import React from 'react';
import { useCategoryDialog } from './CategoryDialog';
import IconButton from '../../IconButton';
import { CategoryType } from '../../../common/ResponseTypes';
const EditButton = ({ category, }) => {
    const [CategoryDialog, showCategoryDialog] = useCategoryDialog();
    const showDialog = () => {
        switch (category.type) {
            case CategoryType.Regular:
                showCategoryDialog();
                break;
            case CategoryType.Bill:
                showCategoryDialog();
                break;
            default:
                showCategoryDialog();
                break;
        }
    };
    const handleClick = (event) => {
        event.stopPropagation();
    };
    return (React.createElement("div", { onClick: handleClick },
        React.createElement(IconButton, { icon: "edit", onClick: showDialog }),
        React.createElement(CategoryDialog, { category: category })));
};
export default EditButton;
