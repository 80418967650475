import React from 'react';
import styles from './Transaction.module.scss';
const Transaction = ({ transaction, }) => (React.createElement("div", { key: transaction.transaction_id, className: styles.transaction },
    React.createElement("label", null,
        "Date:",
        React.createElement("div", null, transaction.date)),
    React.createElement("label", null,
        "Name:",
        React.createElement("div", null, transaction.name)),
    React.createElement("label", null,
        "Amount:",
        React.createElement("div", null, transaction.amount)),
    React.createElement("label", null,
        "Trx ID:",
        React.createElement("div", null, transaction.transaction_id)),
    React.createElement("label", null,
        "Pending Trx ID:",
        React.createElement("div", null, transaction.pending_transaction_id)),
    React.createElement("label", null,
        "Account ID:",
        React.createElement("div", null, transaction.account_id)),
    React.createElement("label", null,
        "Pending:",
        React.createElement("div", null, transaction.pending.toString())),
    React.createElement("label", null,
        "Payment Channel:",
        React.createElement("div", null, transaction.payment_channel)),
    React.createElement("label", null,
        "Merchant Name:",
        React.createElement("div", null, transaction.merchant_name)),
    React.createElement("label", null,
        "Account Owner:",
        React.createElement("div", null, transaction.account_owner))));
export default Transaction;
