import React from 'react';
import styles from './Transactions.module.scss';
const Reconcile = ({ transaction, }) => {
    const handleReconcileChange = () => {
        transaction.toggleReconciled();
    };
    const handleReconcileClick = (event) => {
        event.stopPropagation();
    };
    return (React.createElement("input", { type: "checkbox", className: styles.reconcile, checked: transaction.reconciled, onChange: handleReconcileChange, onClick: handleReconcileClick }));
};
export default Reconcile;
