import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useFundingDialog } from '../../Funding/FundingDialog';
import { useRebalanceDialog } from '../../Rebalance/RebalanceDialog';
import { useStores } from '../../State/Store';
import { useCategoryDialog } from './CategoryDialog';
import { useGroupDialog } from './GroupDialog';
import useMediaQuery from '../../MediaQuery';
const CategoryViewToolbar = observer(({ open = false, }) => {
    const { categoryTree } = useStores();
    const [RebalanceDialog, showRebalanceDialog] = useRebalanceDialog();
    const [FundingDialog, showFundingDialog] = useFundingDialog();
    const [CategoryDialog, showCategoryDialog] = useCategoryDialog();
    const [BillDialog, showBillDialog] = useCategoryDialog();
    const [GoalDialog, showGoalDialog] = useCategoryDialog();
    const [GroupDialog, showGroupDialog] = useGroupDialog();
    const { isMobile } = useMediaQuery();
    const handleAddClick = (eventKey) => {
        switch (eventKey) {
            case 'GROUP':
                showGroupDialog();
                break;
            case 'CATEGORY':
                showCategoryDialog();
                break;
            case 'BILL':
                showBillDialog();
                break;
            case 'GOAL':
                showGoalDialog();
                break;
            default:
                break;
        }
    };
    const renderCategoryButtons = () => (open || !isMobile
        ? (React.createElement(React.Fragment, null,
            React.createElement(Dropdown, { onSelect: handleAddClick },
                React.createElement(Dropdown.Toggle, { id: "dropdown-basic" }, "Add..."),
                React.createElement(Dropdown.Menu, null,
                    React.createElement(Dropdown.Item, { eventKey: "GROUP" }, "Group"),
                    React.createElement(Dropdown.Item, { eventKey: "CATEGORY" }, "Category"),
                    React.createElement(Dropdown.Item, { eventKey: "BILL" }, "Bill"),
                    React.createElement(Dropdown.Item, { eventKey: "GOAL" }, "Goal"))),
            categoryTree.noGroupGroup !== null
                ? (React.createElement(React.Fragment, null,
                    React.createElement(CategoryDialog, null)))
                : null,
            React.createElement(GroupDialog, null),
            React.createElement(BillDialog, { type: "BILL" }),
            React.createElement(GoalDialog, { type: "GOAL" })))
        : null);
    return (React.createElement(React.Fragment, null,
        renderCategoryButtons(),
        !open || !isMobile
            ? (React.createElement(React.Fragment, null,
                React.createElement("button", { type: "button", onClick: showRebalanceDialog }, "Rebalance"),
                React.createElement(RebalanceDialog, null),
                React.createElement("button", { type: "button", onClick: showFundingDialog }, "Fund"),
                React.createElement(FundingDialog, null)))
            : null));
});
export default CategoryViewToolbar;
