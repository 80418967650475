import * as responsive from 'react-responsive';
const useMediaQuery = () => {
    const isDesktop = responsive.useMediaQuery({ query: '(min-width: 600px)' });
    const isMobile = responsive.useMediaQuery({ query: '(max-width: 600px)' });
    const addMediaClass = (className) => {
        if (isMobile) {
            return `mobile ${className}`;
        }
        return className;
    };
    return { isDesktop, isMobile, addMediaClass };
};
export default useMediaQuery;
