import { makeObservable, observable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import Bill from './Bill';
class Overview {
    constructor(store) {
        this.bills = [];
        this.initialized = false;
        this.store = store;
        makeObservable(this, {
            bills: observable,
        });
    }
    async load() {
        const response = await Http.get('/api/v1/bills');
        if (!response.ok) {
            throw new Error('invalid response');
        }
        const body = await response.body();
        if (body) {
            runInAction(() => {
                this.bills = body.map((props) => new Bill(props));
                this.initialized = true;
            });
        }
    }
}
export default Overview;
