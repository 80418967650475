import React from 'react';
import Icon from './Icon';
import styles from './IconButton.module.scss';
const IconButton = ({ icon, caption, rotate = false, className, iconClass, solid = true, onClick, }) => {
    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) {
            onClick();
        }
    };
    return (React.createElement("button", { type: "button", className: `btn btn-sm ${styles.layout} ${className ?? ''}`, onClick: handleClick },
        React.createElement(Icon, { icon: icon, rotate: rotate, iconClass: iconClass, solid: solid }),
        React.createElement("div", null, caption)));
};
export default IconButton;
