import React from 'react';
import useMediaQuery from './MediaQuery';
const DesktopView = ({ children, }) => {
    const { isMobile } = useMediaQuery();
    return isMobile
        ? null
        : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        React.createElement(React.Fragment, null, children));
};
export default DesktopView;
