import React, { useState, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import PopoverHeader from 'react-bootstrap/PopoverHeader';
import PopoverBody from 'react-bootstrap/PopoverBody';
import parseEquation from './EquationParser';
import styles from './AmountInput.module.scss';
import Console from './Console';
const AmountInput = ({ id, value = 0, onDeltaChange, onChange, onBlur, className = '', style, name, readOnly, }) => {
    const convertAmount = (amount) => (typeof amount === 'string'
        ? parseFloat(amount).toFixed(2)
        : amount.toFixed(2));
    const [inputAmount, setInputAmount] = useState(convertAmount(value));
    const [initialValue, setInitialValue] = useState(convertAmount(value));
    const [showPopover, setShowPopover] = useState(false);
    const ref = useRef(null);
    const inputRef = useRef(null);
    const [equation, setEquation] = useState('');
    const [previousAmount, setPreviousAmount] = useState('0');
    const [focus, setFocus] = useState(false);
    // React.useEffect(() => {
    //   console.log(`mounted ${name} ${value}, ${inputAmount}`);
    //   return () => console.log(`unmounted ${name} ${value} ${inputAmount}`)
    // }, [name, value, inputAmount])
    // const setInputAmount = (v: string) => {
    //   console.log(`set input value: ${v}`)
    //   setInputAmount2(v);
    // }
    const handleChange = (event) => {
        setInputAmount(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    const handleCalcChange = (event) => {
        setEquation(event.target.value);
        try {
            if (event.target.value === '') {
                setInputAmount('');
            }
            else {
                const val = parseEquation(event.target.value);
                setInputAmount(val.toFixed(2));
            }
        }
        catch (error) {
            Console.log(error);
        }
    };
    const handleFocus = (event) => {
        setInitialValue(parseFloat(event.target.value).toFixed(2));
        setFocus(true);
    };
    const handleBlur = (event) => {
        let newAmount = Math.round(parseFloat(event.target.value) * 100.0) / 100.0;
        if (Number.isNaN(newAmount)) {
            newAmount = 0;
        }
        const delta = newAmount - parseFloat(initialValue);
        if (onDeltaChange && delta !== 0) {
            onDeltaChange(newAmount, delta);
        }
        setInputAmount(newAmount.toFixed(2));
        if (onBlur) {
            onBlur(event);
        }
        setFocus(false);
    };
    const handleKeyPress = (event) => {
        if (event.key === '=') {
            setShowPopover(!showPopover);
            event.stopPropagation();
            event.preventDefault();
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Escape' || event.key === 'Enter') {
            setShowPopover(false);
            if (event.key === 'Escape') {
                setInputAmount(previousAmount);
            }
            event.stopPropagation();
            event.preventDefault();
            const input = ref.current;
            if (input !== null) {
                input.focus();
            }
        }
    };
    const handleEnter = () => {
        const input = ref.current;
        if (input !== null) {
            input.focus();
        }
        setEquation(inputAmount);
        setPreviousAmount(inputAmount);
    };
    let inputValue = inputAmount;
    if (onChange !== undefined) {
        inputValue = typeof value === 'string' ? value : value.toFixed(2);
    }
    if (!focus) {
        let v = inputValue;
        if (typeof v === 'string') {
            v = (parseFloat(v) * 100.0) / 100.0;
        }
        v = Math.round(v * 100.0) / 100.0;
        if (Number.isNaN(v)) {
            v = 0;
        }
        inputValue = v.toFixed(2);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { id: id, ref: ref, className: `${styles.amountInput} ${styles.dollarAmount} ${className}`, style: style, type: "text", value: inputValue, name: name, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, onKeyDown: handleKeyPress, readOnly: readOnly }),
        !readOnly
            ? (React.createElement(Overlay, { show: showPopover, target: ref.current, placement: "bottom", containerPadding: 20, onEnter: handleEnter },
                React.createElement(Popover, { id: "pop-over" },
                    React.createElement(PopoverHeader, null, "Enter Equation"),
                    React.createElement(PopoverBody, null,
                        React.createElement("input", { ref: inputRef, type: "text", value: equation, onChange: handleCalcChange, onKeyDown: handleKeyDown })))))
            : null));
};
export default AmountInput;
