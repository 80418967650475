import React from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '../IconButton';
import { getSubTypeName } from '../State/AccountTypes';
import Amount from '../Amount';
import styles from './Account.module.scss';
import { useDeleteConfirmation } from '../DeleteConfirmation';
const Account = observer(({ selected, account, onAccountSelected, onAccountStateChange, showAccountDialog, }) => {
    const [CloseConfirmation, handleCloseClick] = useDeleteConfirmation(account.closed ? 'Open Confirmation' : 'Close Confirmation', account.closed ? 'Open' : 'Close', (React.createElement(React.Fragment, null,
        React.createElement("div", null, `Are you sure you want to ${account.closed ? 'open' : 'close'} this account?`),
        React.createElement("div", { style: { marginTop: '1rem' } }, `Accounts can be ${account.closed ? 'reclosed' : 'reopened'} from the ${account.closed ? 'Opened' : 'Closed'} tab.`))), () => {
        account.setSettings({ closed: !account.closed });
        onAccountStateChange(account);
    });
    const accountSelected = () => {
        onAccountSelected(account);
    };
    let acctClassName = styles.account;
    if (selected) {
        acctClassName += ` ${styles.selected}`;
    }
    return (React.createElement("div", { className: acctClassName, onClick: accountSelected },
        React.createElement("div", { className: styles.buttons },
            React.createElement(IconButton, { icon: "gear", className: styles.iconButton, onClick: () => showAccountDialog(account) }),
            React.createElement(IconButton, { icon: account.closed ? 'circle' : 'times-circle', className: styles.iconButton, solid: false, onClick: handleCloseClick })),
        React.createElement("div", { className: styles.accountInfo },
            React.createElement("div", { className: styles.accountName }, account.name),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Amount, { style: { textAlign: 'left' }, amount: account.balance }),
                account.plaidBalance !== null && account.balance !== account.plaidBalance
                    ? (React.createElement(React.Fragment, null,
                        React.createElement("div", null, "/"),
                        React.createElement(Amount, { style: { textAlign: 'left' }, amount: account.plaidBalance })))
                    : null),
            React.createElement("div", null, getSubTypeName(account.type, account.subtype)),
            account.type === 'loan'
                ? (React.createElement("div", null, account.rate === null
                    ? 'APR: unknown'
                    : `APR: ${account.rate}%`))
                : null),
        React.createElement(CloseConfirmation, null)));
});
export default Account;
