import React from 'react';
import styles from './NavigationView.module.scss';
const NavigationView = ({ title, open, onClose, details, children, }) => (React.createElement("div", { className: styles.outerWrapper },
    React.createElement("div", { className: styles.titleWrapper, onClick: onClose },
        React.createElement("div", { className: `${styles.backButton} ${open ? 'open' : ''}` }, '<'),
        React.createElement("div", { className: `${styles.title} ${open ? 'open' : ''}` }, title)),
    React.createElement("div", { className: styles.wrapper },
        children,
        React.createElement("div", { className: `${styles.offCanvas} ${open ? 'open' : ''}` }, details))));
export default NavigationView;
