import React, { useEffect, useState, } from 'react';
import { Form, Formik, useFormikContext, } from 'formik';
import { Observer } from 'mobx-react-lite';
import { Button, FormControl, InputGroup, } from 'react-bootstrap';
import { FormField, setFormErrors, SubmitButton } from '@mortvola/forms';
import Http from '@mortvola/http';
import { useNavigate } from 'react-router-dom';
import { useStores } from './State/Store';
import IconButton from './IconButton';
import styles from './UserAccount.module.scss';
import { useDeleteConfirmation } from './DeleteConfirmation';
import PushRegistrationButton from './PushRegistrationButton';
const MyInputGroup = (props) => {
    const { user } = useStores();
    const { isSubmitting } = useFormikContext();
    return (React.createElement(InputGroup, null,
        React.createElement(FormControl, { ...props, readOnly: user.pendingEmail !== null }),
        React.createElement(SubmitButton, { isSubmitting: isSubmitting, label: "Change", submitLabel: "Changing" })));
};
const UserAccount = () => {
    const stores = useStores();
    const [initialized, setInitialized] = useState(false);
    const navigate = useNavigate();
    const [DeleteConfirmation, handleDeleteClick] = useDeleteConfirmation('Delete Confirmation', 'Delete', (React.createElement(React.Fragment, null,
        React.createElement("div", null, "Are you sure you want to delete your account?"),
        React.createElement("div", { style: { marginTop: '1rem' } }, "This is an irreverisible action."))), async () => {
        const response = await Http.delete('/api/v1/user');
        if (response.ok) {
            stores.refresh();
            navigate('/');
        }
    });
    useEffect(() => {
        (async () => {
            await stores.user.load();
            setInitialized(true);
        })();
    }, [stores.user]);
    const handleSubmit = async (values, { setErrors }) => {
        const errors = await stores.user.update(values.email);
        if (errors) {
            setFormErrors(setErrors, errors);
        }
    };
    const handleDeletePending = async () => {
        await stores.user.deletePendingEmail();
    };
    const handleResendClick = () => {
        stores.user.resendVerificationLink();
    };
    if (initialized) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Observer, null, () => (React.createElement(Formik, { initialValues: {
                    username: stores.user.username ?? '',
                    email: stores.user.email ?? '',
                }, onSubmit: handleSubmit },
                React.createElement(Form, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '20rem',
                        margin: '1rem',
                    } },
                    React.createElement(FormField, { name: "username", readOnly: true, label: "Username:" }),
                    React.createElement("div", { style: { display: 'flex', alignItems: 'end' } },
                        React.createElement(FormField, { name: "email", label: "Email:", as: MyInputGroup })),
                    stores.user.pendingEmail
                        ? (React.createElement("div", { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '0.5rem',
                                maxWidth: 'max-content',
                                overflow: 'hidden',
                            } },
                            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                                React.createElement("div", null, "Pending verification:"),
                                React.createElement("div", { style: { display: 'flex', whiteSpace: 'nowrap' } },
                                    React.createElement("div", { className: "ellipsis", style: { margin: '0 0.5rem' } }, stores.user.pendingEmail),
                                    React.createElement(IconButton, { icon: "trash", iconClass: styles.icon, onClick: handleDeletePending }))),
                            React.createElement("button", { type: "button", style: {
                                    border: 'none',
                                    background: 'transparent',
                                    color: 'blue',
                                    fontSize: 'small',
                                    width: 'max-content',
                                }, onClick: handleResendClick }, "Resend verification link")))
                        : null,
                    React.createElement(PushRegistrationButton, { url: "/api/v1/user/register-push/web", className: styles.enableNotifications }),
                    React.createElement(Button, { variant: "danger", className: styles.delete, onClick: handleDeleteClick }, "Delete Account"))))),
            React.createElement(DeleteConfirmation, null)));
    }
    return null;
};
export default UserAccount;
