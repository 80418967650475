import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
const Notification = ({ title, onNotified, onHide, show, children, }) => (React.createElement(Modal, { onHide: onHide, show: show },
    React.createElement(Modal.Header, { closeButton: true },
        React.createElement(Modal.Title, null, title)),
    React.createElement(Modal.Body, null, children),
    React.createElement(Modal.Footer, null,
        React.createElement(Button, { variant: "danger", onClick: onNotified }, "OK"))));
function useNotification(title, children, onNotified) {
    const [notified, setNotified] = useState(false);
    const showNotification = () => {
        setNotified(true);
    };
    const handleHide = () => {
        setNotified(false);
    };
    const handleConfirm = () => {
        if (onNotified) {
            onNotified();
        }
        handleHide();
    };
    const createConfirmation = () => (React.createElement(Notification, { title: title, show: notified, onHide: handleHide, onNotified: handleConfirm }, children));
    return [
        createConfirmation,
        showNotification,
    ];
}
export default Notification;
export { useNotification };
