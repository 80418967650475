/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { makeUseModal } from '@mortvola/usemodal';
import { ModalBody, ModalFooter, ModalHeader, } from 'react-bootstrap';
import Http from '@mortvola/http';
import { DateTime } from 'luxon';
import Amount from '../Amount';
const TransactionFields = ({ name, date, amount }) => (React.createElement("div", null,
    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
        React.createElement("input", { readOnly: true, value: name }),
        React.createElement("label", { style: { display: 'flex', flexDirection: 'column' } },
            "Date:",
            React.createElement("input", { type: "date", readOnly: true, value: date?.toISODate() ?? '' })),
        React.createElement("label", { style: { display: 'flex', flexDirection: 'column' } },
            "Amount:",
            React.createElement(Amount, { amount: amount })))));
const DuplicateDialog = ({ setShow, transaction, }) => {
    const [otherTransaction, setOtherTransaction] = React.useState(null);
    React.useEffect(() => {
        (async () => {
            const response = await Http.get(`/api/v1/transaction/${transaction.duplicateOfTransactionId}`);
            if (response.ok) {
                const body = await response.body();
                setOtherTransaction(body);
            }
        })();
    }, [transaction.duplicateOfTransactionId]);
    const handleKeepBoth = async () => {
        const errors = await transaction.dedup();
        if (!errors) {
            setShow(false);
        }
    };
    const handleDeleteDuplicate = async () => {
        const errors = await transaction.delete();
        if (!errors) {
            setShow(false);
        }
    };
    const handleCancel = () => {
        setShow(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, null, "Duplicate Transaction"),
        React.createElement(ModalBody, null,
            React.createElement(TransactionFields, { name: transaction.name, date: transaction.date, amount: transaction.amount }),
            React.createElement("div", { style: { borderTop: 'solid black thin', margin: '1rem' } }),
            React.createElement(TransactionFields, { name: otherTransaction?.accountTransaction.name, date: otherTransaction?.date ? DateTime.fromISO(otherTransaction?.date) : undefined, amount: otherTransaction?.accountTransaction.amount })),
        React.createElement(ModalFooter, null,
            React.createElement("div", { style: { display: 'flex', columnGap: '0.5rem' } },
                React.createElement("button", { type: "button", onClick: handleCancel }, "Cancel"),
                React.createElement("button", { type: "button", onClick: handleKeepBoth }, "No, these are not duplicates"),
                React.createElement("button", { type: "button", onClick: handleDeleteDuplicate }, "Yes, these are duplicates")))));
};
export const useDuplicateDialog = makeUseModal(DuplicateDialog, { size: 'lg' });
export default DuplicateDialog;
