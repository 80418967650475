import React from 'react';
import * as responsive from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styles from './Signup.module.scss';
import EnterUserInfo from './EnterUserInfo';
import EnterPassCode from './EnterPassCode';
const Signup = () => {
    const tiny = responsive.useMediaQuery({ query: '(max-width: 350px)' });
    const small = responsive.useMediaQuery({ query: '(max-width: 600px)' });
    const medium = responsive.useMediaQuery({ query: '(max-width: 1224px)' });
    const [context, setContext] = React.useState({ state: 'Enter Info', email: '' });
    const navigate = useNavigate();
    const addSizeClass = (className) => {
        if (tiny) {
            // eslint-disable-next-line css-modules/no-undef-class
            return `${styles.tiny} ${className}`;
        }
        if (small) {
            // eslint-disable-next-line css-modules/no-undef-class
            return `${styles.small} ${className}`;
        }
        if (medium) {
            // eslint-disable-next-line css-modules/no-undef-class
            return `${styles.medium} ${className}`;
        }
        return className;
    };
    const handleNext = (newContext) => {
        setContext((prev) => {
            switch (prev.state) {
                case 'Enter Info':
                    return { ...newContext, state: 'Verify Code' };
                case 'Verify Code':
                    navigate('/home');
                    return prev;
                default:
                    return prev;
            }
        });
    };
    const renderForm = () => {
        switch (context.state) {
            case 'Enter Info':
                return React.createElement(EnterUserInfo, { context: context, onNext: handleNext });
            case 'Verify Code':
                return React.createElement(EnterPassCode, { context: context, onNext: handleNext });
            default:
                return null;
        }
    };
    const handleTitleClick = () => {
        navigate('/');
    };
    return (React.createElement("div", { className: addSizeClass(styles.frame) },
        React.createElement("div", { className: styles.title, onClick: handleTitleClick }, "SpendCraft"),
        renderForm()));
};
export default Signup;
