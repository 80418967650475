import React from 'react';
import CategoryInput from './CategoryInput/CategoryInput';
import IconButton from './IconButton';
import AmountInput from './AmountInput';
import styles from './CategorySplitItem.module.scss';
import useMediaQuery from './MediaQuery';
import { useStores } from './State/Store';
const CategorySplitItem = ({ split, onCategoryChange, onDeltaChange, onAddItem, onDeleteItem, onCommentChange, }) => {
    const { categoryTree: { unassignedCat } } = useStores();
    const { isMobile } = useMediaQuery();
    if (!unassignedCat) {
        throw new Error('unassigned category is null');
    }
    const handleCategoryChange = (category) => {
        if (split.id === undefined) {
            throw new Error('missing id');
        }
        onCategoryChange(split.id, category.id);
    };
    const handleDeltaChange = (amount, delta) => {
        if (split.id === undefined) {
            throw new Error('missing id');
        }
        onDeltaChange(split.id, amount, delta);
    };
    const handleCommentChange = (event) => {
        if (split.id === undefined) {
            throw new Error('missing id');
        }
        onCommentChange(split.id, event.target.value);
    };
    const handleAddItem = () => {
        if (split.id === undefined) {
            throw new Error('missing id');
        }
        onAddItem(split.id);
    };
    const handleDeleteItem = () => {
        if (split.id === undefined) {
            throw new Error('missing id');
        }
        onDeleteItem(split.id);
    };
    const categoryId = split ? split.categoryId : null;
    return (React.createElement("div", { className: styles.transactionSplitItem },
        React.createElement("div", null,
            React.createElement(CategoryInput, { className: "category-input", onCategoryChange: handleCategoryChange, categoryId: categoryId === unassignedCat.id ? null : categoryId }),
            React.createElement(AmountInput, { onDeltaChange: handleDeltaChange, value: split.amount, style: { margin: '1px' } }),
            React.createElement("input", { type: "text", className: styles.comment, value: split.comment ?? '', onChange: handleCommentChange, placeholder: isMobile ? 'Comment' : '' })),
        React.createElement(IconButton, { icon: "plus", onClick: handleAddItem }),
        React.createElement(IconButton, { icon: "minus", onClick: handleDeleteItem })));
};
export default CategorySplitItem;
