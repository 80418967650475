import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Transactions.module.scss';
import { useStores } from '../State/Store';
const Reconcile = observer(({ transaction, }) => {
    const { uiState: { selectedStatement } } = useStores();
    const handleReconcileChange = () => {
        if (selectedStatement !== null
            && (transaction.statementId === selectedStatement.id || transaction.statementId === null)) {
            transaction.toggleReconciled(selectedStatement.id);
        }
    };
    const handleReconcileClick = (event) => {
        event.stopPropagation();
    };
    return (React.createElement("input", { type: "checkbox", className: styles.reconcile, checked: transaction.statementId !== null, onChange: handleReconcileChange, onClick: handleReconcileClick, disabled: selectedStatement === null
            || (transaction.statementId !== null && transaction.statementId !== selectedStatement.id) }));
});
export default Reconcile;
