import { makeAutoObservable, runInAction } from 'mobx';
import Http from '@mortvola/http';
export const isNetworthReport = (r) => (true);
export const isPayeeReport = (r) => (Array.isArray(r)
    && (r.length === 0 || (r[0].name !== undefined)));
class Reports {
    constructor(store) {
        this.reportType = null;
        this.data = null;
        this.data = null;
        makeAutoObservable(this);
        this.store = store;
    }
    async loadReport(reportType) {
        switch (reportType) {
            case 'netWorth': {
                const response = await Http.get('/api/v1/reports/networth');
                if (response.ok) {
                    const body = await response.body();
                    runInAction(() => {
                        this.reportType = reportType;
                        if (isNetworthReport(body)) {
                            this.data = body;
                        }
                    });
                }
                break;
            }
            case 'payee': {
                const response = await Http.get('/api/v1/reports/payee');
                if (response.ok) {
                    const body = await response.body();
                    runInAction(() => {
                        this.reportType = reportType;
                        if (isPayeeReport(body)) {
                            this.data = body;
                        }
                    });
                }
                break;
            }
            default:
                break;
        }
    }
}
export default Reports;
