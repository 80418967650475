import { makeObservable, observable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import { DateTime } from 'luxon';
import { isAddTransactionResponse, } from '../../common/ResponseTypes';
import Transaction from './Transaction';
import TransactionContainer from './TransactionContainer';
class Account {
    constructor(store, institution, props) {
        this.officialName = null;
        this.balance = 0;
        this.transactions = new TransactionContainer(store, `/api/v1/account/${props.id}/transactions`, this.updateBalance);
        this.pendingTransactions = new TransactionContainer(store, `/api/v1/account/${props.id}/transactions?pending=1`);
        this.id = props.id;
        this.plaidId = props.plaidId;
        this.name = props.name;
        this.closed = props.closed;
        this.type = props.type;
        this.subtype = props.subtype;
        this.tracking = props.tracking;
        this.balance = props.balance;
        this.plaidBalance = props.plaidBalance;
        this.startDate = props.startDate ? DateTime.fromISO(props.startDate) : null;
        this.rate = props.rate;
        this.institution = institution;
        makeObservable(this, {
            name: observable,
            plaidBalance: observable,
            rate: observable,
            institution: observable,
            balance: observable,
        });
        this.store = store;
    }
    update(props) {
        this.id = props.id;
        this.plaidId = props.plaidId;
        this.name = props.name;
        this.closed = props.closed;
        this.type = props.type;
        this.subtype = props.subtype;
        this.tracking = props.tracking;
        this.balance = props.balance;
        this.plaidBalance = props.plaidBalance;
        this.startDate = props.startDate ? DateTime.fromISO(props.startDate) : null;
        this.rate = props.rate;
        this.transactions.url = `/api/v1/account/${props.id}/transactions`;
        this.pendingTransactions.url = `/api/v1/account/${props.id}/transactions?pending=1`;
    }
    updateBalance(balance) {
        this.balance = balance;
    }
    async setSettings(settings) {
        const response = await Http.patch(`/api/v1/account/${this.id}`, settings);
        if (response.ok) {
            runInAction(() => {
                this.closed = settings.closed ?? this.closed;
                if (this.closed) {
                    this.institution.closeAccount(this);
                }
            });
        }
    }
    async addTransaction(values) {
        const response = await Http.post(`/api/v1/account/${this.id}/transactions`, values);
        if (response.ok) {
            const body = await response.body();
            if (isAddTransactionResponse(body)) {
                runInAction(() => {
                    this.store.categoryTree.updateBalances(body.categories);
                    const transaction = new Transaction(this.store, body.transaction);
                    this.transactions.insertTransaction(transaction);
                    this.balance = body.acctBalances[0].balance;
                });
                return null;
            }
        }
        throw new Error('Error response received');
    }
    delete() {
        this.institution.deleteAccount(this);
    }
    async updateOfflineAccount(name) {
        const response = await Http.patch(`/api/v1/account/${this.id}`, {
            name,
        });
        if (response.ok) {
            runInAction(() => {
                this.name = name;
            });
        }
    }
}
export default Account;
