import React from 'react';
import { observer } from 'mobx-react';
import CategorySelectorGroup from './CategorySelectorGroup';
import { useStores } from '../State/Store';
import { isGroup } from '../State/Group';
import CategorySelectorCategory from './CategorySelectorCategory';
import { isCategory } from '../State/Category';
export const categoryFiltered = (group, category, filterParts, types) => {
    if (types !== undefined && isCategory(category) && !types.includes(category.type)) {
        return true;
    }
    if (filterParts.length > 0) {
        if (filterParts.length === 1) {
            // No colon. Filter can be applied to both group and categories.
            // If the category's group matches the filter then always include the
            // category.
            return ((group === null || !group.name.toLowerCase().includes(filterParts[0]))
                && !category.name.toLowerCase().includes(filterParts[0]));
        }
        if (filterParts[0] === '') {
            // filter only applies to the category
            return !category.name.toLowerCase().includes(filterParts[1]);
        }
        if (filterParts[1] === '') {
            // filter only applies to the group
            return group === null || !group.name.toLowerCase().includes(filterParts[0]);
        }
        return (group === null || !group.name.toLowerCase().includes(filterParts[0]))
            || !category.name.toLowerCase().includes(filterParts[1]);
    }
    return false;
};
// eslint-disable-next-line react/display-name
const CategorySelector = observer(React.forwardRef(({ selectedCategory = null, left = null, top = null, width = null, height = null, onSelect, filter = [], types, }, forwardRef) => {
    const { categoryTree } = useStores();
    const filteredCategories = (group, level) => (group.children
        .filter((c) => (!categoryFiltered(group, c, filter, types)))
        .map((c) => {
        if (isCategory(c)) {
            return (React.createElement(CategorySelectorCategory, { key: `${group.id}:${c.id}`, category: c, selected: selectedCategory !== null && c.id === selectedCategory.id, onSelect: onSelect, level: level }));
        }
        if (isGroup(c)) {
            return (React.createElement(CategorySelectorGroup, { key: c.id, group: c, level: 0 }, filteredCategories(c, level)));
        }
        return null;
    }));
    const style = {}; // { display: 'none' };
    if (left !== null) {
        style.left = left;
    }
    if (top !== null) {
        style.top = top;
    }
    if (width !== null) {
        style.width = width;
    }
    if (height !== null) {
        style.height = height;
    }
    const handleMouseDown = (event) => {
        event.preventDefault();
    };
    return (React.createElement("div", { ref: forwardRef, className: "drop-down", style: style, onMouseDown: handleMouseDown },
        categoryTree.unassignedCat && !categoryFiltered(null, categoryTree.unassignedCat, filter, types)
            ? (React.createElement(CategorySelectorCategory, { key: `${categoryTree.budget.id}:${categoryTree.unassignedCat.id}`, category: categoryTree.unassignedCat, selected: selectedCategory !== null && categoryTree.unassignedCat.id === selectedCategory.id, onSelect: onSelect, level: 0 }))
            : null,
        categoryTree.budget.fundingPoolCat && !categoryFiltered(null, categoryTree.budget.fundingPoolCat, filter, types)
            ? (React.createElement(CategorySelectorCategory, { key: `${categoryTree.budget.id}:${categoryTree.budget.fundingPoolCat.id}`, category: categoryTree.budget.fundingPoolCat, selected: selectedCategory !== null && categoryTree.budget.fundingPoolCat.id === selectedCategory.id, onSelect: onSelect, level: 0 }))
            : null,
        categoryTree.accountTransferCat && !categoryFiltered(null, categoryTree.accountTransferCat, filter, types)
            ? (React.createElement(CategorySelectorCategory, { key: `${categoryTree.budget.id}:${categoryTree.accountTransferCat.id}`, category: categoryTree.accountTransferCat, selected: selectedCategory !== null && categoryTree.accountTransferCat.id === selectedCategory.id, onSelect: onSelect, level: 0 }))
            : null,
        categoryTree.budget.children.map((g) => {
            if (isGroup(g)) {
                const categories = filteredCategories(g, 1);
                if (categories.length > 0) {
                    return (React.createElement(CategorySelectorGroup, { key: g.id, group: g, level: 0 }, filteredCategories(g, 1)));
                }
                return null;
            }
            if (!isCategory(g)) {
                throw new Error('group is not a category');
            }
            if (!categoryFiltered(null, g, filter, types)) {
                if (g.group === null) {
                    throw new Error('group is null');
                }
                return (React.createElement(CategorySelectorCategory, { key: `${g.group.id}:${g.id}`, category: g, selected: selectedCategory !== null && g.id === selectedCategory.id, onSelect: onSelect, level: 0 }));
            }
            return null;
        })));
}));
export default CategorySelector;
