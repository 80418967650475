import { makeAutoObservable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import Category, { isCategory } from './Category';
import { isErrorResponse, isGroupProps, isAddCategoryResponse, } from '../../common/ResponseTypes';
class Group {
    constructor(props, store) {
        this.categories = [];
        this.id = props.id;
        this.name = props.name;
        this.type = props.type;
        this.store = store;
        if (props.categories && props.categories.length > 0) {
            this.setCategories(props.categories);
        }
        makeAutoObservable(this);
    }
    setCategories(categories) {
        categories.forEach((c) => {
            switch (c.type) {
                case 'UNASSIGNED':
                    if (this.store.categoryTree.unassignedCat === null) {
                        throw new Error('unassigned category is null');
                    }
                    this.categories.push(this.store.categoryTree.unassignedCat);
                    break;
                case 'ACCOUNT TRANSFER':
                    if (this.store.categoryTree.accountTransferCat === null) {
                        throw new Error('account transfer category is null');
                    }
                    this.categories.push(this.store.categoryTree.accountTransferCat);
                    break;
                case 'FUNDING POOL':
                    if (this.store.categoryTree.fundingPoolCat === null) {
                        throw new Error('funding category is null');
                    }
                    this.categories.push(this.store.categoryTree.fundingPoolCat);
                    break;
                default: {
                    const category = new Category(c, this.store);
                    if (this.type === 'NO GROUP') {
                        this.store.categoryTree.insertNode(category);
                    }
                    this.categories.push(category);
                    break;
                }
            }
        });
    }
    findCategory(categoryId) {
        const cat = this.categories.find((c) => c.id === categoryId);
        if (cat) {
            return cat;
        }
        return null;
    }
    async addCategory(params) {
        const { group, goalDate, ...p } = params;
        const response = await Http.post(`/api/v1/groups/${this.id}/categories`, {
            ...p,
            groupId: this.id,
            goalDate: goalDate?.toISODate(),
        });
        const body = await response.body();
        if (!response.ok) {
            if (isErrorResponse(body)) {
                return body.errors;
            }
        }
        else if (isAddCategoryResponse(body)) {
            runInAction(() => {
                const category = new Category(body, this.store);
                // Find the position where this new category should be inserted.
                this.insertCategory(category);
            });
        }
        return null;
    }
    insertCategory(category) {
        // Find the position where this new category should be inserted.
        if (this.type === 'NO GROUP') {
            this.store.categoryTree.insertNode(category);
        }
        const index = this.categories.findIndex((g) => category.name.localeCompare(g.name) < 0);
        if (index === -1) {
            this.categories.push(category);
        }
        else {
            this.categories = [
                ...this.categories.slice(0, index),
                category,
                ...this.categories.slice(index),
            ];
        }
    }
    async update(name) {
        const response = await Http.patch(`/api/v1/groups/${this.id}`, { name, hidden: false });
        const body = await response.body();
        if (!response.ok) {
            if (isErrorResponse(body)) {
                return body.errors;
            }
        }
        else {
            runInAction(() => {
                if (isGroupProps(body)) {
                    this.name = body.name;
                }
            });
        }
        return null;
    }
    async delete() {
        const response = await Http.delete(`/api/v1/groups/${this.id}`);
        if (!response.ok) {
            const body = await response.body();
            if (isErrorResponse(body)) {
                return body.errors;
            }
        }
        else {
            runInAction(() => {
                this.store.categoryTree.removeNode(this);
            });
        }
        return null;
    }
    removeCategory(category) {
        if (this.type === 'NO GROUP') {
            this.store.categoryTree.removeNode(category);
        }
        const index = this.categories.findIndex((c) => c.id === category.id);
        if (index !== -1) {
            this.categories.splice(index, 1);
        }
    }
    updateBalances(balances) {
        this.categories.forEach((c) => {
            c.updateBalances(balances);
        });
    }
}
export const isGroup = (r) => (r !== undefined && r !== null
    && r.id !== undefined
    && r.name !== undefined
    && r.categories !== undefined);
export const isCategoriesArray = (r) => ((Array.isArray(r))
    && (r.length === 0 || isCategory(r[0])));
export default Group;
