import React from 'react';
import { observer } from 'mobx-react-lite';
import RegisterTransactions from './RegisterTransactions';
import RegisterTitles from './RegisterTitles';
import useTrxDialog from './TrxDialog';
import Transaction from './Transaction';
import { useStores } from '../State/Store';
import { TransactionType } from '../../common/ResponseTypes';
import styles from './Transactions.module.scss';
import { useNotification } from '../Notification';
const PostedRegister = observer(({ type, trxContainer, category, account, transactionClassName, }) => {
    const [Notification, showNotification] = useNotification('Changed Transaction', (React.createElement("div", null, "The transaction was changed by the system or another user.")));
    const handleReload = () => {
        showNotification();
        trxContainer.getData(0);
    };
    const [TrxDialog, showTrxDialog] = useTrxDialog(account ?? undefined, handleReload);
    const { uiState } = useStores();
    const handleClick = (transaction) => {
        uiState.selectTransaction(transaction);
        if (transaction.type !== TransactionType.STARTING_BALANCE
            && showTrxDialog) {
            showTrxDialog(transaction);
        }
    };
    const renderTransactions = () => {
        if (trxContainer === null) {
            throw new Error('trxContainer is not set');
        }
        if (trxContainer.transactions.length === 0 && trxContainer.isComplete()) {
            return (React.createElement("div", { className: styles.noTransactions },
                React.createElement("div", null, "There are no transactions in this view")));
        }
        let runningBalance = (category?.balance ?? account?.balance) ?? 0;
        return trxContainer.transactions.map((transaction) => {
            let { amount } = transaction;
            if (type === 'rebalances') {
                amount = transaction.categories.reduce((prev, c) => {
                    if (c.amount > 0) {
                        return prev + c.amount;
                    }
                    return prev;
                }, 0);
            }
            else if (category !== null) {
                amount = transaction.getAmountForCategory(category.id);
            }
            else if (account && account.type === 'loan') {
                amount = transaction.principle ?? 0;
            }
            const element = (React.createElement(Transaction, { key: transaction.id, transaction: transaction, className: transactionClassName, amount: amount, runningBalance: runningBalance, account: account, onClick: handleClick }));
            if (runningBalance !== undefined) {
                runningBalance -= amount;
            }
            return element;
        });
    };
    return (React.createElement("div", { className: "register window window1" },
        React.createElement("div", null),
        React.createElement(RegisterTransactions, { trxContainer: trxContainer, titles: React.createElement(RegisterTitles, { transactionClassName: transactionClassName }) }, renderTransactions()),
        React.createElement(TrxDialog, null),
        React.createElement(Notification, null)));
});
export default PostedRegister;
