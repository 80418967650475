import React from 'react';
import { observer } from 'mobx-react-lite';
import Buttons from './GroupButtons';
import Category from './Category';
import { isCategory } from '../../State/Category';
const Group = observer(({ group, onCategorySelected, selectedCategory = null, level = 0, }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "cat-list-group", style: { marginLeft: 25 * level } },
        React.createElement("div", { className: "group-element-bar" },
            React.createElement(Buttons, { group: group }),
            React.createElement("div", { className: "group-name" }, group.name))),
    group.children.map((category) => (isCategory(category)
        ? (React.createElement(Category, { key: category.name, category: category, onCategorySelected: onCategorySelected, selectedCategory: selectedCategory, level: level + 1 }))
        : (React.createElement(Group, { key: category.name, group: category, onCategorySelected: onCategorySelected, selectedCategory: selectedCategory, level: level + 1 })))))));
export default Group;
