import React from 'react';
import { Form, Formik, } from 'formik';
import Http from '@mortvola/http';
import { FormError, setFormErrors, FormField, SubmitButton, } from '@mortvola/forms';
import styles from './Signin.module.scss';
import { isErrorResponse } from '../../common/ResponseTypes';
const ChangePassword = ({ context, onNext, link, }) => {
    // const navigate = useNavigate();
    const handleSubmit = async (values, { setErrors }) => {
        const response = await Http.post('/api/v1/password/update', {
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
        });
        if (response.ok) {
            onNext(context);
        }
        else {
            const body = await response.body();
            if (isErrorResponse(body)) {
                setFormErrors(setErrors, body.errors);
            }
        }
    };
    return (React.createElement(Formik, { initialValues: {
            password: '',
            passwordConfirmation: '',
        }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(Form, { className: styles.form },
        React.createElement("div", { className: styles.subtitle }, "Enter your new password."),
        React.createElement(FormField, { type: "password", name: "password", label: "Password", autoComplete: "new-password" }),
        React.createElement(FormField, { type: "password", name: "passwordConfirmation", label: "Confirm Password", autoComplete: "new-password" }),
        React.createElement(SubmitButton, { className: styles.button, isSubmitting: isSubmitting, label: "Change Password", submitLabel: "Changing Password" }),
        link,
        React.createElement(FormError, { name: "general" })))));
};
export default ChangePassword;
