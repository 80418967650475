import React, { useRef, useEffect } from 'react';
const CategorySelectorCategory = ({ category, selected, onSelect, }) => {
    const ref = useRef(null);
    useEffect(() => {
        const element = ref.current;
        if (selected && element) {
            element.scrollIntoView(false);
        }
    }, [selected, ref]);
    const handleClick = (event) => {
        event.stopPropagation();
        onSelect(category);
    };
    let className = 'cat-list-cat cat-list-item category-select-item';
    if (selected) {
        className += ' selected';
    }
    return (React.createElement("div", { className: className, onClick: handleClick, ref: ref }, category.name));
};
export default CategorySelectorCategory;
