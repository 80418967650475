import React from 'react';
import style from './ReportList.module.scss';
import ReportListItem from './ReportListItem';
const ReportList = ({ reports, onSelect, selectedValue = '', }) => {
    const handleSelect = (value) => {
        onSelect(value);
    };
    return (React.createElement("div", { className: style.reports }, reports.map((r) => (React.createElement(ReportListItem, { key: r.value, value: r.value, selected: selectedValue === r.value, onSelect: handleSelect }, r.name)))));
};
export default ReportList;
