import React from 'react';
import { Form, Formik, } from 'formik';
import Http from '@mortvola/http';
import { FormField, FormError, setFormErrors, SubmitButton, } from '@mortvola/forms';
import styles from './Signup.module.scss';
import { isErrorResponse } from '../../common/ResponseTypes';
const EnterUserInfo = ({ context, onNext, }) => {
    const handleSubmit = async (values, { setErrors }) => {
        const response = await Http.post('/api/v1/register', values);
        if (response.ok) {
            onNext({ ...context, email: values.email });
        }
        else {
            const body = await response.body();
            if (isErrorResponse(body)) {
                setFormErrors(setErrors, body.errors);
            }
        }
    };
    return (React.createElement(Formik, { initialValues: {
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
        }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(Form, { className: styles.form },
        React.createElement("div", { className: styles.subtitle }, "Sign Up"),
        React.createElement("div", { className: styles.description }, 'Let\'s get your account set up!'),
        React.createElement(FormField, { name: "username", label: "Username" }),
        React.createElement(FormField, { name: "email", label: "E-Mail Address" }),
        React.createElement(FormField, { type: "password", name: "password", label: "Password", autoComplete: "new-password" }),
        React.createElement(FormField, { type: "password", name: "passwordConfirmation", label: "Confirm Password", autoComplete: "new-password" }),
        React.createElement(SubmitButton, { className: styles.button, isSubmitting: isSubmitting, label: "Create my account", submitLabel: "Creating your acccount" }),
        React.createElement(FormError, { name: "general" }),
        React.createElement("div", { className: styles.finePrint }, 'By selecting "Create my account", you agree to our Terms'
            + ' and have read and acknowledge our Privacy Statement.')))));
};
export default EnterUserInfo;
