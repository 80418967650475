import React from 'react';
import { createPortal } from 'react-dom';
import IconButton from '../IconButton';
import styles from './TabViewButton.module.scss';
import menuStyles from './TabViewMenu.module.scss';
const TabViewMenu = ({ icon, caption, children, }) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !open);
    };
    const handleWrapperClick = () => {
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { icon: icon, caption: caption, className: `${styles.icon}`, iconClass: "fa-solid", onClick: handleClick }),
        open
            ? (createPortal(React.createElement("div", { className: menuStyles.wrapper, onClick: handleWrapperClick },
                React.createElement("div", { className: menuStyles.items }, children)), document.body))
            : null));
};
export default TabViewMenu;
