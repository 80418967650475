import React from 'react';
import styles from './TransactionTypeSelector.module.scss';
const TransactionTypeSelector = ({ state, onClick, }) => {
    const handleClick1 = () => {
        onClick(false);
    };
    const handleClick2 = () => {
        onClick(true);
    };
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.outer },
            React.createElement("div", { className: `${styles.slider} ${state ? 'on' : ''}` }),
            React.createElement("div", { className: styles.inner },
                React.createElement("div", { className: styles.label, onClick: handleClick1 }, "Posted Trnansactions"),
                React.createElement("div", { className: styles.label, onClick: handleClick2 }, "Pending Transactions")))));
};
export default TransactionTypeSelector;
