import { DateTime } from 'luxon';
import React from 'react';
import styles from './MonthSelector.module.scss';
const Month = ({ month, monthId, onClick }) => {
    const handleClick = (event) => {
        event.stopPropagation();
        onClick(monthId);
    };
    return React.createElement("div", { onClick: handleClick }, month);
};
const DropDown = ({ initialValue, onSelect }) => {
    const [value, setValue] = React.useState(() => {
        if (initialValue && /\d{1,2}-\d{4}/.test(initialValue)) {
            const [month, year] = initialValue.split('-');
            return { month: parseInt(month, 10), year: parseInt(year, 10) };
        }
        const n = DateTime.now();
        return {
            month: n.month,
            year: n.year,
        };
    });
    const handleDropDownClick = (event) => {
        event.stopPropagation();
    };
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const handleDecrementClick = () => {
        setValue((prev) => ({
            month: prev.month,
            year: prev.year - 1,
        }));
    };
    const handleIncrementClick = () => {
        setValue((prev) => ({
            month: prev.month,
            year: prev.year + 1,
        }));
    };
    const handleMonthClick = (monthId) => {
        setValue({
            month: monthId + 1,
            year: value.year,
        });
        onSelect(`${monthId + 1}-${value.year}`);
    };
    return (React.createElement("div", { className: styles.dropDown, onClick: handleDropDownClick },
        React.createElement("div", { className: styles.year },
            React.createElement("div", { onClick: handleDecrementClick }, "<"),
            React.createElement("div", null, value.year),
            React.createElement("div", { onClick: handleIncrementClick }, ">")),
        React.createElement("div", { className: styles.months }, months.map((m, index) => (React.createElement(Month, { key: m, onClick: handleMonthClick, month: m, monthId: index }))))));
};
const MonthSelector = ({ value, onChange, }) => {
    const [open, setOpen] = React.useState(false);
    const inputRef = React.useRef(null);
    const handleClick = (event) => {
        event.stopPropagation();
        setOpen(true);
    };
    const handleSelect = (v) => {
        const input = inputRef.current;
        if (input) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set;
            nativeInputValueSetter?.call(input, v);
            input.dispatchEvent(new Event('input', { bubbles: true }));
        }
        setOpen(false);
    };
    const handleMonthDecrement = () => {
        if (value && /\d{1,2}-\d{4}/.test(value)) {
            const input = inputRef.current;
            if (input) {
                const [monthStr, yearStr] = value.split('-');
                let month = parseInt(monthStr, 10);
                let year = parseInt(yearStr, 10);
                month -= 1;
                if (month < 1) {
                    year -= 1;
                    month = 12;
                }
                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set;
                nativeInputValueSetter?.call(input, `${month}-${year}`);
                input.dispatchEvent(new Event('input', { bubbles: true }));
            }
        }
        setOpen(false);
    };
    const handleMonthIncrement = () => {
        if (value && /\d{1,2}-\d{4}/.test(value)) {
            const input = inputRef.current;
            if (input) {
                const [monthStr, yearStr] = value.split('-');
                let month = parseInt(monthStr, 10);
                let year = parseInt(yearStr, 10);
                month += 1;
                if (month > 12) {
                    year += 1;
                    month = 1;
                }
                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set;
                nativeInputValueSetter?.call(input, `${month}-${year}`);
                input.dispatchEvent(new Event('input', { bubbles: true }));
            }
        }
        setOpen(false);
    };
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };
    return (React.createElement("div", { className: styles.controlWrapper },
        React.createElement("button", { type: "button", onClick: handleMonthDecrement }, "<"),
        React.createElement("div", { className: styles.control, onClick: handleClick },
            React.createElement("input", { ref: inputRef, type: "text", value: value, onChange: handleChange, style: { width: '6rem', textAlign: 'center' } }),
            open
                ? (React.createElement(DropDown, { initialValue: value, onSelect: handleSelect }))
                : null),
        React.createElement("button", { type: "button", onClick: handleMonthIncrement }, ">")));
};
export default MonthSelector;
