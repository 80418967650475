import React from 'react';
import MainTray from './MainTray';
import Toolbar from './Toolbar';
import styles from './Main.module.scss';
const Main = ({ toolbar, sidebar, children, className, onToggleClick, }) => (React.createElement("div", { className: styles.main },
    React.createElement(Toolbar, { onToggleClick: onToggleClick }, toolbar),
    React.createElement(MainTray, { className: className },
        React.createElement("div", { className: `${styles.sideBar} window ${className}` }, sidebar),
        children)));
export default Main;
