import React from 'react';
import { Field, useField, useFormikContext, } from 'formik';
import { FormCheckbox } from '@mortvola/forms';
import AmountInput from '../AmountInput';
import Amount from '../Amount';
import styles from './Funding.module.scss';
import VerticalTitled from './VerticalTitled';
import CategorySpread from '../CategorySpread/CategorySpread';
const FundingItem = ({ fundingInfo, groupName, category, date, onDeltaChange, diffOnly, }) => {
    const { values } = useFormikContext();
    const name = `categories.${category.id}.baseAmount`;
    const [field] = useField(name);
    const balance = (fundingInfo?.initialAmount ?? 0) + (typeof field.value === 'string'
        ? parseFloat(field.value ?? 0)
        : (field.value ?? 0));
    const previousMonthName = date.minus({ months: 1 }).monthLong;
    const hide = (current, previous) => {
        const value1 = parseFloat(current ?? 0).toFixed(2);
        const value2 = (previous ?? 0).toFixed(2);
        return diffOnly && !(value1 !== value2);
    };
    let title = category.name;
    if (groupName) {
        title = `${groupName}: ${title}`;
    }
    return (React.createElement(Field, { name: name }, ({ field: fieldProps }) => (React.createElement("div", { className: `${styles.fundListItem} ${hide(fieldProps.value, fundingInfo?.previousFunding) ? styles.hide : ''}` },
        React.createElement("div", { className: styles.fundListCatName }, title),
        React.createElement("div", { className: styles.valuesWrapper },
            React.createElement("div", { className: styles.fundValues },
                React.createElement(VerticalTitled, { title: "Current" },
                    React.createElement(Amount, { amount: fundingInfo?.initialAmount ?? 0 })),
                React.createElement(VerticalTitled, { title: "Funding", underscored: false },
                    React.createElement(AmountInput, { onDeltaChange: onDeltaChange, ...fieldProps })),
                React.createElement(VerticalTitled, { title: "Balance" },
                    React.createElement(Amount, { amount: balance })),
                React.createElement("div", null, `${previousMonthName} Funding:`),
                React.createElement(Amount, { style: { minWidth: '6rem' }, amount: fundingInfo?.previousFunding ?? 0 })),
            React.createElement("div", { className: styles.fundValues2 },
                React.createElement("div", { className: styles.labeledAmount },
                    `${previousMonthName} Expenses:`,
                    React.createElement(Amount, { style: { minWidth: '6rem' }, amount: fundingInfo?.previousExpenses ?? 0 })),
                React.createElement("div", { className: styles.labeledAmount },
                    `${previousMonthName} Category Transfers:`,
                    React.createElement(Amount, { style: { minWidth: '6rem' }, amount: fundingInfo?.previousCatTransfers ?? 0 })))),
        React.createElement("div", { className: styles.fundingWrapper },
            React.createElement(FormCheckbox, { name: `categories.${category.id}.includeFundingTransfers`, label: "Add Funding Transfers to Funding Amount." }),
            React.createElement(CategorySpread, { name: `categories.${category.id}.fundingCategories`, categories: values.categories[category.id].fundingCategories, title: "Funding Categories:" }))))));
};
export default FundingItem;
