var __esDecorate = (this && this.__esDecorate) || function (ctor, descriptorIn, decorators, contextIn, initializers, extraInitializers) {
    function accept(f) { if (f !== void 0 && typeof f !== "function") throw new TypeError("Function expected"); return f; }
    var kind = contextIn.kind, key = kind === "getter" ? "get" : kind === "setter" ? "set" : "value";
    var target = !descriptorIn && ctor ? contextIn["static"] ? ctor : ctor.prototype : null;
    var descriptor = descriptorIn || (target ? Object.getOwnPropertyDescriptor(target, contextIn.name) : {});
    var _, done = false;
    for (var i = decorators.length - 1; i >= 0; i--) {
        var context = {};
        for (var p in contextIn) context[p] = p === "access" ? {} : contextIn[p];
        for (var p in contextIn.access) context.access[p] = contextIn.access[p];
        context.addInitializer = function (f) { if (done) throw new TypeError("Cannot add initializers after decoration has completed"); extraInitializers.push(accept(f || null)); };
        var result = (0, decorators[i])(kind === "accessor" ? { get: descriptor.get, set: descriptor.set } : descriptor[key], context);
        if (kind === "accessor") {
            if (result === void 0) continue;
            if (result === null || typeof result !== "object") throw new TypeError("Object expected");
            if (_ = accept(result.get)) descriptor.get = _;
            if (_ = accept(result.set)) descriptor.set = _;
            if (_ = accept(result.init)) initializers.unshift(_);
        }
        else if (_ = accept(result)) {
            if (kind === "field") initializers.unshift(_);
            else descriptor[key] = _;
        }
    }
    if (target) Object.defineProperty(target, contextIn.name, descriptor);
    done = true;
};
var __runInitializers = (this && this.__runInitializers) || function (thisArg, initializers, value) {
    var useValue = arguments.length > 2;
    for (var i = 0; i < initializers.length; i++) {
        value = useValue ? initializers[i].call(thisArg, value) : initializers[i].call(thisArg);
    }
    return useValue ? value : void 0;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
import Http from '@mortvola/http';
import { DateTime } from 'luxon';
import { observable, runInAction } from 'mobx';
let Statement = (() => {
    var _a, _Statement_id_accessor_storage, _Statement_startDate_accessor_storage, _Statement_endDate_accessor_storage, _Statement_startingBalance_accessor_storage, _Statement_endingBalance_accessor_storage, _Statement_credits_accessor_storage, _Statement_debits_accessor_storage;
    let _id_decorators;
    let _id_initializers = [];
    let _id_extraInitializers = [];
    let _startDate_decorators;
    let _startDate_initializers = [];
    let _startDate_extraInitializers = [];
    let _endDate_decorators;
    let _endDate_initializers = [];
    let _endDate_extraInitializers = [];
    let _startingBalance_decorators;
    let _startingBalance_initializers = [];
    let _startingBalance_extraInitializers = [];
    let _endingBalance_decorators;
    let _endingBalance_initializers = [];
    let _endingBalance_extraInitializers = [];
    let _credits_decorators;
    let _credits_initializers = [];
    let _credits_extraInitializers = [];
    let _debits_decorators;
    let _debits_initializers = [];
    let _debits_extraInitializers = [];
    return _a = class Statement {
            get id() { return __classPrivateFieldGet(this, _Statement_id_accessor_storage, "f"); }
            set id(value) { __classPrivateFieldSet(this, _Statement_id_accessor_storage, value, "f"); }
            get startDate() { return __classPrivateFieldGet(this, _Statement_startDate_accessor_storage, "f"); }
            set startDate(value) { __classPrivateFieldSet(this, _Statement_startDate_accessor_storage, value, "f"); }
            get endDate() { return __classPrivateFieldGet(this, _Statement_endDate_accessor_storage, "f"); }
            set endDate(value) { __classPrivateFieldSet(this, _Statement_endDate_accessor_storage, value, "f"); }
            get startingBalance() { return __classPrivateFieldGet(this, _Statement_startingBalance_accessor_storage, "f"); }
            set startingBalance(value) { __classPrivateFieldSet(this, _Statement_startingBalance_accessor_storage, value, "f"); }
            get endingBalance() { return __classPrivateFieldGet(this, _Statement_endingBalance_accessor_storage, "f"); }
            set endingBalance(value) { __classPrivateFieldSet(this, _Statement_endingBalance_accessor_storage, value, "f"); }
            get credits() { return __classPrivateFieldGet(this, _Statement_credits_accessor_storage, "f"); }
            set credits(value) { __classPrivateFieldSet(this, _Statement_credits_accessor_storage, value, "f"); }
            get debits() { return __classPrivateFieldGet(this, _Statement_debits_accessor_storage, "f"); }
            set debits(value) { __classPrivateFieldSet(this, _Statement_debits_accessor_storage, value, "f"); }
            constructor(props) {
                _Statement_id_accessor_storage.set(this, __runInitializers(this, _id_initializers, void 0));
                _Statement_startDate_accessor_storage.set(this, (__runInitializers(this, _id_extraInitializers), __runInitializers(this, _startDate_initializers, void 0)));
                _Statement_endDate_accessor_storage.set(this, (__runInitializers(this, _startDate_extraInitializers), __runInitializers(this, _endDate_initializers, void 0)));
                _Statement_startingBalance_accessor_storage.set(this, (__runInitializers(this, _endDate_extraInitializers), __runInitializers(this, _startingBalance_initializers, void 0)));
                _Statement_endingBalance_accessor_storage.set(this, (__runInitializers(this, _startingBalance_extraInitializers), __runInitializers(this, _endingBalance_initializers, void 0)));
                _Statement_credits_accessor_storage.set(this, (__runInitializers(this, _endingBalance_extraInitializers), __runInitializers(this, _credits_initializers, void 0)));
                _Statement_debits_accessor_storage.set(this, (__runInitializers(this, _credits_extraInitializers), __runInitializers(this, _debits_initializers, void 0)));
                __runInitializers(this, _debits_extraInitializers);
                this.id = props.id;
                this.startDate = DateTime.fromISO(props.startDate);
                this.endDate = DateTime.fromISO(props.endDate);
                this.startingBalance = props.startingBalance;
                this.endingBalance = props.endingBalance;
                this.credits = props.credits;
                this.debits = props.debits;
            }
            async update(update) {
                const response = await Http.patch(`/api/v1/statements/${this.id}`, update);
                if (response.ok) {
                    const props = await response.body();
                    runInAction(() => {
                        this.credits = props.credits;
                        this.debits = props.debits;
                        this.startDate = DateTime.fromISO(props.startDate);
                        this.endDate = DateTime.fromISO(props.endDate);
                        this.startingBalance = props.startingBalance;
                        this.endingBalance = props.endingBalance;
                    });
                }
            }
        },
        _Statement_id_accessor_storage = new WeakMap(),
        _Statement_startDate_accessor_storage = new WeakMap(),
        _Statement_endDate_accessor_storage = new WeakMap(),
        _Statement_startingBalance_accessor_storage = new WeakMap(),
        _Statement_endingBalance_accessor_storage = new WeakMap(),
        _Statement_credits_accessor_storage = new WeakMap(),
        _Statement_debits_accessor_storage = new WeakMap(),
        (() => {
            const _metadata = typeof Symbol === "function" && Symbol.metadata ? Object.create(null) : void 0;
            _id_decorators = [observable];
            _startDate_decorators = [observable];
            _endDate_decorators = [observable];
            _startingBalance_decorators = [observable];
            _endingBalance_decorators = [observable];
            _credits_decorators = [observable];
            _debits_decorators = [observable];
            __esDecorate(_a, null, _id_decorators, { kind: "accessor", name: "id", static: false, private: false, access: { has: obj => "id" in obj, get: obj => obj.id, set: (obj, value) => { obj.id = value; } }, metadata: _metadata }, _id_initializers, _id_extraInitializers);
            __esDecorate(_a, null, _startDate_decorators, { kind: "accessor", name: "startDate", static: false, private: false, access: { has: obj => "startDate" in obj, get: obj => obj.startDate, set: (obj, value) => { obj.startDate = value; } }, metadata: _metadata }, _startDate_initializers, _startDate_extraInitializers);
            __esDecorate(_a, null, _endDate_decorators, { kind: "accessor", name: "endDate", static: false, private: false, access: { has: obj => "endDate" in obj, get: obj => obj.endDate, set: (obj, value) => { obj.endDate = value; } }, metadata: _metadata }, _endDate_initializers, _endDate_extraInitializers);
            __esDecorate(_a, null, _startingBalance_decorators, { kind: "accessor", name: "startingBalance", static: false, private: false, access: { has: obj => "startingBalance" in obj, get: obj => obj.startingBalance, set: (obj, value) => { obj.startingBalance = value; } }, metadata: _metadata }, _startingBalance_initializers, _startingBalance_extraInitializers);
            __esDecorate(_a, null, _endingBalance_decorators, { kind: "accessor", name: "endingBalance", static: false, private: false, access: { has: obj => "endingBalance" in obj, get: obj => obj.endingBalance, set: (obj, value) => { obj.endingBalance = value; } }, metadata: _metadata }, _endingBalance_initializers, _endingBalance_extraInitializers);
            __esDecorate(_a, null, _credits_decorators, { kind: "accessor", name: "credits", static: false, private: false, access: { has: obj => "credits" in obj, get: obj => obj.credits, set: (obj, value) => { obj.credits = value; } }, metadata: _metadata }, _credits_initializers, _credits_extraInitializers);
            __esDecorate(_a, null, _debits_decorators, { kind: "accessor", name: "debits", static: false, private: false, access: { has: obj => "debits" in obj, get: obj => obj.debits, set: (obj, value) => { obj.debits = value; } }, metadata: _metadata }, _debits_initializers, _debits_extraInitializers);
            if (_metadata) Object.defineProperty(_a, Symbol.metadata, { enumerable: true, configurable: true, writable: true, value: _metadata });
        })(),
        _a;
})();
export default Statement;
