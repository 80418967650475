/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Field, ErrorMessage, } from 'formik';
import { makeUseModal } from '@mortvola/usemodal';
import { FormModal, FormField } from '@mortvola/forms';
import { DateTime } from 'luxon';
import AccountItem from './AccountItem';
const AccountsDialog = ({ account, setShow, }) => {
    const handleValidate = (values) => {
        const errors = {};
        // if (!values.tracking.some((s) => s !== 'None')) {
        //   errors.tracking = 'No tracking options selected';
        // }
        // if (values.startDate === '') {
        //   errors.startDate = 'Start is required';
        // }
        return errors;
    };
    const handleSubmit = async (values) => {
        // if (!institution.unlinkedAccounts) {
        //   throw new Error('unlinkedAccounts is undefined');
        // }
        // const accountTracking: AccountTrackingProps[] = accounts
        //   .map((a, i) => {
        //     if (values.tracking === null) {
        //       throw new Error('account selections is null');
        //     }
        //     if (values.tracking[i]) {
        //       return ({ id: a.id, mask: a.mask, tracking: values.tracking[i] })
        //     }
        //     return ({ id: a.id, mask: a.mask, tracking: 'Transactions' as TrackingType })
        //   })
        //   // .filter((a) => (a.tracking !== 'None' && a.tracking !== undefined));
        const response = null;
        // if (institution) {
        //   response = await institution.update();
        // }
        // else {
        //   if (metadata.institution === null) {
        //     throw new Error('institution is null');
        //   }
        //   response = await accounts.addInstitution(
        //     publicToken, metadata.institution.institution_id,
        //   );
        // }
        if (account) {
            account.startDate = DateTime.fromISO(values.startDate);
            account.tracking = values.tracking;
            // if (response) {
            account.setSettings({
                startDate: DateTime.fromISO(values.startDate),
                tracking: values.tracking,
            });
        }
        setShow(false);
        // }
    };
    // useEffect(() => {
    //   institution.getUnlinkedAccounts();
    // }, [institution]);
    return (React.createElement(FormModal, { initialValues: {
            tracking: account?.tracking ?? 'Transactions',
            startDate: account?.startDate?.toISODate() ?? '',
        }, setShow: setShow, validate: handleValidate, onSubmit: handleSubmit, title: "Account Settings", formId: "UnlinkedAccounts" },
        React.createElement("div", { className: "account-name" }, account?.name),
        React.createElement("div", { style: {
                display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1rem', justifyItems: 'start',
            } },
            React.createElement(FormField, { name: "startDate", label: "Start Date:", type: "date", style: { height: '2.5rem' } }),
            React.createElement(Field, { name: "tracking", label: "Tracking:", as: AccountItem })),
        React.createElement(ErrorMessage, { name: "tracking" })));
};
const observedAccountsDialog = observer(AccountsDialog);
export const useAccountsDialog = makeUseModal(AccountsDialog);
export default observedAccountsDialog;
