import Http from '@mortvola/http';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
const IncomeVsExpenses = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        (async () => {
            const response = await Http.get('/api/v1/reports/income-vs-expenses');
            if (response.ok) {
                const body = await response.body();
                // Append the income/expense data to the end of each row of the table.
                body.splice(0, 0, ['date', 'income', 'expenses', 'net']);
                setData(body);
            }
        })();
    }, []);
    return (React.createElement("div", { className: "chart-wrapper window window1" }, data !== null
        ? (React.createElement(Chart, { chartType: "ComboChart", data: data, options: {
                width: '100%',
                height: '100%',
                legend: { position: 'none' },
                isStacked: true,
                hAxis: {
                    slantedText: true,
                },
                seriesType: 'bars',
                series: {
                    [data[0].length - 2]: { type: 'line' },
                },
                focusTarget: 'datum',
            } }))
        : null));
};
export default IncomeVsExpenses;
