import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
class FundingPlanCategory {
    constructor(props) {
        this.goalDate = DateTime.now();
        this.id = props.id;
        this.amount = props.amount;
        this.categoryId = props.categoryId;
        this.useGoal = props.useGoal;
        if (props.goalDate) {
            this.goalDate = DateTime.fromISO(props.goalDate);
        }
        this.recurrence = props.recurrence;
        makeAutoObservable(this);
    }
    static computeMonthlyAmount(category, goalAmount, monthsToGoal) {
        const remaining = goalAmount - Math.min(goalAmount, (category ? category.balance : 0));
        return (monthsToGoal === 0
            ? remaining
            : Math.ceil((remaining / monthsToGoal) * 100) / 100);
    }
    static sanitizeGoalDate(goalDate) {
        if (goalDate === '' || goalDate === null) {
            const gd = DateTime.now().startOf('month').plus({ months: 1 }).toISODate();
            if (gd === null) {
                throw new Error('gd is null');
            }
            return gd;
        }
        return goalDate;
    }
    static computeMonths(goalDate) {
        const date = DateTime.fromISO(FundingPlanCategory.sanitizeGoalDate(goalDate)).startOf('month');
        const lastFunding = DateTime.now().startOf('month');
        const duration = date.diff(lastFunding, 'months');
        return Math.max(0, duration.months);
    }
    monthlyAmount(category) {
        return this.useGoal
            ? FundingPlanCategory
                .computeMonthlyAmount(category, this.amount, FundingPlanCategory.computeMonths(this.goalDate.toISODate()))
            : this.amount;
    }
}
export default FundingPlanCategory;
