import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, matchPath, useLocation, useNavigate, } from 'react-router-dom';
import CategoryView from './CategoryView/CategoryView';
import HomeToolbar from './CategoryView/CategoryViewToolbar';
import { useStores } from '../State/Store';
import styles from './Home.module.scss';
import Main from '../Main';
import DesktopView from '../DesktopView';
import MobileView from '../MobileView';
import NavigationView from '../NavigationView';
const Home = observer(() => {
    const { categoryTree } = useStores();
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    React.useEffect(() => {
        const matched = matchPath({ path: '/home/:category', caseSensitive: false, end: true }, location.pathname);
        if (matched) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [location.pathname]);
    const handleClose = () => {
        navigate('/home');
    };
    if (categoryTree.initialized) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DesktopView, null,
                React.createElement(Main, { toolbar: React.createElement(HomeToolbar, { open: open }), sidebar: (React.createElement(CategoryView, null)), className: styles.theme },
                    React.createElement(Outlet, null))),
            React.createElement(MobileView, null,
                React.createElement(NavigationView, { title: "Categories", open: open, onClose: handleClose, details: (React.createElement(Outlet, null)) },
                    React.createElement(CategoryView, null)))));
    }
    return null;
});
export default Home;
