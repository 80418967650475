import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStores } from './State/Store';
const RequireAuth = observer(({ children }) => {
    const store = useStores();
    const location = useLocation();
    if (!store.user.authenticated) {
        return React.createElement(Navigate, { to: "/signin", state: { from: location }, replace: true });
    }
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    React.createElement(React.Fragment, null, children));
});
export default RequireAuth;
