import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import NetWorth from './NetWorth';
import Payee from './Payee';
import Category from './Category';
import Main from '../Main';
import styles from './Reports.module.scss';
import useMediaQuery from '../MediaQuery';
import ReportList from './ReportList';
import IncomeVsExpenses from './IncomeVsExpenses';
import FundingHistory from './FundingHistory';
import BudgetProgress from './BudgetProgress';
const Reports = observer(() => {
    const [reportType, setReportType] = useState(null);
    const [open, setOpen] = useState(false);
    const { isMobile } = useMediaQuery();
    const handleToggleClick = () => {
        setOpen(!open);
    };
    const handleSelect = (value) => {
        setReportType(value);
        if (isMobile) {
            setOpen(false);
        }
    };
    const reports = [
        { value: 'netWorth', name: 'Net Worth' },
        { value: 'payee', name: 'Payee' },
        { value: 'category', name: 'Category' },
        { value: 'incomeVsExpenses', name: 'Income Vs. Expenses' },
        { value: 'fundingHistory', name: 'Funding History' },
        { value: 'budgetProgress', name: 'Budget Progress' },
    ];
    const renderReport = () => {
        switch (reportType) {
            case 'netWorth':
                return React.createElement(NetWorth, null);
            case 'payee':
                return React.createElement(Payee, null);
            case 'category':
                return React.createElement(Category, null);
            case 'incomeVsExpenses':
                return React.createElement(IncomeVsExpenses, null);
            case 'fundingHistory':
                return React.createElement(FundingHistory, null);
            case 'budgetProgress':
                return React.createElement(BudgetProgress, null);
            default:
                return React.createElement("div", { className: "chart-wrapper window window1" });
        }
        return null;
    };
    return (React.createElement(Main, { sidebar: React.createElement(ReportList, { reports: reports, onSelect: handleSelect, selectedValue: reportType }), onToggleClick: handleToggleClick, className: styles.theme }, renderReport()));
});
export default Reports;
