import { DateTime } from 'luxon';
class PlaidLog {
    constructor(props) {
        this.id = props.id;
        this.createdAt = DateTime.fromISO(props.createdAt);
        this.request = props.request;
        this.response = props.response;
        this.status = props.status;
        this.institutionId = props.institutionId;
    }
}
export default PlaidLog;
