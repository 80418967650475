import React from 'react';
import DateTime from '../DateTime';
import styles from './TransactionLog.module.scss';
const TransactionLog = ({ log, onClick, }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(log.transactionId);
        }
    };
    return (React.createElement("div", { key: log.id, className: styles.layout, onClick: handleClick },
        React.createElement(DateTime, { dateTime: log.date }),
        React.createElement("div", { className: styles.message }, log.message)));
};
export default TransactionLog;
