import React, { useMemo, useState, } from 'react';
import Icon from '../Icon';
import styles from './SortableTable.module.scss';
export default function useSortableTable(keyPrecedence) {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState(() => {
        if (keyPrecedence !== undefined) {
            return { column: keyPrecedence[0], direction: 'ascending' };
        }
        return null;
    });
    const sorted = useMemo(() => {
        const sortedData = data.slice();
        if (sortConfig !== null && sortedData !== null) {
            const compare2 = (a, b, key) => {
                let value = 0;
                const x = a[key];
                const y = b[key];
                if (typeof x === 'number' && typeof y === 'number') {
                    value = sortConfig.direction === 'ascending' ? x - y : y - x;
                }
                else if (typeof x === 'string' && typeof y === 'string') {
                    if (sortConfig.direction === 'ascending') {
                        value = x.localeCompare(y);
                    }
                    else {
                        value = y.localeCompare(x);
                    }
                }
                return value;
            };
            sortedData.sort((a, b) => {
                let value = 0;
                value = compare2(a, b, sortConfig.column);
                if (keyPrecedence) {
                    let index = 0;
                    while (value === 0 && index <= keyPrecedence.length - 1) {
                        if (sortConfig.column !== keyPrecedence[index]) {
                            value = compare2(a, b, keyPrecedence[index]);
                        }
                        index += 1;
                    }
                }
                return value;
            });
        }
        return sortedData;
    }, [data, keyPrecedence, sortConfig]);
    const processTitleClick = (column) => {
        let direction = 'ascending';
        if (sortConfig !== null && sortConfig.column === column) {
            direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        }
        setSortConfig({ column, direction });
    };
    const SortableTable = ({ children, }) => (React.createElement("div", { className: styles.tableWrapper },
        React.createElement("div", { className: styles.table }, children)));
    const SortableTableHeader = ({ className, children, }) => (React.createElement("div", { className: className }, children));
    const SortableTableBody = ({ className, style, children, }) => (React.createElement("div", { className: `striped ${className}`, style: { ...style, overflowY: 'scroll' } }, sorted !== null
        ? (sorted.map(children))
        : null));
    const SortableColumn = ({ column, className, style, children, }) => {
        const sortIcon = () => {
            if (sortConfig && sortConfig.column === column) {
                if (sortConfig.direction === 'ascending') {
                    return React.createElement(Icon, { icon: "sort-up" });
                }
                return React.createElement(Icon, { icon: "sort-down" });
            }
            return React.createElement(Icon, { icon: "sort", style: { color: 'darkgray' } });
        };
        return (React.createElement("div", { className: className, style: style, onClick: () => processTitleClick(column) },
            children,
            sortIcon()));
    };
    SortableTable.Column = SortableColumn;
    SortableTable.Header = SortableTableHeader;
    SortableTable.Body = SortableTableBody;
    return {
        setData,
        SortableTable,
    };
}
