import React from 'react';
import { useAutoAssignmentDialog } from './AutoAssignmentDialog';
const AutoAssigmentsToolbar = () => {
    const [AutoAssignmentDialog, showDialog] = useAutoAssignmentDialog();
    const addAutoAssignment = () => {
        showDialog();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", onClick: addAutoAssignment }, "Add..."),
        React.createElement(AutoAssignmentDialog, null)));
};
export default AutoAssigmentsToolbar;
