import React from 'react';
const CategorySelectorGroup = ({ group, children, level = 0, }) => {
    const handleClick = (event) => {
        event.stopPropagation();
    };
    if (group.children.length > 0) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "cat-list-item", onClick: handleClick, style: { marginLeft: 24 * level } }, group.name),
            children));
    }
    return null;
};
export default CategorySelectorGroup;
