import React from 'react';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { useStores } from '../State/Store';
import Bill from './Bill';
import styles from './Overview.module.scss';
import MinorTitle from './MinorTitle';
const Overview = observer(() => {
    const { overview } = useStores();
    return (React.createElement("div", { className: styles.layout },
        React.createElement("div", null,
            React.createElement(MinorTitle, null, "Monthly Bills"),
            overview.bills
                .filter((bill) => !bill.suspended && bill.date?.month === DateTime.now().month && bill.recurrence === 1)
                .map((bill) => (React.createElement(Bill, { key: bill.id, bill: bill })))),
        React.createElement("div", null,
            React.createElement(MinorTitle, null, "Other Bills Due This Month"),
            overview.bills
                .filter((bill) => !bill.suspended && bill.date?.month === DateTime.now().month && bill.recurrence !== 1)
                .map((bill) => (React.createElement(Bill, { key: bill.id, bill: bill })))),
        React.createElement("div", null,
            React.createElement(MinorTitle, null, "Upcoming Bills"),
            overview.bills
                .filter((bill) => !bill.suspended && bill.date?.month !== DateTime.now().month)
                .map((bill) => (React.createElement(Bill, { key: bill.id, bill: bill }))))));
});
export default Overview;
