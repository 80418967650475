import React from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styles from './TabView.module.scss';
import TabViewButton from './TabViewButton';
import { useStores } from '../State/Store';
import TabViewMenu from './TabViewMenu';
import TabViewMenuItem from './TabViewMenuItem';
const TabView = observer(() => {
    const { uiState, user } = useStores();
    const navigate = useNavigate();
    const params = useParams();
    const handleCategoriesClick = () => {
        if (uiState.selectedCategory !== undefined && uiState.selectedCategory !== null) {
            if (params.categoryId === undefined || parseInt(params.categoryId, 10) !== uiState.selectedCategory.id) {
                navigate(`/home/${uiState.selectedCategory.id}`);
            }
            else {
                navigate('/home');
            }
        }
        else {
            navigate('/home');
        }
    };
    const handleAccountClick = () => {
        navigate('/user');
    };
    const handleBillsClick = () => {
        navigate('/bills');
    };
    const handleLogsClick = () => {
        navigate('/logs');
    };
    const handlePlaidLogsClick = () => {
        navigate('/admin');
    };
    const handlePlansClick = () => {
        navigate('/plans');
    };
    const handleAutoAssignClick = () => {
        navigate('/auto-assignments');
    };
    const handleSearchClick = () => {
        navigate('/search');
    };
    const handleOtherClick = () => {
        console.log('not implemented');
    };
    const handleAccountsClick = () => {
        if (uiState.selectedAccount !== undefined && uiState.selectedAccount !== null) {
            if (params.accountId === undefined || parseInt(params.accountId, 10) !== uiState.selectedAccount.id) {
                navigate(`/accounts/${uiState.selectedAccount.id}`);
            }
            else {
                navigate('/accounts');
            }
        }
        else {
            navigate('/accounts');
        }
    };
    return (React.createElement("div", { className: styles.layout },
        React.createElement(TabViewButton, { icon: "inbox", caption: "Categories", url: "/home", onClick: handleCategoriesClick }),
        React.createElement(TabViewButton, { icon: "file-invoice-dollar", caption: "Bills", url: "/bills", onClick: handleBillsClick }),
        React.createElement(TabViewButton, { icon: "building-columns", caption: "Accounts", url: "/accounts", onClick: handleAccountsClick }),
        React.createElement(TabViewButton, { icon: "file", caption: "Reports", url: "/reports", onClick: handleOtherClick }),
        React.createElement(TabViewMenu, { icon: "ellipsis", caption: "More" },
            React.createElement(TabViewMenuItem, { onClick: handleAccountClick }, "Account"),
            React.createElement(TabViewMenuItem, { onClick: handleAutoAssignClick }, "Auto Assign"),
            React.createElement(TabViewMenuItem, { onClick: handleSearchClick }, "Search"),
            React.createElement(TabViewMenuItem, { onClick: handleLogsClick }, "Logs"),
            user.roles.includes('ADMIN')
                ? (React.createElement(TabViewMenuItem, { onClick: handlePlaidLogsClick }, "Plaid Logs"))
                : null)));
});
export default TabView;
