import React from 'react';
import { Form, Formik, } from 'formik';
import Http from '@mortvola/http';
import { FormError, setFormErrors, FormField, SubmitButton, } from '@mortvola/forms';
import { Button } from 'react-bootstrap';
import styles from './Signin.module.scss';
import { isErrorResponse } from '../../common/ResponseTypes';
const EnterPassCode = ({ context, onNext, link, }) => {
    const [resendStatus, setResendStatus] = React.useState('');
    const handleSubmit = async (values, { setErrors }) => {
        const response = await Http.post('/api/v1/code-verify', {
            email: context.email,
            code: values.code,
        });
        if (response.ok) {
            const { data } = await response.body();
            Http.setTokens(data.access, data.refresh);
            onNext(context);
        }
        else {
            const body = await response.body();
            if (isErrorResponse(body)) {
                setFormErrors(setErrors, body.errors);
            }
        }
    };
    const sendCode = async () => {
        setResendStatus('Sending new code.');
        const response = await Http.post('/api/v1/code-request', {
            email: context.email,
        });
        if (response.ok) {
            setResendStatus('A new code has been sent to the email address you provided.');
        }
        else {
            setResendStatus('An error occured. Please try again later.');
        }
    };
    return (React.createElement(Formik, { initialValues: {
            code: '',
        }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(Form, { className: styles.form },
        React.createElement("div", { className: styles.description }, "A a one-time pass code has been sent to the email address you provided to verify you are the owner. Enter the one-time pass code below and click the Verify Code button."),
        React.createElement(FormField, { name: "code", label: "Pass Code", autoComplete: "one-time-code" }),
        React.createElement(SubmitButton, { className: styles.button, isSubmitting: isSubmitting, label: "Verify Code", submitLabel: "Verifying Code" }),
        React.createElement("div", { className: styles.description }, "If you did not receive the code or if the code has expired, click the button below to send a new code to your email address."),
        React.createElement(Button, { className: styles.button, onClick: sendCode }, "Send New Code"),
        React.createElement("div", { className: styles.status }, resendStatus),
        link,
        React.createElement(FormError, { name: "general" })))));
};
export default EnterPassCode;
