import React from 'react';
import { useCategoryDialog } from './CategoryDialog';
import IconButton from '../../IconButton';
const EditButton = ({ category, }) => {
    const [CategoryDialog, showCategoryDialog] = useCategoryDialog();
    const showDialog = () => {
        switch (category.type) {
            case 'REGULAR':
                showCategoryDialog();
                break;
            case 'BILL':
                showCategoryDialog();
                break;
            default:
                showCategoryDialog();
                break;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { icon: "edit", onClick: showDialog }),
        React.createElement(CategoryDialog, { category: category })));
};
export default EditButton;
