var __esDecorate = (this && this.__esDecorate) || function (ctor, descriptorIn, decorators, contextIn, initializers, extraInitializers) {
    function accept(f) { if (f !== void 0 && typeof f !== "function") throw new TypeError("Function expected"); return f; }
    var kind = contextIn.kind, key = kind === "getter" ? "get" : kind === "setter" ? "set" : "value";
    var target = !descriptorIn && ctor ? contextIn["static"] ? ctor : ctor.prototype : null;
    var descriptor = descriptorIn || (target ? Object.getOwnPropertyDescriptor(target, contextIn.name) : {});
    var _, done = false;
    for (var i = decorators.length - 1; i >= 0; i--) {
        var context = {};
        for (var p in contextIn) context[p] = p === "access" ? {} : contextIn[p];
        for (var p in contextIn.access) context.access[p] = contextIn.access[p];
        context.addInitializer = function (f) { if (done) throw new TypeError("Cannot add initializers after decoration has completed"); extraInitializers.push(accept(f || null)); };
        var result = (0, decorators[i])(kind === "accessor" ? { get: descriptor.get, set: descriptor.set } : descriptor[key], context);
        if (kind === "accessor") {
            if (result === void 0) continue;
            if (result === null || typeof result !== "object") throw new TypeError("Object expected");
            if (_ = accept(result.get)) descriptor.get = _;
            if (_ = accept(result.set)) descriptor.set = _;
            if (_ = accept(result.init)) initializers.unshift(_);
        }
        else if (_ = accept(result)) {
            if (kind === "field") initializers.unshift(_);
            else descriptor[key] = _;
        }
    }
    if (target) Object.defineProperty(target, contextIn.name, descriptor);
    done = true;
};
var __runInitializers = (this && this.__runInitializers) || function (thisArg, initializers, value) {
    var useValue = arguments.length > 2;
    for (var i = 0; i < initializers.length; i++) {
        value = useValue ? initializers[i].call(thisArg, value) : initializers[i].call(thisArg);
    }
    return useValue ? value : void 0;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
import { observable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import Category, { isCategory } from './Category';
import { isErrorResponse, isAddCategoryResponse, GroupType, CategoryType, } from '../../common/ResponseTypes';
let Group = (() => {
    var _a, _Group_id_accessor_storage, _Group_name_accessor_storage, _Group_type_accessor_storage, _Group_children_accessor_storage;
    let _id_decorators;
    let _id_initializers = [];
    let _id_extraInitializers = [];
    let _name_decorators;
    let _name_initializers = [];
    let _name_extraInitializers = [];
    let _type_decorators;
    let _type_initializers = [];
    let _type_extraInitializers = [];
    let _children_decorators;
    let _children_initializers = [];
    let _children_extraInitializers = [];
    return _a = class Group {
            get id() { return __classPrivateFieldGet(this, _Group_id_accessor_storage, "f"); }
            set id(value) { __classPrivateFieldSet(this, _Group_id_accessor_storage, value, "f"); }
            get name() { return __classPrivateFieldGet(this, _Group_name_accessor_storage, "f"); }
            set name(value) { __classPrivateFieldSet(this, _Group_name_accessor_storage, value, "f"); }
            get type() { return __classPrivateFieldGet(this, _Group_type_accessor_storage, "f"); }
            set type(value) { __classPrivateFieldSet(this, _Group_type_accessor_storage, value, "f"); }
            get children() { return __classPrivateFieldGet(this, _Group_children_accessor_storage, "f"); }
            set children(value) { __classPrivateFieldSet(this, _Group_children_accessor_storage, value, "f"); }
            constructor(props, store) {
                _Group_id_accessor_storage.set(this, __runInitializers(this, _id_initializers, void 0));
                _Group_name_accessor_storage.set(this, (__runInitializers(this, _id_extraInitializers), __runInitializers(this, _name_initializers, void 0)));
                _Group_type_accessor_storage.set(this, (__runInitializers(this, _name_extraInitializers), __runInitializers(this, _type_initializers, void 0)));
                _Group_children_accessor_storage.set(this, (__runInitializers(this, _type_extraInitializers), __runInitializers(this, _children_initializers, [])));
                this.group = (__runInitializers(this, _children_extraInitializers), null);
                this.id = props.id;
                this.name = props.name;
                this.type = props.type;
                this.store = store;
            }
            getFundingPool() {
                if (this.group === null) {
                    throw new Error('gropu not set');
                }
                return this.group?.getFundingPool();
            }
            findCategory(categoryId) {
                let stack = [
                    ...this.children,
                ];
                while (stack.length > 0) {
                    const node = stack[0];
                    stack = stack.slice(1);
                    if (isCategory(node)) {
                        if (node.id === categoryId) {
                            return node;
                        }
                        const subcategory = node.subcategories.find((subcat) => subcat.id === categoryId);
                        if (subcategory) {
                            return subcategory;
                        }
                    }
                    else {
                        stack.push(...node.children);
                    }
                }
                return null;
            }
            async addCategory(params) {
                const { group, fundingCategories, goalDate, ...p } = params;
                const response = await Http.post(`/api/v1/groups/${this.id}/categories`, {
                    ...p,
                    fundingCategories: p.type === CategoryType.Bill
                        ? fundingCategories
                        : [],
                    groupId: this.id,
                    goalDate: goalDate?.toISODate(),
                });
                const body = await response.body();
                if (!response.ok) {
                    if (isErrorResponse(body)) {
                        return body.errors;
                    }
                }
                else if (isAddCategoryResponse(body)) {
                    runInAction(() => {
                        const category = new Category(body, this.store);
                        // Find the position where this new category should be inserted.
                        this.insertCategory(category);
                    });
                }
                return null;
            }
            insertCategory(category) {
                // Find the position where this new category should be inserted.
                if (this.type === GroupType.NoGroup) {
                    this.store.categoryTree.insertNode(category);
                }
                const index = this.children.findIndex((g) => category.name.localeCompare(g.name) < 0);
                if (index === -1) {
                    this.children.push(category);
                }
                else {
                    this.children = [
                        ...this.children.slice(0, index),
                        category,
                        ...this.children.slice(index),
                    ];
                }
                category.group = this;
            }
            async update(value) {
                const response = await Http.patch(`/api/v1/groups/${this.id}`, { ...value, hidden: false });
                const body = await response.body();
                if (!response.ok) {
                    if (isErrorResponse(body)) {
                        return body.errors;
                    }
                }
                else {
                    runInAction(() => {
                        if (body.data !== undefined) {
                            this.name = body.data.name;
                        }
                    });
                }
                return null;
            }
            async delete() {
                const response = await Http.delete(`/api/v1/groups/${this.id}`);
                if (!response.ok) {
                    const body = await response.body();
                    if (isErrorResponse(body)) {
                        return body.errors;
                    }
                }
                else {
                    runInAction(() => {
                        this.store.categoryTree.removeNode(this);
                    });
                }
                return null;
            }
            removeCategory(category) {
                if (this.type === GroupType.NoGroup) {
                    this.store.categoryTree.removeNode(category);
                }
                const index = this.children.findIndex((c) => c.id === category.id);
                if (index !== -1) {
                    this.children.splice(index, 1);
                }
            }
        },
        _Group_id_accessor_storage = new WeakMap(),
        _Group_name_accessor_storage = new WeakMap(),
        _Group_type_accessor_storage = new WeakMap(),
        _Group_children_accessor_storage = new WeakMap(),
        (() => {
            const _metadata = typeof Symbol === "function" && Symbol.metadata ? Object.create(null) : void 0;
            _id_decorators = [observable];
            _name_decorators = [observable];
            _type_decorators = [observable];
            _children_decorators = [observable];
            __esDecorate(_a, null, _id_decorators, { kind: "accessor", name: "id", static: false, private: false, access: { has: obj => "id" in obj, get: obj => obj.id, set: (obj, value) => { obj.id = value; } }, metadata: _metadata }, _id_initializers, _id_extraInitializers);
            __esDecorate(_a, null, _name_decorators, { kind: "accessor", name: "name", static: false, private: false, access: { has: obj => "name" in obj, get: obj => obj.name, set: (obj, value) => { obj.name = value; } }, metadata: _metadata }, _name_initializers, _name_extraInitializers);
            __esDecorate(_a, null, _type_decorators, { kind: "accessor", name: "type", static: false, private: false, access: { has: obj => "type" in obj, get: obj => obj.type, set: (obj, value) => { obj.type = value; } }, metadata: _metadata }, _type_initializers, _type_extraInitializers);
            __esDecorate(_a, null, _children_decorators, { kind: "accessor", name: "children", static: false, private: false, access: { has: obj => "children" in obj, get: obj => obj.children, set: (obj, value) => { obj.children = value; } }, metadata: _metadata }, _children_initializers, _children_extraInitializers);
            if (_metadata) Object.defineProperty(_a, Symbol.metadata, { enumerable: true, configurable: true, writable: true, value: _metadata });
        })(),
        _a;
})();
export const isGroup = (r) => (r !== undefined && r !== null
    && r.id !== undefined
    && r.name !== undefined
    && r.children !== undefined);
export const isCategoriesArray = (r) => ((Array.isArray(r))
    && (r.length === 0 || isCategory(r[0])));
export default Group;
