import React from 'react';
import Amount from '../Amount';
import Date from '../Date';
import styles from './Balance.module.scss';
const Balance = ({ balance, showBalanceDialog, }) => {
    const handleClick = () => {
        showBalanceDialog(balance);
    };
    return (React.createElement("div", { key: balance.id, className: styles.balance, onClick: handleClick },
        React.createElement(Date, { date: balance.date }),
        React.createElement(Amount, { amount: balance.balance })));
};
export default Balance;
