import React from 'react';
import { Form, Formik, } from 'formik';
import * as responsive from 'react-responsive';
import Http from '@mortvola/http';
import { FormError, setFormErrors, FormField, SubmitButton, } from '@mortvola/forms';
import { useNavigate } from 'react-router-dom';
import styles from './Signin.module.scss';
import { isErrorResponse } from '../../common/ResponseTypes';
const Signin = () => {
    const tiny = responsive.useMediaQuery({ query: '(max-width: 350px)' });
    const small = responsive.useMediaQuery({ query: '(max-width: 600px)' });
    const medium = responsive.useMediaQuery({ query: '(max-width: 1224px)' });
    const navigate = useNavigate();
    const addSizeClass = (className) => {
        if (tiny && styles.tiny) {
            return `${styles.tiny} ${className}`;
        }
        if (small && styles.small) {
            return `${styles.small} ${className}`;
        }
        if (medium && styles.medium) {
            return `${styles.medium} ${className}`;
        }
        return className;
    };
    const handleSubmit = async (values, { setErrors }) => {
        const response = await Http.post('/api/v1/login', values);
        if (response.ok) {
            const { data } = await response.body();
            Http.setTokens(data.access, data.refresh);
            navigate('/home');
        }
        else {
            const body = await response.body();
            if (isErrorResponse(body)) {
                setFormErrors(setErrors, body.errors);
            }
        }
    };
    const handleTitleClick = () => {
        navigate('/');
    };
    return (React.createElement("div", { className: addSizeClass(styles.frame) },
        React.createElement("div", { className: styles.title, onClick: handleTitleClick }, "SpendCraft"),
        React.createElement(Formik, { initialValues: {
                username: '',
                password: '',
            }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(Form, { className: styles.form },
            React.createElement("div", { className: styles.subtitle }, "Sign In"),
            React.createElement("div", { className: styles.description }, "Enter your username and password."),
            React.createElement(FormField, { name: "username", label: "Username", autoComplete: "username" }),
            React.createElement(FormField, { type: "password", name: "password", label: "Password", autoComplete: "current-password" }),
            React.createElement(SubmitButton, { className: styles.button, isSubmitting: isSubmitting, label: "Sign In", submitLabel: "Signing In" }),
            React.createElement("a", { href: "/recover-password" }, "Forgot Your Password?"),
            React.createElement(FormError, { name: "general" }))))));
};
export default Signin;
