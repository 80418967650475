import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
const Confirmation = ({ title, buttonTitle, onConfirm, onHide, show, children, }) => (React.createElement(Modal, { onHide: onHide, show: show },
    React.createElement(Modal.Header, { closeButton: true },
        React.createElement(Modal.Title, null, title)),
    React.createElement(Modal.Body, null, children),
    React.createElement(Modal.Footer, null,
        React.createElement(Button, { variant: "secondary", onClick: onHide }, "Cancel"),
        React.createElement(Button, { variant: "danger", onClick: onConfirm }, buttonTitle))));
function useDeleteConfirmation(title, buttonTitle, children, onDelete) {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const handleConfirmClick = () => {
        setConfirmDelete(true);
    };
    const handleHide = () => {
        setConfirmDelete(false);
    };
    const handleConfirm = () => {
        onDelete();
        handleHide();
    };
    const createConfirmation = () => (React.createElement(Confirmation, { title: title, buttonTitle: buttonTitle, show: confirmDelete, onHide: handleHide, onConfirm: handleConfirm }, children));
    return [
        createConfirmation,
        handleConfirmClick,
    ];
}
export default Confirmation;
export { useDeleteConfirmation };
