import React from 'react';
import styles from './TransactionsSync.module.scss';
import Transaction from './Transaction';
const TransactionsSync = ({ response, }) => (React.createElement("div", { className: styles.layout },
    React.createElement("label", null,
        "Request ID:",
        React.createElement("div", null, response.request_id)),
    React.createElement("label", null,
        "Transaction Update Status:",
        React.createElement("div", null, response.transactions_update_status)),
    React.createElement("label", null,
        "Next Cursor:",
        React.createElement("div", null, response.next_cursor)),
    React.createElement("label", null,
        "Has More:",
        React.createElement("div", null, response.has_more.toString())),
    React.createElement("div", { className: styles.section }, "Accounts:"),
    React.createElement("div", { className: styles.transactionsWrapper }, response.accounts.map((acct) => (React.createElement("div", { key: acct.account_id, className: styles.account },
        React.createElement("label", null,
            "Name:",
            React.createElement("div", null, acct.name)),
        React.createElement("label", null,
            "Account ID:",
            React.createElement("div", null, acct.account_id)),
        React.createElement("label", null,
            "Balance:",
            React.createElement("div", null, acct.balances.current)),
        React.createElement("label", null,
            "Available:",
            React.createElement("div", null, acct.balances.available)),
        React.createElement("label", null,
            "Limit:",
            React.createElement("div", null, acct.balances.limit)),
        React.createElement("label", null,
            "Last Updated:",
            React.createElement("div", null, acct.balances.last_updated_datetime)))))),
    React.createElement("div", { className: styles.section }, "Added Transactions:"),
    React.createElement("div", { className: styles.transactionsWrapper }, response.added.map((trx) => (React.createElement(Transaction, { key: trx.transaction_id, transaction: trx })))),
    React.createElement("div", { className: styles.section }, "Modified Transactions:"),
    React.createElement("div", { className: styles.transactionsWrapper }, response.modified.map((trx) => (React.createElement(Transaction, { key: trx.transaction_id, transaction: trx })))),
    React.createElement("div", { className: styles.section }, "Removed Transactions:"),
    React.createElement("div", { className: styles.transactionsWrapper }, response.removed.map((trx) => (React.createElement("div", { key: trx.transaction_id },
        React.createElement("label", null,
            "Trx ID:",
            React.createElement("div", null, trx.transaction_id)),
        React.createElement("label", null,
            "Account ID:",
            React.createElement("div", null, trx.account_id))))))));
export default TransactionsSync;
