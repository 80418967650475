import { makeAutoObservable, runInAction } from 'mobx';
class UIState {
    constructor(store) {
        this.selectedCategory = null;
        this.selectedPlan = null;
        this.selectedAccount = null;
        this.selectedStatement = null;
        this.selectedTransaction = null;
        this.plaid = null;
        makeAutoObservable(this);
        this.store = store;
    }
    selectCategory(category) {
        runInAction(() => {
            this.selectedCategory = category;
        });
    }
    selectAccount(account) {
        runInAction(() => {
            this.selectedAccount = account;
        });
    }
    selectStatement(statement) {
        runInAction(() => {
            this.selectedStatement = statement;
        });
    }
    selectPlan(plan) {
        runInAction(() => {
            this.selectedPlan = plan;
        });
    }
    selectTransaction(transaction) {
        runInAction(() => {
            this.selectedTransaction = transaction;
        });
    }
}
export default UIState;
