import React from 'react';
import TitleStub from './TitleStub';
import styles from './Transactions.module.scss';
const RegisterTitles = ({ transactionClassName, }) => (React.createElement("div", { className: `${styles.registerTitle} ${styles.transaction} ${transactionClassName ?? ''}` },
    React.createElement("div", null),
    React.createElement("div", { className: styles.date }, "Date"),
    React.createElement("div", { className: styles.name }, "Name"),
    React.createElement("div", { className: `${styles.trxAmount} currency` }, "Trx Amount"),
    React.createElement("div", { className: `${styles.amount} currency` }, "Amount"),
    React.createElement("div", { className: `${styles.interest} currency` }, "Interest"),
    React.createElement("div", { className: `${styles.principle} currency` }, "Principle"),
    React.createElement("div", { className: `${styles.runningBalance} currency` }, "Balance"),
    React.createElement("div", { className: styles.reconcile }, "C"),
    React.createElement("div", { className: styles.owner }, "Owner"),
    React.createElement("div", { className: styles.account }, "Account"),
    React.createElement(TitleStub, null)));
export default RegisterTitles;
