import React, { useState, useRef, useEffect, } from 'react';
import ReactDOM from 'react-dom';
import CategorySelector, { categoryFiltered } from './CategorySelector';
import useExclusiveBool from '../ExclusiveBool';
import { useStores } from '../State/Store';
import { isGroup } from '../State/Group';
import { isCategory } from '../State/Category';
const CategoryInput = ({ categoryId = null, value, name, onCategoryChange, className, }) => {
    const { categoryTree } = useStores();
    const { nodes } = categoryTree;
    const [selected, setSelected] = useState({ groupIndex: null, categoryIndex: null });
    const initialValue = () => {
        if (value !== undefined) {
            return ((categoryTree.unassignedCat && parseInt(value, 10) === categoryTree.unassignedCat.id)
                ? ''
                : (categoryTree.getCategoryName(parseInt(value, 10)) ?? ''));
        }
        return (categoryId === null || (categoryTree.unassignedCat && categoryId === categoryTree.unassignedCat.id)
            ? ''
            : (categoryTree.getCategoryName(categoryId) ?? ''));
    };
    const [open, setOpen] = useExclusiveBool(false);
    const [inputValue, setInputValue] = useState(initialValue);
    const [originalValue, setOriginalValue] = useState(initialValue);
    const [filter, setFilter] = useState({
        value: initialValue(), parts: initialValue().toLowerCase().split(':'),
    });
    const inputRef = useRef(null);
    const selectorRef = useRef(null);
    const handleCancel = () => {
        setOpen(false);
        setInputValue(originalValue);
        setFilter({ value: '', parts: [] });
    };
    const handleSelect = (category) => {
        let groupIndex = nodes.findIndex((g) => g.id === category.id || g.id === category.groupId);
        let categoryIndex;
        const group = nodes[groupIndex];
        if (isGroup(group)) {
            categoryIndex = group.categories.findIndex((c) => c.id === category.id);
        }
        else {
            categoryIndex = groupIndex;
            groupIndex = null;
        }
        setSelected({ groupIndex, categoryIndex });
        setOpen(false);
        setInputValue(categoryTree.getCategoryName(category.id) ?? '');
        setOriginalValue(categoryId === null ? '' : (categoryTree.getCategoryName(categoryId) ?? ''));
        if (onCategoryChange) {
            onCategoryChange(category);
        }
    };
    const filtered = (groupIndex, categoryIndex, filterParts) => {
        const node = nodes[groupIndex];
        return (isGroup(node) && (node.categories.length === 0
            || categoryFiltered(node, node.categories[categoryIndex], filterParts))) || (isCategory(node) && categoryFiltered(null, node, filterParts));
    };
    const traverseList = (selection, moveOne, filterParts) => {
        const newSelection = { ...selection };
        for (;;) {
            if (moveOne(newSelection)) {
                if (newSelection.groupIndex === null) {
                    throw new Error('group index is null');
                }
                if (newSelection.categoryIndex === null) {
                    throw new Error('category index is null');
                }
                if (!filtered(newSelection.groupIndex, newSelection.categoryIndex, filterParts)) {
                    return newSelection;
                }
            }
            else {
                return null;
            }
        }
    };
    const moveDownOne = (newSelection) => {
        if (newSelection.groupIndex === null || newSelection.categoryIndex === null) {
            newSelection.groupIndex = 0;
            newSelection.categoryIndex = 0;
        }
        else {
            let node = nodes[newSelection.groupIndex];
            if (isCategory(node)) {
                if (newSelection.groupIndex < nodes.length - 1) {
                    newSelection.groupIndex += 1;
                    node = nodes[newSelection.groupIndex];
                    newSelection.categoryIndex = 0;
                }
                else {
                    return false;
                }
            }
            else if (newSelection.categoryIndex < node.categories.length - 1) {
                newSelection.categoryIndex += 1;
            }
            else if (newSelection.groupIndex < nodes.length - 1) {
                newSelection.groupIndex += 1;
                node = nodes[newSelection.groupIndex];
                newSelection.categoryIndex = 0;
            }
            else {
                // Could not move down
                return false;
            }
        }
        return true;
    };
    const setFirstBestSelection = (filterParts) => {
        const selection = traverseList({ groupIndex: null, categoryIndex: null }, moveDownOne, filterParts);
        if (selection === null) {
            setSelected({ groupIndex: null, categoryIndex: null });
        }
        else {
            setSelected(selection);
        }
    };
    const openDropDown = () => {
        const origValue = categoryId === null ? '' : (categoryTree.getCategoryName(categoryId) ?? '');
        setOriginalValue(origValue);
        setFilter({ value: inputValue, parts: inputValue.toLowerCase().split(':') });
        setOpen(true);
        setFirstBestSelection(filter.parts);
    };
    const handleClick = (event) => {
        event.stopPropagation();
        if (!open) {
            openDropDown();
        }
    };
    const handleFocus = () => {
        openDropDown();
    };
    const handleBlur = () => {
        setOpen(false);
    };
    const moveUpOne = (newSelection) => {
        if (newSelection.groupIndex === null || newSelection.categoryIndex === null) {
            newSelection.groupIndex = 0;
            newSelection.categoryIndex = 0;
        }
        else {
            let node = nodes[newSelection.groupIndex];
            if (isCategory(node)) {
                if (newSelection.groupIndex > 0) {
                    newSelection.groupIndex -= 1;
                    node = nodes[newSelection.groupIndex];
                    newSelection.categoryIndex = 0;
                    if (isGroup(node) && node.categories.length > 0) {
                        newSelection.categoryIndex = node.categories.length - 1;
                    }
                }
                else {
                    return false;
                }
            }
            else if (newSelection.categoryIndex > 0) {
                newSelection.categoryIndex -= 1;
            }
            else if (newSelection.groupIndex > 0) {
                newSelection.groupIndex -= 1;
                node = nodes[newSelection.groupIndex];
                newSelection.categoryIndex = 0;
                if (isGroup(node) && node.categories.length > 0) {
                    newSelection.categoryIndex = node.categories.length - 1;
                }
            }
            else {
                // Could not move up
                return false;
            }
        }
        return true;
    };
    const handleDown = (filterParts) => {
        const selection = traverseList(selected, moveDownOne, filterParts);
        if (selection !== null) {
            setSelected(selection);
        }
    };
    const handleChange = (event) => {
        setInputValue(event.target.value);
        const newFilter = {
            value: event.target.value,
            parts: event.target.value !== '' ? event.target.value.toLowerCase().split(':') : [],
        };
        setFilter(newFilter);
        setFirstBestSelection(newFilter.parts);
    };
    const handleUp = (filterParts) => {
        const selection = traverseList(selected, moveUpOne, filterParts);
        if (selection !== null) {
            setSelected(selection);
        }
    };
    const handleEnter = () => {
        let selectedGroup = null;
        if (selected.groupIndex !== null) {
            selectedGroup = nodes[selected.groupIndex];
            if (isCategory(selectedGroup)) {
                setInputValue(categoryTree.getCategoryName(selectedGroup.id) ?? '');
                if (onCategoryChange) {
                    onCategoryChange(selectedGroup);
                }
            }
            else if (isGroup(selectedGroup) && selectedGroup && selected.categoryIndex !== null) {
                const selectedCategory = selectedGroup.categories[selected.categoryIndex];
                setInputValue(categoryTree.getCategoryName(selectedCategory.id) ?? '');
                if (onCategoryChange) {
                    onCategoryChange(selectedCategory);
                }
            }
            else {
                setInputValue('');
            }
        }
        setOpen(false);
    };
    const handleMouseDown = (event) => {
        const input = inputRef.current;
        const selector = selectorRef.current;
        if (input && !input.contains(event.target)
            && (selector === null || !selector.contains(event.target))) {
            event.stopPropagation();
            handleCancel();
        }
    };
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleMouseDown, false);
            return () => {
                document.removeEventListener('mousedown', handleMouseDown);
            };
        }
        return undefined;
    });
    const handleKeydown = (event) => {
        if (open && selectorRef.current) {
            switch (event.key) {
                case 'Escape': {
                    event.stopPropagation();
                    event.preventDefault();
                    handleCancel();
                    break;
                }
                case 'ArrowDown':
                    event.preventDefault();
                    handleDown(filter.parts);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    handleUp(filter.parts);
                    break;
                case 'Enter':
                    event.preventDefault();
                    handleEnter();
                    break;
                case 'Tab':
                    handleEnter();
                    break;
                default:
            }
        }
        else if (event.key.length === 1 || event.key === 'Backspace' || event.key === 'Delete'
            || event.key === 'ArrowDown') {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
            }
            openDropDown();
        }
    };
    const renderSelector = () => {
        const element = inputRef.current;
        if (open && element) {
            const input = element.getBoundingClientRect();
            const containerRect = document.documentElement.getBoundingClientRect();
            let height = Math.min(containerRect.bottom - input.bottom, 250);
            let top = input.bottom;
            const topHeight = Math.min(input.top - containerRect.top, 250);
            if (topHeight > height) {
                height = topHeight;
                top = input.top - height;
            }
            let selectedCategory = null;
            if (selected.groupIndex !== null) {
                const selectedGroup = nodes[selected.groupIndex];
                if (isGroup(selectedGroup)) {
                    if (selected.categoryIndex === null) {
                        throw new Error('category index is null');
                    }
                    selectedCategory = selectedGroup.categories[selected.categoryIndex];
                }
                else {
                    if (!isCategory(selectedGroup)) {
                        throw new Error('group is not a category');
                    }
                    selectedCategory = selectedGroup;
                }
            }
            const hiddenElement = document.querySelector('#hidden');
            if (hiddenElement) {
                const viewport = window.visualViewport;
                if (viewport === null) {
                    throw new Error('viewport is null');
                }
                return ReactDOM.createPortal(React.createElement(CategorySelector, { ref: selectorRef, left: input.left + viewport.offsetLeft, top: top + viewport.offsetTop, width: input.width < 200 ? 200 : input.width, height: height, selectedCategory: selectedCategory, onSelect: handleSelect, filter: filter.parts }), hiddenElement);
            }
            return null;
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { ref: inputRef, 
            // className={`category-input ${className ?? ''}`}
            className: className ?? '', type: "text", placeholder: "Unassigned", onClick: handleClick, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, onKeyDown: handleKeydown, value: inputValue || '', name: name }),
        renderSelector()));
};
export default CategoryInput;
