import React from 'react';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import Amount from '../Amount';
import styles from './StatementView.module.scss';
import StatementDate from './StatementDate';
import StatementAmount from './StatementAmount';
const StatementView = observer(({ statement, }) => {
    const handleReconcileAll = () => {
        statement.update({ reconcile: 'All' });
    };
    const handleUnreconcileAll = () => {
        statement.update({ reconcile: 'None' });
    };
    const handleStartDateUpdate = (date) => {
        statement.update({ startDate: date });
    };
    const handleEndDateUpdate = (date) => {
        statement.update({ endDate: date });
    };
    const handleStartingBalanceUpdate = (amount) => {
        statement.update({ startingBalance: amount });
    };
    const handleEndingBalanceUpdate = (amount) => {
        statement.update({ endingBalance: amount });
    };
    return (React.createElement("div", { className: styles.layout },
        React.createElement("div", null,
            React.createElement("label", null,
                "Date Range:",
                React.createElement(StatementDate, { date: statement.startDate, onUpdate: handleStartDateUpdate }),
                "to",
                React.createElement(StatementDate, { date: statement.endDate, onUpdate: handleEndDateUpdate })),
            React.createElement("label", null,
                "Starting Balance:",
                React.createElement(StatementAmount, { amount: statement.startingBalance, onUpdate: handleStartingBalanceUpdate })),
            React.createElement("label", null,
                "Credits:",
                React.createElement(Amount, { amount: statement.credits })),
            React.createElement("label", null,
                "Debits:",
                React.createElement(Amount, { amount: statement.debits })),
            React.createElement("label", null,
                "Ending Balance:",
                React.createElement(Amount, { amount: statement.startingBalance + statement.credits + statement.debits })),
            React.createElement("label", null,
                "Target Ending Balance:",
                React.createElement(StatementAmount, { amount: statement.endingBalance, onUpdate: handleEndingBalanceUpdate })),
            React.createElement("label", null,
                "Ending Balance Difference:",
                React.createElement(Amount, { amount: statement.startingBalance + statement.credits + statement.debits - statement.endingBalance }))),
        React.createElement("div", { className: styles.controls },
            React.createElement(Button, { onClick: handleReconcileAll }, "Reconcile All"),
            React.createElement(Button, { onClick: handleUnreconcileAll }, "Reconcile None"))));
});
export default StatementView;
