import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Transactions.module.scss';
import RemoteDataManager from '../RemoteDataManager';
import useMediaQuery from '../MediaQuery';
const RegisterTransactions = observer(({ trxContainer, titles, children, }) => {
    const { isMobile } = useMediaQuery();
    return (React.createElement(React.Fragment, null,
        isMobile
            ? null
            : titles,
        React.createElement(RemoteDataManager, { data: trxContainer },
            React.createElement("div", { className: styles.transactions }, children))));
});
export default RegisterTransactions;
