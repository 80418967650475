import React, { useRef, useEffect } from 'react';
const CategorySelectorCategory = ({ category, selected, selectedCategory, onSelect, level = 0, }) => {
    const ref = useRef(null);
    useEffect(() => {
        const element = ref.current;
        if (selected && element) {
            element.scrollIntoView(false);
        }
    }, [selected, ref]);
    const handleClick = (event) => {
        event.stopPropagation();
        onSelect(category);
    };
    let className = 'cat-list-cat cat-list-item category-select-item';
    if (selected) {
        className += ' selected';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className, onClick: handleClick, ref: ref, style: { marginLeft: level * 24 } }, category.name),
        category.subcategories.map((subcat) => (React.createElement(CategorySelectorCategory, { key: `${subcat.id}`, category: subcat, selected: selectedCategory !== null && subcat.id === selectedCategory?.id, onSelect: onSelect, level: level + 1 })))));
};
export default CategorySelectorCategory;
