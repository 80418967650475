import React from 'react';
const PurchaseLocation = ({ location }) => (React.createElement("div", null,
    React.createElement("div", null,
        location.city && location.region
            ? (React.createElement(React.Fragment, null,
                React.createElement("div", null, location.address),
                React.createElement("div", null, `${location.city}, ${location.region} ${location.postalCode ?? ''}`)))
            : null,
        location.storeNumber
            ? React.createElement("div", null, `Store Number: ${location.storeNumber}`)
            : null)));
export default PurchaseLocation;
