import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import Institution from './Institution';
import { useStores } from '../State/Store';
import styles from './AccountView.module.scss';
const AccountView = observer(({ opened, onAccountSelected, }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { accounts, uiState } = useStores();
    const handleAccountSelected = (account) => {
        navigate(account.id.toString());
        if (onAccountSelected) {
            onAccountSelected();
        }
    };
    React.useEffect(() => {
        accounts.load();
    }, [accounts]);
    React.useEffect(() => {
        if (accounts.initialized) {
            if (params.accountId !== undefined) {
                const acct = accounts.findAccount(parseInt(params.accountId, 10));
                if (acct && acct.closed === !opened) {
                    uiState.selectAccount(acct);
                }
                else {
                    navigate('/accounts');
                }
            }
            else {
                uiState.selectAccount(null);
            }
        }
    }, [accounts, accounts.initialized, accounts.institutions, navigate, opened, params, params.accountId, uiState]);
    const handleAccountStateChange = (account) => {
        if (uiState.selectedAccount === account) {
            uiState.selectAccount(null);
        }
    };
    return (React.createElement("div", { className: styles.accounts }, accounts.institutions
        .filter((institution) => (opened ? institution.hasOpenAccounts() : institution.hasClosedAccounts()))
        .map((institution) => (React.createElement(Institution, { key: institution.id, institution: institution, onAccountSelected: handleAccountSelected, onAccountStateChange: handleAccountStateChange, selectedAccount: uiState.selectedAccount, opened: opened })))));
});
export default AccountView;
