import { DateTime } from 'luxon';
class Bill {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.amount = props.fundingAmount;
        this.date = props.goalDate ? DateTime.fromISO(props.goalDate) : null;
        this.recurrence = props.recurrence;
        this.suspended = props.suspended;
        this.debits = props.debits;
    }
}
export default Bill;
