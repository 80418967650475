import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../State/Store';
import { useAutoAssignmentDialog } from './AutoAssignmentDialog';
import AutoAssignment from './AutoAssignment';
const AutoAssignmentDetails = observer(() => {
    const { autoAssignments } = useStores();
    const [AutoAssignmentDialog, showDialog] = useAutoAssignmentDialog();
    const [editAutoAssignment, setEditedAutoAssignment] = React.useState();
    const handleClick = (autoAssginment) => {
        setEditedAutoAssignment(autoAssginment);
        showDialog();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null, autoAssignments.autoAssignemnts.map((a) => (React.createElement(AutoAssignment, { key: a.id, autoAssignment: a, onClick: handleClick })))),
        React.createElement(AutoAssignmentDialog, { autoAssignment: editAutoAssignment })));
});
export default AutoAssignmentDetails;
