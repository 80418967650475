import { makeObservable, observable } from 'mobx';
import QueryManager from './QueryManager';
import Transaction from './Transaction';
class TransactionContainer {
    constructor(store, url, balanceCallback) {
        this.transactions = [];
        this.transactionsQuery = new QueryManager();
        this.balanceCallback = null;
        this.transactionResponseHandler = (body, idx, limit) => {
            if (this.balanceCallback) {
                this.balanceCallback(body.balance, body.transactionsCount);
            }
            if (idx === 0) {
                this.setTransactions(body.transactions);
            }
            else if (body.transactions.length > 0) {
                this.appendTransactions(body.transactions);
            }
            return body.transactions.length < limit;
        };
        makeObservable(this, {
            transactions: observable,
        });
        this.url = url;
        this.balanceCallback = balanceCallback ?? null;
        this.store = store;
    }
    state() {
        return this.transactionsQuery.state;
    }
    isComplete() {
        return this.transactionsQuery.fetchComplete;
    }
    async getData(index, qs) {
        this.searchString = qs;
        return this.transactionsQuery.fetch(this.url, index, this.transactionResponseHandler, qs);
    }
    getMoreData() {
        return this.getData(this.transactions.length, this.searchString);
    }
    clearTransactions() {
        this.transactions = [];
    }
    setTransactions(newTransactions) {
        const transactions = newTransactions.map((t) => (new Transaction(this.store, t)));
        this.transactions = transactions;
    }
    appendTransactions(newTransactions) {
        const transactions = newTransactions.map((t) => (new Transaction(this.store, t)));
        this.transactions = [
            ...this.transactions,
            ...transactions,
        ];
    }
    insertTransaction(transaction) {
        const index = this.transactions.findIndex((t) => transaction.date >= t.date);
        if (index === -1) {
            this.transactions.push(transaction);
        }
        else {
            this.transactions.splice(index, 0, transaction);
        }
    }
    removeTransaction(transactionId) {
        const index = this.transactions.findIndex((t) => t.id === transactionId);
        if (index !== -1) {
            this.transactions.splice(index, 1);
        }
    }
}
export default TransactionContainer;
