/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, } from 'formik';
import { makeUseModal } from '@mortvola/usemodal';
import { SubmitButton } from '@mortvola/forms';
import Http from '@mortvola/http';
import CategorySplits from './CategorySplits';
const Header = () => (React.createElement(Modal.Header, { closeButton: true },
    React.createElement("h4", { id: "modalTitle", className: "modal-title" }, "Category Transfer")));
const Footer = ({ setShow, isSubmitting }) => (React.createElement(Modal.Footer, null,
    React.createElement("div", null),
    React.createElement("div", null),
    React.createElement(Button, { variant: "secondary", onClick: () => setShow(false) }, "Cancel"),
    React.createElement(SubmitButton, { isSubmitting: isSubmitting, label: "Save", submitLabel: "Saving" })));
const CategoryTransferDialog = ({ setShow, transaction = null, }) => {
    // const amount = 0;
    const validateSplits = (splits) => {
        let error;
        if (splits !== undefined && splits.length > 0) {
            if (splits.some((split) => (split.categoryId === null || split.categoryId === undefined))) {
                error = 'There are one or more items not assigned a category.';
            }
        }
        return error;
    };
    const handleSubmit = async (values) => {
        const { date, fromCategories, toCategories } = values;
        const cats = fromCategories.concat(toCategories).map((s) => {
            if (s.id === undefined) {
                throw new Error('missing id');
            }
            if (s.id < 0) {
                const { id, ...newSplit } = s;
                return newSplit;
            }
            return s;
        });
        if (transaction) {
            const response = await Http.patch(`/api/v1/category-transfer/${transaction.id}`, { date, categories: cats });
            if (response.ok) {
                setShow(false);
            }
        }
        else {
            const response = await Http.post('/api/v1/category-transfer', { date, categories: cats });
            if (response.ok) {
                setShow(false);
            }
        }
    };
    const handleValidate = (values) => {
        const errors = {};
        const fromSum = values.fromCategories.reduce((accum, item) => accum + Math.floor(item.amount * -100), 0);
        const toSum = values.toCategories.reduce((accum, item) => accum + Math.floor(item.amount * 100), 0);
        if (fromSum !== toSum) {
            errors.fromCategories = 'The sum of the From categories does not match the sum of the To categories.';
        }
        return errors;
    };
    const handleTotalChange = () => null;
    return (React.createElement(Formik, { initialValues: {
            fromCategories: transaction && transaction.categories
                ? transaction.categories.filter((c) => c.amount < 0)
                : [{ id: -1, amount: 0 }],
            toCategories: transaction && transaction.categories
                ? transaction.categories.filter((c) => c.amount >= 0)
                : [{ id: -1, amount: 0 }],
            date: transaction ? transaction.date.toISODate() : null,
        }, validate: handleValidate, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(Form, { id: "catTransferForm", className: "scrollable-form" },
        React.createElement(Header, null),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("label", null,
                    "Date",
                    React.createElement(Field, { type: "date", name: "date" }))),
            React.createElement("label", null, "From:"),
            React.createElement("div", { className: "cat-fund-table" },
                React.createElement("div", { className: "transaction-split-item cat-fund-title" },
                    React.createElement("div", { className: "fund-list-cat-name" }, "Category"),
                    React.createElement("div", { className: "dollar-amount" }, "Balance"),
                    React.createElement("div", { className: "dollar-amount" }, "Amount"),
                    React.createElement("div", { className: "dollar-amount" }, "New Balance")),
                React.createElement(Field, { name: "fromCategories", validate: validateSplits }, ({ field: { value, name, }, form: { setFieldValue, }, }) => (React.createElement(CategorySplits, { splits: value.map((s) => ({ ...s, ...{ amount: s.amount * -1 } })), total: 0, onChange: (splits) => {
                        setFieldValue(name, splits.map((s) => ({ ...s, ...{ amount: s.amount * -1 } })));
                        handleTotalChange();
                    } }))),
                React.createElement("label", null,
                    "Transfer Total",
                    React.createElement("div", { className: "transfer-total splits-total dollar-amount" }, "0.00")))),
        React.createElement("br", null),
        React.createElement("label", null, "To:"),
        React.createElement("div", { className: "cat-fund-table" },
            React.createElement("div", { className: "transaction-split-item cat-fund-title" },
                React.createElement("div", { className: "fund-list-cat-name" }, "Category"),
                React.createElement("div", { className: "dollar-amount" }, "Balance"),
                React.createElement("div", { className: "dollar-amount" }, "Amount"),
                React.createElement("div", { className: "dollar-amount" }, "New Balance")),
            React.createElement(Field, { name: "toCategories", validate: validateSplits }, ({ field: { value, name, }, form: { setFieldValue, }, }) => (React.createElement(CategorySplits, { splits: value, total: 0, onChange: (splits) => {
                    setFieldValue(name, splits);
                    handleTotalChange();
                } }))),
            React.createElement("label", { className: "dollar-amount" },
                "Unassigned",
                React.createElement("div", { className: "available-funds splits-total dollar-amount" }, "0.00"))),
        React.createElement(ErrorMessage, { name: "splits" }),
        React.createElement(Footer, { setShow: setShow, isSubmitting: isSubmitting })))));
};
export const useCategoryTransferDialog = makeUseModal(CategoryTransferDialog);
export default CategoryTransferDialog;
