import Group from './Group';
class Budget extends Group {
    constructor() {
        super(...arguments);
        this.fundingPoolCat = null;
    }
    getFundingPool() {
        if (this.fundingPoolCat === null) {
            throw new Error('fundingPoolCat not set');
        }
        return this.fundingPoolCat;
    }
    findCategory(categoryId) {
        if (categoryId === this.fundingPoolCat?.id) {
            return this.fundingPoolCat;
        }
        const subcategory = this.fundingPoolCat?.subcategories.find((subcat) => subcat.id === categoryId);
        if (subcategory) {
            return subcategory;
        }
        return super.findCategory(categoryId);
    }
}
export default Budget;
