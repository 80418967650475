/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { makeUseModal } from '@mortvola/usemodal';
import { FormModal, FormField, setFormErrors } from '@mortvola/forms';
import AmountInput from '../AmountInput';
import styles from './BalanceDialog.module.scss';
const BalanceDialog = ({ setShow, balance = null, balances = null, }) => {
    const handleValidate = () => {
        const errors = {};
        return errors;
    };
    const handleSubmit = async (values, { setErrors }) => {
        const amount = typeof values.amount === 'string' ? parseFloat(values.amount) : values.amount;
        let errors;
        if (balance) {
            errors = await balance.update({
                date: values.date,
                amount,
            });
        }
        else {
            if (!balances) {
                throw new Error('account is null');
            }
            errors = await balances.addBalance({
                date: values.date,
                amount,
            });
        }
        if (errors) {
            setFormErrors(setErrors, errors);
        }
        else {
            setShow(false);
        }
    };
    const handleDelete = async (bag) => {
        const { setErrors } = bag;
        if (balance) {
            const errors = await balance.delete();
            if (errors) {
                setFormErrors(setErrors, errors);
            }
            else {
                setShow(false);
            }
        }
    };
    return (React.createElement(FormModal, { initialValues: {
            date: balance ? (balance.date.toISODate() ?? '') : '',
            amount: balance ? balance.balance : 0,
        }, setShow: setShow, title: balance ? 'Edit Balance' : 'Add Balance', validate: handleValidate, onSubmit: handleSubmit, onDelete: balance ? handleDelete : null },
        React.createElement("div", { className: styles.main },
            React.createElement(FormField, { type: "date", name: "date", label: "Date:" }),
            React.createElement(FormField, { as: AmountInput, name: "amount", label: "Amount:" }))));
};
export const useBalanceDialog = makeUseModal(BalanceDialog);
export default BalanceDialog;
