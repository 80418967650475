import React from 'react';
import { observer } from 'mobx-react-lite';
import Amount from '../../Amount';
import EditButton from './EditButton';
import { CategoryType } from '../../../common/ResponseTypes';
const Category = observer(({ category, selectedCategory, onCategorySelected, level = 0, }) => {
    const handleClick = () => {
        onCategorySelected(category);
    };
    const handleSubcategoryClick = (subcategory) => {
        onCategorySelected(subcategory);
    };
    let className = 'cat-list-cat';
    if (category.id === selectedCategory?.id) {
        className += ' selected';
    }
    let barClassName = 'cat-element-bar';
    if (category.type !== CategoryType.Loan
        && category.type !== CategoryType.Regular
        && category.type !== CategoryType.Bill) {
        barClassName += ' system';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className, style: { marginLeft: 25 * level }, onClick: handleClick },
            React.createElement("div", { className: barClassName },
                [CategoryType.Regular, CategoryType.Loan, CategoryType.Bill, CategoryType.Goal].includes(category.type)
                    ? React.createElement(EditButton, { category: category })
                    : null,
                React.createElement("div", { className: "cat-list-name" }, category.name)),
            React.createElement(Amount, { className: "cat-list-amt", amount: category.balance })),
        category.subcategories.length > 0
            ? (React.createElement(React.Fragment, null,
                category.subcategories.map((sub) => (React.createElement(Category, { key: `${sub.id}`, category: sub, onCategorySelected: () => handleSubcategoryClick(sub), selectedCategory: selectedCategory, level: level + 1 }))),
                React.createElement("div", { className: "cat-list-cat", style: { marginLeft: 25 * (level + 1) } },
                    React.createElement("div", { style: { justifySelf: 'end', paddingRight: '6px' } }, "Total"),
                    React.createElement(Amount, { className: "cat-list-amt", amount: category.getTotalBalance(), style: { borderTop: 'thin solid white ' } }))))
            : null));
});
export default Category;
