import React from 'react';
const StatementDate = ({ date, onUpdate, }) => {
    const [editDate, setEditDate] = React.useState(false);
    const [dateValue, setDateValue] = React.useState('');
    const handleEditDateClick = () => {
        setEditDate(true);
        setDateValue(date.toISODate() ?? '');
    };
    const handleDateChange = (event) => {
        setDateValue(event.target.value);
    };
    const handleDateKeyDown = (event) => {
        if (event.code === 'Enter') {
            setEditDate(false);
            if (dateValue !== date.toISODate()) {
                onUpdate(dateValue);
            }
        }
        else if (event.code === 'Escape') {
            setEditDate(false);
        }
    };
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    React.createElement(React.Fragment, null, editDate
        ? (React.createElement("input", { type: "date", value: dateValue, onChange: handleDateChange, onKeyDown: handleDateKeyDown }))
        : React.createElement("div", { onClick: handleEditDateClick }, date.toISODate())));
};
export default StatementDate;
