import { observer } from 'mobx-react-lite';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { useStores } from '../State/Store';
import Register from '../Transactions/Register';
import BalanceHistory from './BalanceHistory';
import styles from '../Transactions/Transactions.module.scss';
import styles2 from './AccountDetails.module.scss';
import PendingRegister from '../Transactions/PendingRegister';
import StatementsView from './Statements';
var Tab;
(function (Tab) {
    Tab["Transaction"] = "transaction";
    Tab["Pending"] = "pending";
    Tab["Balances"] = "balances";
    Tab["Statements"] = "statement";
})(Tab || (Tab = {}));
const AccountDetails = observer(() => {
    const { uiState: { selectedAccount }, } = useStores();
    const [selected, setSelected] = React.useState(Tab.Transaction);
    const handleSelect = (eventKey) => {
        if (eventKey !== null) {
            setSelected(eventKey);
        }
    };
    if (!selectedAccount) {
        return null;
    }
    const renderTab = () => {
        switch (selected) {
            case Tab.Transaction:
                return React.createElement(Register, { className: styles.acct, type: "account" });
            case Tab.Pending:
                return (React.createElement("div", { className: styles.acct },
                    React.createElement(PendingRegister, { trxContainer: selectedAccount.pendingTransactions })));
            case Tab.Balances:
                return React.createElement(BalanceHistory, null);
            case Tab.Statements:
                return React.createElement(StatementsView, { account: selectedAccount });
            default:
                return null;
        }
    };
    return (React.createElement("div", { className: `${styles2.layout}` },
        React.createElement("div", { className: `${styles2.mainTrayTitle} ellipsis` }, `${selectedAccount.institution.name}: ${selectedAccount.name}`),
        React.createElement(Nav, { variant: "underline", className: styles2.menu, onSelect: handleSelect, activeKey: selected },
            React.createElement(Nav.Link, { as: "div", eventKey: Tab.Transaction }, "Transactions"),
            React.createElement(Nav.Link, { as: "div", eventKey: Tab.Pending }, "Pending"),
            React.createElement(Nav.Link, { as: "div", eventKey: Tab.Balances }, "Balances"),
            React.createElement(Nav.Link, { as: "div", eventKey: Tab.Statements }, "Statements")),
        React.createElement("div", { className: styles2.content }, renderTab())));
});
export default AccountDetails;
