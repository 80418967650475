import React from 'react';
import Amount from '../Amount';
import Date from '../Date';
import styles from './Bill.module.scss';
const Bill = ({ bill, }) => (React.createElement("div", { key: bill.id, className: styles.layout },
    React.createElement("div", null, bill.name),
    React.createElement(Amount, { amount: bill.amount }),
    React.createElement(Date, { date: bill.date }),
    React.createElement(Amount, { amount: bill.debits, noValue: "" })));
export default Bill;
