/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, } from 'formik';
import { makeUseModal } from '@mortvola/usemodal';
import { FormModal, FormError, setFormErrors } from '@mortvola/forms';
import { useStores } from '../../State/Store';
const GroupDialog = ({ setShow, group, }) => {
    const { categoryTree } = useStores();
    const handleSubmit = async (values, formikHelpers) => {
        const { setErrors } = formikHelpers;
        let errors = null;
        if (group) {
            errors = await group.update(values.name);
        }
        else {
            errors = await categoryTree.addGroup(values.name);
        }
        if (errors) {
            setFormErrors(setErrors, errors);
        }
        else {
            setShow(false);
        }
    };
    const handleValidate = (values) => {
        const errors = {};
        if (values.name === '') {
            errors.name = 'The group name must not be blank.';
        }
        return errors;
    };
    const handleDelete = async (bag) => {
        const { setErrors } = bag;
        if (group) {
            const errors = await group.delete();
            if (errors) {
                setFormErrors(setErrors, errors);
            }
            else {
                setShow(false);
            }
        }
    };
    const title = () => {
        if (group) {
            return 'Edit Group';
        }
        return 'Add Group';
    };
    return (React.createElement(FormModal, { setShow: setShow, initialValues: {
            name: group && group.name ? group.name : '',
        }, title: title(), formId: "GroupDialogForm", validate: handleValidate, onSubmit: handleSubmit, onDelete: group ? handleDelete : null },
        React.createElement("label", null,
            "Group:",
            React.createElement(Field, { type: "text", className: "form-control", name: "name" }),
            React.createElement(FormError, { name: "name" }))));
};
export const useGroupDialog = makeUseModal(GroupDialog);
export default GroupDialog;
