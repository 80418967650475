import { makeAutoObservable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import Category, { isCategory } from './Category';
import Group, { isGroup } from './Group';
import { isErrorResponse, isGroupProps, isGroupsResponse, } from '../../common/ResponseTypes';
import SystemIds from './SystemIds';
class CategoryTree {
    constructor(store) {
        this.initialized = false;
        this.nodes = [];
        this.systemIds = new SystemIds();
        this.noGroupGroup = null;
        this.unassignedCat = null;
        this.fundingPoolCat = null;
        this.accountTransferCat = null;
        this.rebalances = null;
        makeAutoObservable(this);
        this.store = store;
    }
    // eslint-disable-next-line class-methods-use-this
    state() {
        return 'IDLE';
    }
    async getData(_id) {
        return this.load();
    }
    // eslint-disable-next-line class-methods-use-this
    async getMoreData() {
        console.log('not implemented');
    }
    // eslint-disable-next-line class-methods-use-this
    isComplete() {
        return true;
    }
    getCategoryGroup(categoryId) {
        const group = this.nodes.find((g) => {
            if (isCategory(g)) {
                return false;
            }
            return g.findCategory(categoryId) !== null;
        }) ?? (this.noGroupGroup && this.noGroupGroup.findCategory(categoryId)
            ? this.noGroupGroup
            : null);
        if (isGroup(group)) {
            return group;
        }
        throw new Error('group is null');
    }
    getCategory(categoryId) {
        let category = null;
        this.nodes.find((node) => {
            if (isCategory(node)) {
                if (node.id === categoryId) {
                    category = node;
                    return true;
                }
            }
            else {
                const cat = node.findCategory(categoryId);
                if (cat) {
                    category = cat;
                    return true;
                }
            }
            return false;
        });
        return category;
    }
    getCategoryName(categoryId) {
        let categoryName = null;
        this.nodes.find((node) => {
            if (isGroup(node)) {
                const category = node.findCategory(categoryId);
                if (category) {
                    categoryName = `${node.name}:${category.name}`;
                    return true;
                }
            }
            else if (node.id === categoryId) {
                categoryName = node.name;
                return true;
            }
            return false;
        });
        return categoryName;
    }
    insertNode(node) {
        // Find the position where this new node should be inserted.
        const index = this.nodes.findIndex((n) => (node.name.localeCompare(n.name) < 0));
        if (index === -1) {
            this.nodes.push(node);
        }
        else {
            this.nodes = [
                ...this.nodes.slice(0, index),
                node,
                ...this.nodes.slice(index),
            ];
        }
    }
    async load() {
        const response = await Http.get('/api/v1/groups');
        const { data } = await response.body();
        if (isGroupsResponse(data)) {
            runInAction(() => {
                this.nodes = [];
                data.forEach((g) => {
                    if (g.type !== 'REGULAR') {
                        this.systemIds.systemGroupId = g.id;
                        g.categories.forEach((c) => {
                            switch (c.type) {
                                case 'UNASSIGNED':
                                    this.unassignedCat = new Category(c, this.store);
                                    break;
                                case 'FUNDING POOL':
                                    this.fundingPoolCat = new Category(c, this.store);
                                    break;
                                case 'ACCOUNT TRANSFER':
                                    this.accountTransferCat = new Category(c, this.store);
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                    const group = new Group(g, this.store);
                    if (group.type === 'NO GROUP') {
                        this.noGroupGroup = group;
                    }
                    else {
                        this.nodes.push(group);
                    }
                });
                this.nodes.sort((a, b) => a.name.localeCompare(b.name));
                this.initialized = true;
                if (this.store.uiState.selectedCategory === null) {
                    this.store.uiState.selectedCategory = this.unassignedCat;
                }
            });
        }
    }
    async addGroup(name) {
        const response = await Http.post('/api/v1/groups', { name });
        const body = await response.body();
        if (!response.ok) {
            if (isErrorResponse(body)) {
                return body.errors;
            }
        }
        else {
            runInAction(() => {
                if (isGroupProps(body)) {
                    const group = new Group(body, this.store);
                    this.insertNode(group);
                }
            });
        }
        return null;
    }
    removeNode(node) {
        const index = this.nodes.findIndex((n) => n.id === node.id);
        if (index !== -1) {
            this.nodes.splice(index, 1);
        }
    }
    updateBalances(balances) {
        runInAction(() => {
            this.nodes.forEach((node) => {
                node.updateBalances(balances);
            });
        });
    }
}
export default CategoryTree;
