import React from 'react';
import { Formik, FieldArray } from 'formik';
import { DateTime } from 'luxon';
import { DropdownButton } from 'react-bootstrap';
import { FormCheckbox, FormField, SubmitButton } from '@mortvola/forms';
import Http from '@mortvola/http';
import Amount from '../Amount';
import { useStores } from '../State/Store';
import useSortableTable from './SortableTable';
import { isGroup } from '../State/Group';
import ReportControls from './ReportControls';
import styles from './Category.module.scss';
const Category = () => {
    const { categoryTree: { nodes } } = useStores();
    const { setData, SortableTable } = useSortableTable(['groupName', 'categoryName', 'sum', 'count']);
    const handleSubmit = async (values) => {
        let qp = `startDate=${values.startDate}&endDate=${values.endDate}`;
        values.category.forEach((c) => {
            if (c.value) {
                qp += `&c=${c.id}`;
            }
        });
        const response = await Http.get(`/api/v1/reports/category?${qp}`);
        if (response.ok) {
            const body = await response.body();
            const isCategoryReport = (r) => (Array.isArray(r)
                && (r.length === 0 || (r[0].groupName !== undefined
                    && r[0].categoryName !== undefined)));
            if (isCategoryReport(body)) {
                setData(body);
            }
        }
    };
    return (React.createElement("div", { className: "payee-report window window1" },
        React.createElement(Formik, { initialValues: {
                startDate: DateTime.now().minus({ years: 1 }).toISODate() ?? '',
                endDate: DateTime.now().toISODate() ?? '',
                category: (nodes.flatMap((g) => {
                    if (isGroup(g)) {
                        return g.categories.filter((c) => (c.type === 'REGULAR'))
                            .map((c) => ({
                            value: true,
                            id: c.id,
                        }));
                    }
                    return ({
                        value: true,
                        id: g.id,
                    });
                })),
            }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(ReportControls, null,
            React.createElement(FormField, { name: "startDate", type: "date", label: "Start Date:" }),
            React.createElement(FormField, { name: "endDate", type: "date", label: "End Date:" }),
            React.createElement(DropdownButton, { id: "test", title: "Categories" },
                React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: '0.5rem',
                        overflowY: 'auto',
                        maxHeight: '300px',
                    } },
                    React.createElement(FieldArray, { name: "account" }, () => {
                        let index = -1;
                        return (nodes.flatMap((g) => {
                            if (isGroup(g)) {
                                return g.categories.filter((c) => (c.type === 'REGULAR'))
                                    .map((c) => {
                                    index += 1;
                                    return (React.createElement(FormCheckbox, { key: c.id, name: `category[${index}].value`, label: `${g.name}:${c.name}` }));
                                });
                            }
                            index += 1;
                            return (React.createElement(FormCheckbox, { key: g.id, name: `category[${index}].value`, label: `${g.name}` }));
                        }));
                    }))),
            React.createElement(SubmitButton, { isSubmitting: isSubmitting, label: "Run Report", submitLabel: "Running Report" })))),
        React.createElement(SortableTable, null,
            React.createElement(SortableTable.Header, { className: `title ${styles.reportItem}` },
                React.createElement(SortableTable.Column, { column: "groupName" }, "Group"),
                React.createElement(SortableTable.Column, { column: "categoryName" }, "Category"),
                React.createElement(SortableTable.Column, { className: "dollar-amount", column: "sum" }, "Amount"),
                React.createElement(SortableTable.Column, { style: { textAlign: 'right' }, column: "count" }, "Count"),
                React.createElement("div", { style: { overflowY: 'scroll', visibility: 'hidden', padding: 0 } })),
            React.createElement(SortableTable.Body, null, (d) => (React.createElement("div", { key: d.rowNumber, className: styles.reportItem },
                React.createElement("div", { className: "ellipsis" }, d.groupName),
                React.createElement("div", { className: "ellipsis" }, d.categoryName),
                React.createElement(Amount, { amount: d.sum }),
                React.createElement("div", { style: { textAlign: 'right' } }, d.count)))))));
};
export default Category;
