import React from 'react';
import { ModalBody, Button } from 'react-bootstrap';
import { makeUseModal } from '@mortvola/usemodal';
import styles from './RelinkDialog.module.scss';
const RelinkDialog = ({ institution, setShow, }) => {
    const handleNo = () => {
        setShow(false);
    };
    const handleYes = () => {
        setShow(false);
        institution.relink();
    };
    return (React.createElement(ModalBody, null,
        React.createElement("div", null,
            "There was a problem connecting to your account. This may occur if",
            React.createElement("ul", null,
                React.createElement("li", null, "You changed the accounts password."),
                React.createElement("li", null, "You changed your multi-factor authentication device, questions or answers."),
                React.createElement("li", null, "The institution changed its security protocols.")),
            "If any of these have happend, you will need to relink to your institution. Would you like to do that now?"),
        React.createElement("div", { className: styles.buttons },
            React.createElement(Button, { onClick: handleNo }, "No"),
            React.createElement(Button, { onClick: handleYes }, "Yes"))));
};
export const useRelinkDialog = makeUseModal(RelinkDialog);
export default RelinkDialog;
