import React from 'react';
import styles from './ReportListItem.module.scss';
const ReportListItem = ({ value, onSelect, selected = false, children, }) => {
    const handleClick = () => {
        onSelect(value);
    };
    let className = styles.reportListItem;
    if (selected) {
        className += ' selected';
    }
    return (React.createElement("div", { className: className, onClick: handleClick }, children));
};
export default ReportListItem;
