import React from 'react';
import { observer } from 'mobx-react-lite';
import { Navbar, Nav, NavDropdown, } from 'react-bootstrap';
import { Link, matchPath, useLocation, useNavigate, useParams, } from 'react-router-dom';
import Http from '@mortvola/http';
import { runInAction } from 'mobx';
import { useStores } from './State/Store';
var EventKeys;
(function (EventKeys) {
    EventKeys["HOME"] = "HOME";
    EventKeys["PLANS"] = "PLANS";
    EventKeys["ACCOUNTS"] = "ACCOUNTS";
    EventKeys["SEARCH"] = "SEARCH";
    EventKeys["REPORTS"] = "REPORTS";
    EventKeys["AUTO_ASSIGN"] = "AUTO_ASSIGN";
    EventKeys["LOGS"] = "LOGS";
    EventKeys["BILLS"] = "BILLS";
    EventKeys["ADMIN"] = "ADMIN";
    EventKeys["LOGOUT"] = "LOGOUT";
})(EventKeys || (EventKeys = {}));
const pathKeys = [
    { path: '/home', key: EventKeys.HOME },
    { path: '/plans', key: EventKeys.PLANS },
    { path: '/accounts', key: EventKeys.ACCOUNTS },
    { path: '/search', key: EventKeys.SEARCH },
    { path: '/reports', key: EventKeys.REPORTS },
    { path: '/auto-assignments', key: EventKeys.AUTO_ASSIGN },
    { path: '/logs', key: EventKeys.LOGS },
    { path: '/bills', key: EventKeys.BILLS },
    { path: '/admin', key: EventKeys.ADMIN },
];
const Menubar = observer(() => {
    const store = useStores();
    const { uiState } = store;
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate();
    const [active, setActive] = React.useState(EventKeys.HOME);
    const location = useLocation();
    const params = useParams();
    React.useEffect(() => {
        const pathKey = pathKeys.find((pk) => (matchPath({ path: pk.path, caseSensitive: false, end: false }, location.pathname)));
        if (pathKey) {
            setActive(pathKey.key);
        }
    }, [location.pathname]);
    const logout = () => {
        (async () => {
            const payload = {
                data: {
                    refresh: Http.refreshToken,
                },
            };
            const response = await Http.post('/api/v1/logout', payload);
            if (response.ok) {
                runInAction(() => {
                    store.refresh();
                });
                Http.setTokens(null, null);
                navigate('/');
            }
        })();
        return null;
    };
    const handleSelect = (eventKey) => {
        setActive(eventKey ?? EventKeys.HOME);
        setExpanded(false);
        switch (eventKey) {
            case EventKeys.HOME:
                if (uiState.selectedCategory !== undefined && uiState.selectedCategory !== null) {
                    if (params.categoryId === undefined || parseInt(params.categoryId, 10) !== uiState.selectedCategory.id) {
                        navigate(`/home/${uiState.selectedCategory.id}`);
                    }
                    else {
                        navigate('/home');
                    }
                }
                else {
                    navigate('/home');
                }
                break;
            case EventKeys.ACCOUNTS:
                if (uiState.selectedAccount !== undefined && uiState.selectedAccount !== null) {
                    if (params.accountId === undefined || parseInt(params.accountId, 10) !== uiState.selectedAccount.id) {
                        navigate(`/accounts/${uiState.selectedAccount.id}`);
                    }
                    else {
                        navigate('/accounts');
                    }
                }
                else {
                    navigate('/accounts');
                }
                break;
            case EventKeys.LOGOUT:
                logout();
                break;
            default:
                break;
        }
    };
    const handleToggle = (expand) => {
        setExpanded(expand);
    };
    return (React.createElement(Navbar, { onSelect: handleSelect, onToggle: handleToggle, expand: "md", expanded: expanded, style: { userSelect: 'none' } },
        React.createElement(Navbar.Brand, { href: "/" }, "SpendCraft"),
        React.createElement(Navbar.Toggle, null),
        React.createElement(Navbar.Collapse, { style: { justifyContent: 'space-between' } },
            React.createElement(Nav, { variant: "underline", defaultActiveKey: "HOME", activeKey: active },
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { eventKey: EventKeys.HOME }, "Home")),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { as: Link, to: "/bills", eventKey: EventKeys.BILLS }, "Bills")),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { eventKey: EventKeys.ACCOUNTS }, "Accounts")),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { as: Link, to: "/search", eventKey: EventKeys.SEARCH }, "Search")),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { as: Link, to: "/reports", eventKey: EventKeys.REPORTS }, "Reports")),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { as: Link, to: "/auto-assignments", eventKey: EventKeys.AUTO_ASSIGN }, "Auto Assign")),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { as: Link, to: "/logs", eventKey: EventKeys.LOGS }, "Logs")),
                store.user.roles.includes('ADMIN')
                    ? (React.createElement(Nav.Item, null,
                        React.createElement(Nav.Link, { as: Link, to: "/admin", eventKey: EventKeys.ADMIN }, "Admin")))
                    : null),
            React.createElement(Nav, null,
                React.createElement(NavDropdown, { className: "dropdown menubar-item", title: store.user.username || '', id: "menubar-dropdown", align: "end" },
                    React.createElement(NavDropdown.Item, { as: Link, to: "/user", eventKey: "USER_ACCOUNT" }, "Account"),
                    React.createElement(NavDropdown.Item, { eventKey: EventKeys.LOGOUT }, "Sign Out"))))));
});
export default Menubar;
