/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { makeUseModal } from '@mortvola/usemodal';
import { FormField, FormModal, } from '@mortvola/forms';
import { RequestErrorCode } from '../../common/ResponseTypes';
import styles from './StatementDialog.module.scss';
import AmountInput from '../AmountInput';
const StatementDialog = ({ setShow, statement = null, account = null, onReload, }) => {
    const handleValidate = (values) => {
        const errors = {};
        return errors;
    };
    const handleSubmit = async (values) => {
        let errors;
        if (statement) {
            errors = await statement.update({
                startDate: values.startDate,
                endDate: values.endDate,
                startingBalance: values.startingBalance,
                endingBalance: values.endingBalance,
            });
        }
        else {
            if (!account) {
                throw new Error('account is null');
            }
            errors = await account.addStatement(values.startDate, values.endDate, values.startingBalance, values.endingBalance);
        }
        if (errors) {
            const error = errors.find((e) => e.code === RequestErrorCode.INCORRECT_VERSION);
            if (error) {
                setShow(false);
                if (onReload) {
                    onReload();
                }
            }
        }
        else {
            setShow(false);
        }
    };
    const handleDelete = async (bag) => {
        // const { setTouched, setErrors } = bag;
        if (statement) {
            const errors = await statement.delete();
            if (errors && errors.length > 0) {
                // setTouched({ [errors[0].field]: true }, false);
                // setFormErrors(setErrors, errors);
            }
            else {
                setShow(false);
            }
        }
    };
    return (React.createElement(FormModal, { initialValues: {
            startDate: statement ? (statement.startDate.toISODate() ?? '') : '',
            endDate: statement ? (statement.endDate.toISODate() ?? '') : '',
            startingBalance: statement ? statement.startingBalance : 0,
            endingBalance: statement ? statement.endingBalance : 0,
        }, setShow: setShow, title: statement ? 'Edit Statement' : 'Add Statement', validate: handleValidate, onSubmit: handleSubmit, onDelete: statement ? handleDelete : null },
        React.createElement("div", { className: styles.main },
            React.createElement(FormField, { name: "startDate", type: "date", label: "Start Date:" }),
            React.createElement(FormField, { name: "endDate", type: "date", label: "End Date:" }),
            React.createElement(FormField, { name: "startingBalance", label: "Starting Balance:" }, ({ field }) => (React.createElement(AmountInput, { className: "form-control", ...field }))),
            React.createElement(FormField, { name: "endingBalance", label: "Ending Balance:" }, ({ field }) => (React.createElement(AmountInput, { className: "form-control", ...field }))))));
};
export const useStatementDialog = makeUseModal(StatementDialog);
export default StatementDialog;
