import React from 'react';
import { FormField, FormModal } from '@mortvola/forms';
import { makeUseModal } from '@mortvola/usemodal';
import { FieldArray, useField, } from 'formik';
import { observer } from 'mobx-react-lite';
import CategoryInput from '../CategoryInput/CategoryInput';
import { useStores } from '../State/Store';
import styles from './AutoAssignmentDialog.module.scss';
import IconButton from '../IconButton';
import CategorySpread from '../CategorySpread/CategorySpread';
let tempId = -1;
const nextTempid = () => {
    const id = tempId;
    tempId -= 1;
    return id;
};
const FormCategoryInput = ({ name }) => {
    const [field, , helpers] = useField(name);
    const handleCategoryChange = (category) => {
        helpers.setValue(category.id);
    };
    return (React.createElement(CategoryInput, { name: name, categoryId: parseInt(field.value, 10), className: "form-control", onCategoryChange: handleCategoryChange }));
};
const AutoAssignmentDialog = observer(({ setShow, autoAssignment, }) => {
    const { autoAssignments, categoryTree: { unassignedCat } } = useStores();
    if (!unassignedCat) {
        throw new Error('Unassigned is not defined or null');
    }
    const handleValidate = (values) => {
        const errors = {};
        return errors;
    };
    const handleSubmit = async (values) => {
        if (autoAssignment) {
            await autoAssignment.update({
                name: values.name,
                searchStrings: values.searchStrings,
                categories: values.categories,
            });
        }
        else {
            await autoAssignments.add({
                name: values.name,
                searchStrings: values.searchStrings,
                categories: values.categories,
            });
        }
        setShow(false);
    };
    const handleDelete = async () => {
        if (autoAssignment) {
            await autoAssignment.delete();
        }
        setShow(false);
    };
    const initialCategories = (categories) => {
        if (!categories || categories.length === 0) {
            return [{
                    id: -1, categoryId: unassignedCat.id, amount: 100, percentage: true,
                }];
        }
        return categories.map((c) => ({
            id: c.id, categoryId: c.categoryId, amount: c.amount, percentage: c.percentage,
        }));
    };
    return (React.createElement(FormModal, { initialValues: {
            name: autoAssignment?.name ?? '',
            searchStrings: (autoAssignment?.searchStrings ?? [''])
                .map((s) => (s)),
            categories: initialCategories(autoAssignment?.categories),
        }, title: autoAssignment ? 'Edit Auto Assignment' : 'Add Auto Assignment', setShow: setShow, validate: handleValidate, onSubmit: handleSubmit, onDelete: autoAssignment ? handleDelete : undefined }, (formikProps) => (React.createElement(React.Fragment, null,
        React.createElement(FormField, { name: "name", label: "Name" }),
        React.createElement("label", { className: styles.searchStrings },
            "Search Strings",
            React.createElement(FieldArray, { name: "searchStrings" }, (arrayHelpers) => (formikProps.values.searchStrings.map((s, i) => (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement("div", { key: i, className: styles.searchStringLayout },
                React.createElement(FormField, { name: `searchStrings[${i}]`, style: { marginTop: 0 } }),
                React.createElement(IconButton, { icon: "plus", onClick: () => arrayHelpers.insert(i + 1, '') }),
                React.createElement(IconButton, { icon: "minus", onClick: () => {
                        if (formikProps.values.searchStrings.length > 1) {
                            arrayHelpers.remove(i);
                        }
                    } }))))))),
        React.createElement(CategorySpread, { name: "categories", categories: formikProps.values.categories, title: "Categories:" })))));
});
export const useAutoAssignmentDialog = makeUseModal(AutoAssignmentDialog, { size: 'md' });
export default AutoAssignmentDialog;
