import React from 'react';
import { Form, Formik, } from 'formik';
import Http from '@mortvola/http';
import { FormError, setFormErrors, FormField, SubmitButton, } from '@mortvola/forms';
import styles from './Signin.module.scss';
import { isErrorResponse } from '../../common/ResponseTypes';
const EnterEmailAddress = ({ context, onNext, link, }) => {
    const handleSubmit = async (values, { setErrors }) => {
        const response = await Http.post('/api/v1/code-request', values);
        if (response.ok) {
            onNext({ ...context, email: values.email });
        }
        else {
            const body = await response.body();
            if (isErrorResponse(body)) {
                setFormErrors(setErrors, body.errors);
            }
        }
    };
    return (React.createElement(Formik, { initialValues: {
            email: '',
        }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(Form, { className: styles.form },
        React.createElement("div", { className: styles.subtitle }, "Enter the email address associated with your account."),
        React.createElement(FormField, { name: "email", label: "E-Mail", autoComplete: "email" }),
        React.createElement(SubmitButton, { className: styles.button, isSubmitting: isSubmitting, label: "Continue", submitLabel: "Continuing" }),
        link,
        React.createElement(FormError, { name: "general" })))));
};
export default EnterEmailAddress;
