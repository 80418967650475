import React from 'react';
import useMediaQuery from './MediaQuery';
const MobileView = ({ children, }) => {
    const { isMobile } = useMediaQuery();
    return isMobile
        ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        React.createElement(React.Fragment, null, children))
        : null;
};
export default MobileView;
