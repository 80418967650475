import { DateTime } from 'luxon';
class TransactionLog {
    constructor(props) {
        this.id = props.id;
        this.date = DateTime.fromISO(props.createdAt);
        this.message = props.message;
        this.transactionId = props.transactionId;
    }
}
export default TransactionLog;
