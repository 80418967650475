import { makeAutoObservable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import FundingPlan from './FundingPlan';
import FundingPlanDetails from './FundingPlanDetails';
class Plans {
    constructor(store) {
        this.list = [];
        this.details = null;
        makeAutoObservable(this);
        this.store = store;
    }
    async load() {
        if (!this.store.uiState.selectedPlan) {
            this.list = [new FundingPlan(this.store, { id: 0, name: 'test' })];
            [this.store.uiState.selectedPlan] = this.list;
        }
    }
    async loadDetails(fundingPlan) {
        if (fundingPlan === null) {
            this.details = null;
        }
        else {
            const response = await Http.get('/api/v1/funding-plans?h=12');
            if (response.ok) {
                const body = await response.body();
                runInAction(() => {
                    this.details = new FundingPlanDetails(this.store, body);
                });
            }
        }
    }
}
export default Plans;
