import React from 'react';
import { observer } from 'mobx-react-lite';
import Amount from '../../Amount';
import { useStores } from '../../State/Store';
const SystemCategory = observer(({ category, onCategorySelected, }) => {
    const { uiState } = useStores();
    const handleClick = () => {
        if (category) {
            onCategorySelected(category);
        }
    };
    let className = 'cat-list-cat system';
    if (uiState.selectedCategory === category) {
        className += ' selected';
    }
    if (category) {
        return (React.createElement("div", { className: className, onClick: handleClick },
            React.createElement("div", { className: "cat-element-bar system" },
                React.createElement("div", { className: "cat-list-name" }, category.name)),
            React.createElement(Amount, { className: "cat-list-amt", amount: category.balance })));
    }
    return null;
});
export default SystemCategory;
