import { observer } from 'mobx-react-lite';
import React from 'react';
import Transaction from './Transaction';
import styles from './PendingRegister.module.scss';
import transactionStyles from './Transactions.module.scss';
import RegisterTransactions from './RegisterTransactions';
import RegisterTitles from './RegisterTitles';
import { TransactionType } from '../../common/ResponseTypes';
import useTrxDialog from './TrxDialog';
import { useStores } from '../State/Store';
const PendingRegister = observer(({ trxContainer, className = '', }) => {
    const [TrxDialog, showTrxDialog] = useTrxDialog(); // account ?? undefined);
    const { uiState } = useStores();
    const handleClick = (transaction) => {
        uiState.selectTransaction(transaction);
        if (transaction.type !== TransactionType.STARTING_BALANCE
            && showTrxDialog) {
            showTrxDialog(transaction);
        }
    };
    if (trxContainer && trxContainer.transactions.length > 0) {
        return (React.createElement("div", { className: `${styles.pending} ${transactionStyles.pending} ${className} window` },
            React.createElement(RegisterTransactions, { trxContainer: trxContainer, titles: React.createElement(RegisterTitles, null) }, trxContainer.transactions.map((transaction) => (React.createElement(Transaction, { key: transaction.id, transaction: transaction, amount: transaction.amount, runningBalance: 0, onClick: handleClick })))),
            React.createElement(TrxDialog, null)));
    }
    return null;
});
export default PendingRegister;
