import Http from '@mortvola/http';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import PleaseWait from '../PleaseWait';
import { useStores } from '../State/Store';
import TransactionContainer from '../State/TransactionContainer';
import RegisterTransactions from '../Transactions/RegisterTransactions';
import Transaction from '../Transactions/Transaction';
import MonthSelector from './MonthSelector';
import ReportControls from './ReportControls';
const BudgetProgress = observer(() => {
    const store = useStores();
    const [data, setData] = useState(null);
    const [days, setDays] = useState([]);
    const [value, setValue] = React.useState(() => {
        const n = DateTime.now();
        return `${n.month}-${n.year}`;
    });
    const [transactions, setTransactions] = React.useState(null);
    const [querying, setQuerying] = React.useState(false);
    useEffect(() => {
        setQuerying(true);
        (async () => {
            try {
                const response = await Http.get(`/api/v1/reports/budget-progress?m=${value}`);
                if (response.ok) {
                    const body = await response.body();
                    const date = DateTime.fromFormat(value, 'M-yyyy');
                    if (date === null || date === undefined) {
                        throw new Error('date is null');
                    }
                    const { daysInMonth } = date;
                    if (daysInMonth === undefined) {
                        throw new Error('daysInMonth is undefined');
                    }
                    const newDays = new Array(daysInMonth);
                    body.forEach((m) => {
                        newDays[DateTime.fromISO(m[0]).day - 1] = [m[1], m[2]];
                    });
                    for (let d = 0; d < newDays.length; d += 1) {
                        if (newDays[d] === undefined) {
                            if (d > 0) {
                                newDays[d] = newDays[d - 1];
                            }
                            else {
                                newDays[d] = [0, []];
                            }
                        }
                    }
                    setDays(newDays);
                    setData([
                        ['date', 'amount'],
                        ...newDays.map((d, index) => ([
                            index + 1,
                            d[0],
                        ])),
                    ]);
                }
            }
            catch (error) {
                console.log(error);
            }
            setQuerying(false);
        })();
    }, [value]);
    const handleChangeEvent = (event) => {
        setValue(event.target.value);
    };
    const fetchTransactions = React.useCallback(async (transactionIds) => {
        const queryParams = transactionIds.reduce((accum, t, index) => {
            if (index === 0) {
                return `t=${t}`;
            }
            return `${accum}&t=${t}`;
        }, '');
        const container = new TransactionContainer(store, `/api/v1/transactions?${queryParams}`);
        container.getData(0);
        setTransactions(container);
        // const response = await Http.get<TransactionProps>(`/api/v1/transactions?${queryParams}`);
        // if (response.ok) {
        //   const body = await response.body();
        // }
    }, [store]);
    const selectCallback = React.useCallback(({ chartWrapper }) => {
        const selection = chartWrapper.getChart().getSelection();
        if (selection.length > 0) {
            const transactionIds = days[selection[0].row][1];
            fetchTransactions(transactionIds);
        }
    }, [days, fetchTransactions]);
    return (React.createElement("div", { className: "chart-wrapper window window1" },
        React.createElement(Formik, { initialValues: {}, onSubmit: () => console.log('submit') },
            React.createElement(ReportControls, null,
                React.createElement(MonthSelector, { value: value, onChange: handleChangeEvent }))),
        querying
            ? React.createElement(PleaseWait, null)
            : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "chart-wrapper" }, data !== null && data.length > 1
                    ? (React.createElement(Chart, { chartType: "ColumnChart", data: data, width: "100%", height: "100%", chartEvents: [{
                                eventName: 'select',
                                callback: selectCallback,
                            }], options: {
                            legend: { position: 'none' },
                            isStacked: true,
                            focusTarget: 'datum',
                            hAxis: {
                                minValue: 1,
                            },
                        } }))
                    : null),
                transactions
                    ? (React.createElement(RegisterTransactions, { trxContainer: transactions }, transactions.transactions.map((t) => (React.createElement(Transaction, { key: t.id, transaction: t, amount: t.amount, runningBalance: 0 })))))
                    : null))));
});
export default BudgetProgress;
