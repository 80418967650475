import React from 'react';
import { observer } from 'mobx-react-lite';
import { Field, Form, Formik, } from 'formik';
import { useStores } from './State/Store';
import PostedRegister from './Transactions/PostedRegister';
import trxStyles from './Transactions/Transactions.module.scss';
import styles from './Search.module.scss';
const Search = observer(() => {
    const { searcher } = useStores();
    const handleSubmit = (values) => {
        searcher.transactions.getData(0, `name=${values.search}`);
    };
    const handleValidate = (values) => {
        const errors = {};
        if (values.search.trim() === '') {
            errors.search = 'A non-empty search string must be provided';
        }
        return errors;
    };
    return (React.createElement("div", { className: styles.layout },
        React.createElement(Formik, { initialValues: { search: '' }, onSubmit: handleSubmit, validate: handleValidate },
            React.createElement(Form, null,
                React.createElement("div", { className: styles.form },
                    React.createElement(Field, { name: "search" }),
                    React.createElement("button", { type: "submit" }, "Search")))),
        React.createElement("div", { className: `${trxStyles.registerWrapper} ${trxStyles.search}` },
            React.createElement(PostedRegister, { type: "account", trxContainer: searcher.transactions, category: null, account: null }))));
});
export default Search;
