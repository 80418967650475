import { makeAutoObservable, runInAction } from 'mobx';
import Http from '@mortvola/http';
class QueryManager {
    constructor() {
        this.state = 'IDLE';
        this.fetchComplete = false;
        makeAutoObservable(this);
    }
    async fetch(url, index, handleResponse, qs) {
        if (index === 0) {
            this.fetchComplete = false;
        }
        if (this.state === 'IDLE' && !this.fetchComplete) {
            try {
                this.state = index === 0 ? 'LOADING' : 'LOADING-MORE';
                const limit = 30;
                let newUrl;
                if (url.includes('?')) {
                    newUrl = `${url}&offset=${index ?? 0}&limit=${limit}`;
                }
                else {
                    newUrl = `${url}?offset=${index ?? 0}&limit=${limit}`;
                }
                if (qs) {
                    newUrl += `&${qs}`;
                }
                const response = await Http.get(newUrl);
                if (response.ok) {
                    const { data } = await response.body();
                    runInAction(() => {
                        if (data) {
                            this.fetchComplete = handleResponse(data, index, limit);
                            this.state = 'IDLE';
                        }
                    });
                }
                else {
                    runInAction(() => {
                        this.state = 'IDLE';
                    });
                }
            }
            catch (error) {
                this.state = 'IDLE';
                console.log(`fetch failed: ${error}`);
            }
        }
    }
}
export default QueryManager;
