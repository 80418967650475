import React, { useContext } from 'react';
import { createRoot } from 'react-dom/client';
import { observer } from 'mobx-react-lite';
import 'regenerator-runtime';
import { BrowserRouter, Routes, Route, Outlet, useLocation, useNavigate, } from 'react-router-dom';
import Http, { ServerError, serverError } from '@mortvola/http';
import '@mortvola/usemodal/dist/main.css';
import '@mortvola/forms/dist/main.css';
import { runInAction } from 'mobx';
import Menubar from './Menubar';
import Home from './Categories/Home';
import Accounts from './AccountView/Accounts';
import Reports from './Reports/Reports';
import PlaidLink from './PlaidLink';
import { StoreContext, store, useStores } from './State/Store';
import UserAccount from './UserAccount';
import usePageViews from './Tracker';
import './style.scss';
import AccountDetails from './AccountView/AccountDetails';
import CategoryDetails from './Categories/CategoryDetails';
import Rebalances from './Categories/Rebalances';
import Signup from './Credentials/Signup';
import Signin from './Credentials/Signin';
import Intro from './Intro';
import RequireAuth from './RequireAuth';
import RecoverPassword from './Credentials/RecoverPassword';
import styles from './App.module.scss';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import TabView from './TabView/TabView';
import Search from './Search';
import AutoAssigments from './AutoAssignment/AutoAssigments';
import AutoAssignmentDetails from './AutoAssignment/AutoAssignmentDetails';
import TransactionLogs from './TransactionLogs/TransactionLogs';
import TransactionLogDetails from './TransactionLogs/TransactionLogDetails';
import PlaidLogsView from './PlaidLogs/PlaidLogsView';
import PlaidLogs from './PlaidLogs/PlaidLogs';
import Overview from './Overview/Overview';
import OverviewView from './Overview/OverviewView';
const App = observer(() => {
    const error = useContext(ServerError);
    usePageViews();
    const stores = useStores();
    const location = useLocation();
    const navigate = useNavigate();
    Http.unauthorizedHandler = () => {
        if (location.pathname !== '/signin') {
            stores.refresh();
            navigate('/signin');
        }
    };
    if (Http.refreshToken) {
        runInAction(() => {
            stores.user.authenticated = true;
            if (!stores.initialized) {
                stores.user.load();
                stores.categoryTree.load();
                stores.accounts.load();
                stores.initialized = true;
            }
        });
    }
    if (error.message) {
        return (React.createElement("div", { style: { width: '100%', padding: '1rem' } },
            React.createElement("div", { style: { paddingBottom: '1rem' } }, error.message),
            React.createElement("div", null,
                "Stack:",
                React.createElement("div", { style: { overflowX: 'auto' } }, error.stack.map((s) => (React.createElement("div", { key: s, style: { paddingLeft: '1rem', whiteSpace: 'nowrap' } }, s)))))));
    }
    return (React.createElement(RequireAuth, null,
        React.createElement("div", { className: styles.layout },
            React.createElement(DesktopView, null,
                React.createElement(Menubar, null),
                React.createElement(Outlet, null)),
            React.createElement(MobileView, null,
                React.createElement(Outlet, null),
                React.createElement(TabView, null)),
            React.createElement(PlaidLink, null))));
});
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}
const container = document.querySelector('.app');
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(StoreContext.Provider, { value: store },
        React.createElement(ServerError.Provider, { value: serverError },
            React.createElement(BrowserRouter, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/", element: React.createElement(Intro, null) }),
                    React.createElement(Route, { path: "/signup", element: React.createElement(Signup, null) }),
                    React.createElement(Route, { path: "/signin", element: React.createElement(Signin, null) }),
                    React.createElement(Route, { path: "/recover-password", element: React.createElement(RecoverPassword, null) }),
                    React.createElement(Route, { path: "/", element: React.createElement(App, null) },
                        React.createElement(Route, { path: "home", element: React.createElement(Home, null) },
                            React.createElement(Route, { index: true, element: React.createElement(CategoryDetails, null) }),
                            React.createElement(Route, { path: ":categoryId", element: React.createElement(CategoryDetails, null) }),
                            React.createElement(Route, { path: "rebalances", element: React.createElement(Rebalances, null) })),
                        React.createElement(Route, { path: "accounts", element: React.createElement(Accounts, null) },
                            React.createElement(Route, { index: true, element: React.createElement(AccountDetails, null) }),
                            React.createElement(Route, { path: ":accountId", element: React.createElement(AccountDetails, null) })),
                        React.createElement(Route, { path: "search", element: React.createElement(Search, null) }),
                        React.createElement(Route, { path: "reports", element: React.createElement(Reports, null) }),
                        React.createElement(Route, { path: "auto-assignments", element: React.createElement(AutoAssigments, null) },
                            React.createElement(Route, { index: true, element: React.createElement(AutoAssignmentDetails, null) })),
                        React.createElement(Route, { path: "logs", element: React.createElement(TransactionLogs, null) },
                            React.createElement(Route, { index: true, element: React.createElement(TransactionLogDetails, null) })),
                        React.createElement(Route, { path: "bills", element: React.createElement(OverviewView, null) },
                            React.createElement(Route, { index: true, element: React.createElement(Overview, null) })),
                        React.createElement(Route, { path: "admin", element: React.createElement(PlaidLogsView, null) },
                            React.createElement(Route, { index: true, element: React.createElement(PlaidLogs, null) })),
                        React.createElement(Route, { path: "user", element: React.createElement(UserAccount, null) })))))));
}
