import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Chart from 'react-google-charts';
import { useStores } from '../State/Store';
import styles from './BalanceHistory.module.scss';
import Balance from './Balance';
import { useBalanceDialog } from './BalanceDialog';
const BalanceHistory = observer(() => {
    const { balances, uiState: { selectedAccount } } = useStores();
    const [BalanceDialog, showBalanceDialog] = useBalanceDialog();
    const [editedBalance, setEditedBalance] = useState(null);
    React.useEffect(() => {
        if (selectedAccount && selectedAccount.tracking === 'Balances') {
            balances.load(selectedAccount);
        }
    }, [balances, selectedAccount]);
    const data = balances.balances.slice()
        .sort((a, b) => {
        if (a.date > b.date) {
            return 1;
        }
        if (a.date < b.date) {
            return -1;
        }
        return 0;
    })
        .map((b) => [b.date.toISODate(), b.balance]);
    data.splice(0, 0, ['date', 'balance']);
    const showDialog = (balance) => {
        setEditedBalance(balance);
        showBalanceDialog();
    };
    const handleHideDialog = () => {
        setEditedBalance(null);
    };
    return (React.createElement("div", { className: `${styles.main} window1` },
        React.createElement("div", { className: "chart-wrapper window " },
            React.createElement(Chart, { chartType: "LineChart", data: data, options: {
                    width: '100%',
                    height: '100%',
                    legend: { position: 'none' },
                    hAxis: {
                        slantedText: true,
                    },
                } })),
        React.createElement("div", { className: "window" },
            React.createElement("div", { className: styles.list }, balances.balances.map((b) => (React.createElement(Balance, { key: b.id, balance: b, showBalanceDialog: showDialog }))))),
        React.createElement(BalanceDialog, { balance: editedBalance, onHide: handleHideDialog })));
});
export default BalanceHistory;
