import { makeAutoObservable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import { isUpdateFundingCategoryResponse, } from '../../common/ResponseTypes';
import FundingPlanCategory from './FundingPlanCategory';
class FundingPlanDetails {
    constructor(store, props) {
        this.id = props.id;
        this.history = props.history;
        this.categories = props.categories.map((c) => (new FundingPlanCategory(c)));
        makeAutoObservable(this);
        this.store = store;
    }
    // eslint-disable-next-line class-methods-use-this
    async updateCategoryAmount(category, amount) {
        // const oldAmount = category.amount;
        category.amount = amount;
        const response = await Http.put(`/api/v1/funding-plans/item/${category.categoryId}`, {
            amount,
            useGoal: category.useGoal,
            goalDate: category.goalDate,
            recurrence: category.recurrence,
        });
        if (response.ok) {
            const body = await response.body();
            if (isUpdateFundingCategoryResponse(body)) {
                runInAction(() => {
                    category.amount = amount;
                });
            }
            else {
                throw new Error('invalid response');
            }
        }
        else {
            throw new Error('invalid response');
        }
    }
}
export default FundingPlanDetails;
