import React, { useEffect, useState, } from 'react';
import CategorySplitItem from './CategorySplitItem';
import { useStores } from './State/Store';
function* creatNextIdGen() {
    let id = -1;
    for (;;) {
        yield id;
        id -= 1;
    }
}
const nextIdGen = creatNextIdGen();
const nextId = () => nextIdGen.next().value;
const CategorySplits = ({ splits, onChange, total, credit = true, }) => {
    const { categoryTree } = useStores();
    if (!categoryTree.unassignedCat) {
        throw new Error('unassigned category is null');
    }
    const [editedSplits, setEditedSplits] = useState(splits && splits.length > 0
        ? splits.map((s) => {
            if (s.id === undefined) {
                return {
                    ...s,
                    id: nextId(),
                };
            }
            return {
                ...s,
                amount: (credit ? s.amount : -s.amount),
            };
        })
        : [{
                id: nextId(), categoryId: categoryTree.unassignedCat.id, amount: total,
            }]);
    useEffect(() => {
        if (splits.length === 0 && categoryTree.unassignedCat) {
            setEditedSplits([{
                    id: nextId(), categoryId: categoryTree.unassignedCat.id, amount: total,
                }]);
        }
    }, [categoryTree.unassignedCat, splits.length, total]);
    const handleChange = (id, amount) => {
        const splitIndex = editedSplits.findIndex((s) => s.id === id);
        if (splitIndex !== -1) {
            const newSplits = [
                ...editedSplits.slice(0, splitIndex),
                { ...editedSplits[splitIndex], amount },
                ...editedSplits.slice(splitIndex + 1),
            ];
            setEditedSplits(newSplits);
            onChange(newSplits);
        }
    };
    const handleCategoryChange = (id, categoryId) => {
        const splitIndex = editedSplits.findIndex((s) => s.id === id);
        if (splitIndex !== -1) {
            const newSplits = [
                ...editedSplits.slice(0, splitIndex),
                { ...editedSplits[splitIndex], categoryId },
                ...editedSplits.slice(splitIndex + 1),
            ];
            setEditedSplits(newSplits);
            onChange(newSplits);
        }
    };
    const handleCommentChange = (id, comment) => {
        const splitIndex = editedSplits.findIndex((s) => s.id === id);
        if (splitIndex !== -1) {
            const newSplits = [
                ...editedSplits.slice(0, splitIndex),
                { ...editedSplits[splitIndex], comment },
                ...editedSplits.slice(splitIndex + 1),
            ];
            setEditedSplits(newSplits);
            onChange(newSplits);
        }
    };
    const handleAddItem = (afterId) => {
        if (!categoryTree.unassignedCat) {
            throw new Error('unassigned category is null');
        }
        const index = editedSplits.findIndex((s) => s.id === afterId);
        if (index !== -1) {
            const sum = editedSplits.reduce((accum, item) => accum + item.amount, 0);
            const amount = total - sum;
            const newSplits = editedSplits.slice();
            newSplits.splice(index + 1, 0, {
                id: nextId(), categoryId: categoryTree.unassignedCat.id, amount,
            });
            setEditedSplits(newSplits);
            onChange(newSplits);
        }
    };
    const handleDeleteItem = (id) => {
        if (editedSplits.length > 1) {
            const index = editedSplits.findIndex((s) => s.id === id);
            if (index !== -1) {
                const newSplits = editedSplits.slice();
                newSplits.splice(index, 1);
                setEditedSplits(newSplits);
                onChange(newSplits);
            }
        }
    };
    return (React.createElement("div", { className: "transaction-split-items" }, editedSplits.map((s) => (React.createElement(CategorySplitItem, { key: s.id, split: s, onAddItem: handleAddItem, onDeleteItem: handleDeleteItem, onDeltaChange: handleChange, onCategoryChange: handleCategoryChange, onCommentChange: handleCommentChange })))));
};
export default CategorySplits;
