import React, { useState } from 'react';
const AccountItem = ({ name, label, value, onChange = null, }) => {
    const [option, setOption] = useState(value);
    const handleChange = (event) => {
        setOption(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    return (React.createElement("label", { style: { marginTop: '0.5rem' } },
        label,
        React.createElement("select", { className: "form-control", name: name, value: option, onChange: handleChange, style: { height: '2.5rem' } },
            React.createElement("option", { value: "Transactions" }, "Categorized"),
            React.createElement("option", { value: "Uncategorized Transactions" }, "Uncategorized"))));
};
export default AccountItem;
