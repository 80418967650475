import Http from '@mortvola/http';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Amount from '../Amount';
import styles from './FundingHistory.module.scss';
const FundingHistory = () => {
    const [data, setData] = useState(null);
    const [totals, setTotals] = useState(null);
    const maxDisplayedMonths = 12;
    useEffect(() => {
        (async () => {
            const response = await Http.get('/api/v1/reports/funding-history');
            if (response.ok) {
                const body = await response.body();
                body.sort((a, b) => {
                    if (a.groupType === 'NO GROUP') {
                        if (b.groupType === 'NO GROUP') {
                            return a.categoryName.localeCompare(b.categoryName);
                        }
                        return a.categoryName.localeCompare(b.groupName);
                    }
                    if (b.groupType === 'NO GROUP') {
                        return a.groupName.localeCompare(b.categoryName);
                    }
                    if (a.groupName === b.groupName) {
                        return a.categoryName.localeCompare(b.categoryName);
                    }
                    return a.groupName.localeCompare(b.groupName);
                });
                const newTotals = [];
                body.forEach((g) => {
                    g.history?.forEach((h) => {
                        const total = newTotals.find((t) => t.year === h.year && t.month === h.month);
                        if (total) {
                            total.amount += h.amount;
                        }
                        else {
                            newTotals.push({ year: h.year, month: h.month, amount: h.amount });
                        }
                    });
                });
                setData(body);
                setTotals(newTotals);
            }
        })();
    }, []);
    const subtractMonth = (month, year) => {
        if (month === 1) {
            return [12, year - 1];
        }
        return [(month - 1), year];
    };
    const renderHistoryTitles = () => {
        let [year, month] = [DateTime.now().year, DateTime.now().month];
        const elements = [];
        for (let i = 0; i < maxDisplayedMonths; i += 1) {
            elements.push(React.createElement("div", { key: i, className: `${styles.historyItem} dollar-amount` }, `${year}-${month}`));
            [month, year] = subtractMonth(month, year);
        }
        return elements;
    };
    const amountsMatch = (a, b) => (a.toFixed(2) === b.toFixed(2));
    const renderHistory = (history) => {
        if (history) {
            let [year, month] = [DateTime.now().year, DateTime.now().month];
            const elements = [];
            for (let i = 0; i < maxDisplayedMonths; i += 1) {
                // eslint-disable-next-line no-loop-func
                const entry = history.find((h) => h.year === year && h.month === month);
                const [prevMonth, prevYear] = subtractMonth(month, year);
                const prevEntry = history.find((h) => h.year === prevYear && h.month === prevMonth);
                if (entry) {
                    let className = styles.historyItem;
                    if (i !== maxDisplayedMonths - 1
                        && (!prevEntry || (prevEntry && !amountsMatch(prevEntry.amount, entry.amount)))) {
                        className += ` ${styles.change}`;
                    }
                    elements.push(React.createElement(Amount, { key: i, className: className, amount: entry.amount }));
                }
                else {
                    let className = styles.historyItem;
                    if (i !== maxDisplayedMonths - 1 && prevEntry) {
                        className += ` ${styles.change}`;
                    }
                    elements.push(React.createElement("div", { key: i, className: className }));
                }
                [month, year] = subtractMonth(month, year);
            }
            return elements;
        }
        return null;
    };
    let currentGroup = null;
    const renderRow = (d, indent) => (React.createElement("div", { className: styles.row, key: d.categoryId },
        React.createElement("div", { className: `${styles.category} ${indent} ellipsis` }, d.categoryName),
        renderHistory(d.history)));
    return (React.createElement("div", { className: `window window1 ${styles.report}` },
        React.createElement("div", { className: `${styles.row} title` },
            React.createElement("div", { className: `${styles.category} ellipsis` }, "Category"),
            renderHistoryTitles()),
        React.createElement("div", { className: styles.reportItems },
            data
                ? data.map((d) => {
                    if (d.groupType === 'NO GROUP') {
                        currentGroup = d.groupName;
                        return renderRow(d, styles.indent1);
                    }
                    if (d.groupName !== currentGroup) {
                        currentGroup = d.groupName;
                        return (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.indent1 }, d.groupName),
                            renderRow(d, styles.indent2)));
                    }
                    return renderRow(d, styles.indent2);
                })
                : null,
            React.createElement("div", { className: `${styles.row} ${styles.totals}`, key: "total" },
                React.createElement("div", { className: `${styles.category} ${styles.indent1} ellipsis` }, "Totals"),
                renderHistory(totals)))));
};
export default FundingHistory;
