import { makeObservable, observable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import PlaidLog from './PlaidLog';
class PlaidLogs {
    constructor(store) {
        this.logs = [];
        this.initialized = false;
        this.store = store;
        makeObservable(this, {
            logs: observable,
        });
    }
    async load() {
        const response = await Http.get('/api/v1/admin/plaid-logs');
        if (!response.ok) {
            throw new Error('invalid response');
        }
        const body = await response.body();
        if (body) {
            runInAction(() => {
                this.logs = body.map((props) => new PlaidLog(props));
                this.initialized = true;
            });
        }
    }
}
export default PlaidLogs;
