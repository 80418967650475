import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '../IconButton';
import { useAccountsDialog } from './AccountsDialog';
import { useInstitutionInfoDialog } from './InstitutionInfoDialog';
import Account from './Account';
import { useOfflineAccountDialog } from './OfflineAccountDialog';
import { useDeleteConfirmation } from '../DeleteConfirmation';
import styles from './Institution.module.scss';
import { useRelinkDialog } from './RelinkDialog';
const Institution = observer(({ institution, opened, onAccountSelected, onAccountStateChange, selectedAccount = null, }) => {
    const [RelinkDialog, showRelinkDialog] = useRelinkDialog();
    const [OnlineAccountsDialog, showOnlineAccountsDialog] = useAccountsDialog();
    const [InstitutionInfoDialog, showInstitutionInfoDialog] = useInstitutionInfoDialog();
    const [OfflineAccountDialog, showOfflineAccountDialog] = useOfflineAccountDialog();
    const handleRelinkClick = () => {
        institution.relink();
    };
    const [editedAccount, setEditedAccount] = useState(null);
    const [DeleteConfirmation, handleDeleteClick] = useDeleteConfirmation('Delete Confirmation', 'Delete', (React.createElement(React.Fragment, null,
        React.createElement("div", null, "Are you sure you want to delete this institution?"),
        React.createElement("div", { style: { marginTop: '1rem' } }, "All accounts within the instution and their transactions will be deleted. This cannot be undone."))), () => {
        institution.delete();
    });
    let syncDate = null;
    if (institution.syncDate) {
        syncDate = `as of ${institution.syncDate.toFormat('LL-dd-y T')}`;
    }
    // const handleAddClick = () => {
    //   if (institution.offline) {
    //     showOfflineAccountDialog();
    //   }
    //   else {
    //     showOnlineAccountsDialog();
    //   }
    // }
    const handleEditAccount = (account) => {
        if (institution.offline) {
            setEditedAccount(account);
            showOfflineAccountDialog();
        }
        else {
            setEditedAccount(account);
            showOnlineAccountsDialog();
        }
    };
    const handleDialogHide = () => {
        setEditedAccount(null);
    };
    const refresh = async () => {
        const result = await institution.sync(institution.id);
        if (!result) {
            showRelinkDialog();
        }
    };
    return (React.createElement("div", { className: styles.institutionCard },
        React.createElement("div", { className: styles.institution },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', overflow: 'hidden' } },
                React.createElement("div", { className: styles.institutionName }, institution.name),
                React.createElement("div", { style: { marginLeft: '1rem' } }, syncDate),
                React.createElement("div", { style: { display: 'flex', alignSelf: 'flex-end' } },
                    React.createElement(IconButton, { icon: "sync-alt", className: styles.iconButton, rotate: institution.refreshing, onClick: refresh }),
                    React.createElement(RelinkDialog, { institution: institution }),
                    React.createElement(IconButton, { icon: "trash-alt", className: styles.iconButton, onClick: handleDeleteClick }),
                    React.createElement(OnlineAccountsDialog, { account: editedAccount }),
                    React.createElement(OfflineAccountDialog, { institution: institution, account: editedAccount, onHide: handleDialogHide }),
                    React.createElement(DeleteConfirmation, null),
                    !institution.offline
                        ? (React.createElement(React.Fragment, null,
                            React.createElement(IconButton, { icon: "link", className: styles.iconButton, onClick: handleRelinkClick }),
                            React.createElement(IconButton, { icon: "info-circle", className: styles.iconButton, onClick: showInstitutionInfoDialog }),
                            React.createElement(InstitutionInfoDialog, { institution: institution })))
                        : null))),
        React.createElement("div", null, institution.accounts
            .filter((account) => account.closed !== opened)
            .map((account, index) => {
            const selected = selectedAccount
                ? selectedAccount.id === account.id
                : false;
            return (React.createElement("div", { key: account.id },
                index !== 0
                    ? React.createElement("div", { className: styles.separator })
                    : null,
                React.createElement(Account, { account: account, onAccountSelected: onAccountSelected, onAccountStateChange: onAccountStateChange, selected: selected, showAccountDialog: handleEditAccount })));
        }))));
});
export default Institution;
