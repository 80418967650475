/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { Field, } from 'formik';
import { makeUseModal } from '@mortvola/usemodal';
import { FormError, FormField, FormModal, setFormErrors, } from '@mortvola/forms';
import CategorySplits from '../CategorySplits';
import Amount from '../Amount';
import AmountInput from '../AmountInput';
import useMediaQuery from '../MediaQuery';
import { RequestErrorCode, TransactionType } from '../../common/ResponseTypes';
import styles from './TransactionDialog.module.scss';
import PurchaseLocation from './PurchaseLocation';
function validateSplits(splits) {
    let error;
    if (splits !== undefined) {
        if (splits.some((split) => split.categoryId === null || split.categoryId === undefined)) {
            error = 'There are one or more items not assigned a category.';
        }
    }
    return error;
}
const TransactionDialog = ({ setShow, transaction = null, account = null, onReload, }) => {
    const { isMobile } = useMediaQuery();
    const computeRemaining = (categories, total) => {
        let sum = 0;
        if (categories && categories.length > 0) {
            sum = categories.reduce((accum, item) => (accum + item.amount), 0);
            return Math.abs(total) - sum;
        }
        // If there are no categories, assume the transaction total is assigned to a single
        // unassigned item.
        return 0;
    };
    const handleValidate = (values) => {
        const errors = {};
        if (values.splits.length > 0) {
            const sum = values.splits.reduce((accum, item) => (accum + Math.round(item.amount * 100)), 0);
            if (sum !== Math.abs(Math.round(values.amount * 100))) {
                errors.splits = 'The sum of the categories does not match the transaction amount.';
            }
        }
        return errors;
    };
    const handleSubmit = async (values) => {
        const amount = typeof values.amount === 'string' ? parseFloat(values.amount) : values.amount;
        const principle = typeof values.principle === 'string' ? parseFloat(values.principle) : values.principle;
        // If the transaction amount is less then zero then
        // negate all of the category amounts.
        if (amount < 0) {
            values.splits.forEach((element) => {
                element.amount *= -1;
            });
        }
        let errors;
        if (transaction) {
            errors = await transaction.updateTransaction({
                name: values.name,
                date: values.date,
                amount,
                principle,
                comment: values.comment,
                categories: values.splits,
            });
        }
        else {
            if (!account) {
                throw new Error('account is null');
            }
            errors = await account.addTransaction({
                name: values.name,
                date: values.date,
                amount,
                principle,
                comment: values.comment,
                categories: values.splits,
            });
        }
        if (errors) {
            const error = errors.find((e) => e.code === RequestErrorCode.INCORRECT_VERSION);
            if (error) {
                setShow(false);
                if (onReload) {
                    onReload();
                }
            }
        }
        else {
            setShow(false);
        }
    };
    const handleDelete = async (bag) => {
        const { setTouched, setErrors } = bag;
        if (transaction) {
            const errors = await transaction.delete();
            if (errors && errors.length > 0) {
                setTouched({ [errors[0].field]: true }, false);
                setFormErrors(setErrors, errors);
            }
            else {
                setShow(false);
            }
        }
    };
    const splits = useMemo(() => {
        if (transaction) {
            if (transaction.categories.length > 0) {
                return transaction.categories.map((c) => ({
                    ...c,
                    amount: transaction.amount < 0 ? -c.amount : c.amount,
                }));
            }
            return [];
        }
        return [];
    }, [transaction]);
    const [remaining, setRemaining] = useState(() => {
        if (transaction) {
            return computeRemaining(splits, transaction.amount);
        }
        return 0;
    });
    const splitItemClass = 'transaction-split-item no-balances';
    let paymentChannel = 'unknown';
    if (transaction && transaction.paymentChannel) {
        paymentChannel = transaction.paymentChannel;
    }
    const isReadOnly = () => (transaction !== null && transaction.type === TransactionType.REGULAR_TRANSACTION);
    const renderSplits = () => (React.createElement("div", { className: "cat-fund-table" },
        React.createElement("div", { className: `${splitItemClass} cat-fund-title` },
            React.createElement("div", { className: "item-title" }, "Category"),
            React.createElement("div", { className: "item-title-amount" }, "Amount"),
            !isMobile
                ? React.createElement("div", { className: "item-title" }, "Comment")
                : null),
        React.createElement(Field, { name: "splits", validate: validateSplits }, ({ field: { value, name, }, form: { setFieldValue, values, }, }) => {
            const amount = typeof (values.amount) === 'string' ? parseFloat(values.amount) : values.amount;
            return (React.createElement(CategorySplits, { splits: value, total: Math.abs(amount), onChange: (s) => {
                    setFieldValue(name, s);
                    setRemaining(computeRemaining(s, amount));
                } }));
        }),
        React.createElement("div", { className: splitItemClass },
            React.createElement("div", { className: "unassigned-label" }, "Remaining:"),
            React.createElement(Amount, { amount: remaining, style: { margin: '1px', padding: '1px' } })),
        React.createElement(FormError, { name: "splits" })));
    return (React.createElement(FormModal, { initialValues: {
            date: transaction ? (transaction.date.toISODate() ?? '') : '',
            name: transaction ? transaction.name : '',
            amount: transaction ? transaction.amount : 0,
            principle: transaction ? (transaction.principle ?? 0) : 0,
            interest: transaction ? (transaction.amount - (transaction.principle ?? 0)) : 0,
            comment: transaction && transaction.comment ? transaction.comment : '',
            splits,
        }, setShow: setShow, title: transaction ? 'Edit Transaction' : 'Add Transaction', formId: "transactionDialogForm", validate: handleValidate, onSubmit: handleSubmit, onDelete: transaction && transaction.type !== TransactionType.STARTING_BALANCE ? handleDelete : null },
        React.createElement("div", { className: styles.main },
            React.createElement(FormField, { name: "date", type: "date", label: "Date:", readOnly: isReadOnly() }),
            transaction !== null
                ? (React.createElement("div", null,
                    React.createElement("div", null, `Payment Channel: ${paymentChannel}`),
                    transaction.location
                        ? React.createElement(PurchaseLocation, { location: transaction.location })
                        : null))
                : null),
        React.createElement(FormField, { name: "name", label: "Name:", readOnly: isReadOnly(), style: { width: '100%' } }),
        React.createElement("div", { style: {
                display: 'grid',
                gridTemplateColumns: account && account.type === 'loan'
                    ? 'minmax(0, 8rem) minmax(0, 8rem) minmax(0, 8rem) minmax(0, 1fr)'
                    : 'minmax(0, 8rem) minmax(0, 1fr)',
                columnGap: '1rem',
            } },
            React.createElement(FormField, { name: "amount", label: "Amount:", readOnly: isReadOnly() }, ({ field, form: { values } }) => (React.createElement(AmountInput, { className: "form-control", readOnly: isReadOnly(), ...field, onBlur: (v) => {
                    setRemaining(computeRemaining(values.splits, parseFloat(v.target.value)));
                } }))),
            account && account.type === 'loan'
                ? (React.createElement(React.Fragment, null,
                    React.createElement(FormField, { name: "principle", label: "Principle:", as: AmountInput }),
                    React.createElement(FormField, { name: "interest", label: "Interest:", as: AmountInput, 
                        // onBlur={() => console.log('interest blur')}
                        readOnly: true })))
                : null,
            React.createElement(FormField, { name: "comment", label: "Memo:" })),
        account === null || account.tracking === 'Transactions'
            ? renderSplits()
            : null));
};
export const useTransactionDialog = makeUseModal(TransactionDialog, { size: 'lg' });
export default TransactionDialog;
