import React from 'react';
import styles from './UploadFileButton.module.scss';
const UploadFileButton = ({ onFileSelection, multiple, accept, label, disabled = false, }) => {
    const inputRef = React.useRef(null);
    const handleClick = () => {
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.value = '';
            inputElement.click();
        }
    };
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("button", { type: "button", onClick: handleClick, disabled: disabled }, label),
        React.createElement("input", { ref: inputRef, type: "file", className: styles.input, accept: accept, multiple: multiple, onChange: onFileSelection })));
};
export default UploadFileButton;
