import React from 'react';
import styles from './Icon.module.scss';
const Icon = ({ icon, iconClass, rotate = false, style, solid = true, }) => {
    let className = '';
    if (iconClass) {
        className += iconClass;
    }
    else {
        className += `${solid ? 'fa-solid' : 'fa-regular'}`;
    }
    className += ` fa-${icon}`;
    if (rotate) {
        className += ` ${styles.rotate}`;
    }
    return (React.createElement("i", { className: className, style: style }));
};
export default Icon;
