import React from 'react';
import useMediaQuery from './MediaQuery';
import styles from './Toolbar.module.scss';
const Toolbar = ({ children, onToggleClick, }) => {
    const { isMobile } = useMediaQuery();
    return (React.createElement("div", { className: styles.toolbar },
        isMobile
            ? (React.createElement("div", { className: "navbar-light" },
                React.createElement("button", { type: "button", className: "navbar-toggler", onClick: onToggleClick },
                    React.createElement("span", { className: "navbar-toggler-icon" }))))
            : null,
        children));
};
export default Toolbar;
