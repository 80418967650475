import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
const usePageViews = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        ReactGA.initialize('UA-170656741-1');
        setInitialized(true);
    }, []);
    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};
export default usePageViews;
