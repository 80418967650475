import React from 'react';
const TabViewMenuItem = ({ onClick, children, }) => {
    const handleClick = (event) => {
        // event.stopPropagation();
        if (onClick) {
            onClick();
        }
    };
    return (React.createElement("div", { onClick: handleClick }, children));
};
export default TabViewMenuItem;
