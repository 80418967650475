import React from 'react';
import DateTime from '../DateTime';
import styles from './PlaidLog.module.scss';
import TransactionsSync from './TransactionsSync';
const PlaidLog = ({ log, onClick, selected, }) => {
    const handleClick = () => {
        onClick(log);
    };
    const renderDetail = () => (((log.status >= 200 && log.status < 300) || log.status === null)
        && (log.request === 'syncTransactions' || log.request === '/transactions/sync')
        ? React.createElement(TransactionsSync, { response: log.response })
        : (React.createElement("div", { className: styles.responseWrapper },
            React.createElement("div", { className: styles.response }, JSON.stringify(log.response, null, 4)))));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.layout, onClick: handleClick },
            React.createElement(DateTime, { dateTime: log.createdAt }),
            React.createElement("div", { className: styles.layout2 },
                React.createElement("div", { className: styles.message }, log.request),
                React.createElement("div", null, log.institutionId),
                React.createElement("div", null, log.status))),
        selected
            ? renderDetail()
            : null));
};
export default PlaidLog;
