import React from 'react';
import { Formik, FieldArray } from 'formik';
import { DateTime } from 'luxon';
import { DropdownButton } from 'react-bootstrap';
import { FormCheckbox, FormField, SubmitButton } from '@mortvola/forms';
import Http from '@mortvola/http';
import Amount from '../Amount';
import { useStores } from '../State/Store';
import useSortableTable from './SortableTable';
import ReportControls from './ReportControls';
import styles from './Payee.module.scss';
const Payee = () => {
    const { accounts } = useStores();
    const { setData, SortableTable } = useSortableTable(['name', 'paymentChannel', 'sum', 'count']);
    const handleSubmit = async (values) => {
        let qp = `startDate=${values.startDate}&endDate=${values.endDate}`;
        if (values.inStoreFilter) {
            qp += '&pc=instore';
        }
        if (values.onlineFilter) {
            qp += '&pc=online';
        }
        if (values.otherFilter) {
            qp += '&pc=other';
        }
        if (values.unknownFilter) {
            qp += '&pc=unknown';
        }
        values.account.forEach((a) => {
            if (a.value) {
                qp += `&a=${a.id}`;
            }
        });
        const response = await Http.get(`/api/v1/reports/payee?${qp}`);
        if (response.ok) {
            const body = await response.body();
            const isPayeeReport = (r) => (Array.isArray(r)
                && (r.length === 0 || (r[0].name !== undefined)));
            if (isPayeeReport(body)) {
                setData(body);
            }
        }
    };
    return (React.createElement("div", { className: "payee-report window window1" },
        React.createElement(Formik, { initialValues: {
                startDate: DateTime.now().minus({ years: 1 }).toISODate() ?? '',
                endDate: DateTime.now().toISODate() ?? '',
                inStoreFilter: true,
                onlineFilter: true,
                otherFilter: true,
                unknownFilter: true,
                account: (accounts.institutions.flatMap((i) => (i.accounts.filter((a) => (a.tracking === 'Transactions')))
                    .map((a) => ({
                    value: true,
                    id: a.id,
                })))),
            }, onSubmit: handleSubmit }, ({ isSubmitting }) => (React.createElement(ReportControls, null,
            React.createElement(FormField, { name: "startDate", type: "date", label: "Start Date:" }),
            React.createElement(FormField, { name: "endDate", type: "date", label: "End Date:" }),
            React.createElement(DropdownButton, { id: "test", title: "Payment Channels" },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column', paddingLeft: '0.5rem' } },
                    React.createElement(FormCheckbox, { name: "inStoreFilter", label: "In Store" }),
                    React.createElement(FormCheckbox, { name: "onlineFilter", label: "Online" }),
                    React.createElement(FormCheckbox, { name: "otherFilter", label: "Other" }),
                    React.createElement(FormCheckbox, { name: "unknownFilter", label: "Unknown" }))),
            React.createElement(DropdownButton, { id: "test", title: "Accounts" },
                React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: '0.5rem',
                        overflowY: 'auto',
                        maxHeight: '300px',
                    } },
                    React.createElement(FieldArray, { name: "account" }, () => {
                        let index = -1;
                        return (accounts.institutions.flatMap((i) => (i.accounts.filter((a) => (a.tracking === 'Transactions')))
                            .map((a) => {
                            index += 1;
                            return (React.createElement(FormCheckbox, { key: a.id, name: `account[${index}].value`, label: `${i.name}:${a.name}` }));
                        })));
                    }))),
            React.createElement(SubmitButton, { isSubmitting: isSubmitting, label: "Run Report", submitLabel: "Running Report" })))),
        React.createElement(SortableTable, null,
            React.createElement(SortableTable.Header, { className: `title ${styles.reportItem}` },
                React.createElement(SortableTable.Column, { column: "name" }, "Name"),
                React.createElement(SortableTable.Column, { column: "paymentChannel" }, "Payment Channel"),
                React.createElement(SortableTable.Column, { className: "dollar-amount", column: "sum" }, "Amount"),
                React.createElement(SortableTable.Column, { style: { textAlign: 'right' }, column: "count" }, "Count"),
                React.createElement("div", { style: { overflowY: 'scroll', visibility: 'hidden', padding: 0 } })),
            React.createElement(SortableTable.Body, null, (d) => (React.createElement("div", { key: d.rowNumber, className: styles.reportItem },
                React.createElement("div", { className: "ellipsis" }, d.name),
                React.createElement("div", null, d.paymentChannel),
                React.createElement(Amount, { amount: d.sum }),
                React.createElement("div", { style: { textAlign: 'right' } }, d.count)))))));
};
export default Payee;
