import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as responsive from 'react-responsive';
import 'regenerator-runtime';
import styles from './Intro.module.scss';
const Intro = () => {
    const navigate = useNavigate();
    const tiny = responsive.useMediaQuery({ query: '(max-width: 350px)' });
    const small = responsive.useMediaQuery({ query: '(max-width: 600px)' });
    const medium = responsive.useMediaQuery({ query: '(max-width: 1224px)' });
    const handleSignupClick = () => {
        navigate('/signup');
    };
    const handleSigninClick = () => {
        navigate('/signin');
    };
    const addSizeClass = (className) => {
        if (tiny) {
            return `${styles.tiny} ${className}`;
        }
        if (small) {
            return `${styles.small} ${className}`;
        }
        if (medium) {
            return `${styles.medium} ${className}`;
        }
        return className;
    };
    return (React.createElement("div", { className: addSizeClass(`${styles.frame} position-ref full-height`) },
        React.createElement("div", { className: styles.buttonTray },
            React.createElement("div", { className: styles.linkButton, onClick: handleSignupClick }, "Sign up"),
            React.createElement("div", { className: styles.linkButton, onClick: handleSigninClick }, "Sign in")),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles.introText },
                React.createElement("div", { className: styles.title }, "SpendCraft"),
                React.createElement("div", { className: styles.catchPhrase }, "We all spend money."),
                React.createElement("div", { className: styles.catchPhrase }, "Be smart about how you spend it."),
                React.createElement("div", { className: styles.subCatchPhrase }, "With an easy to use envelope style budgeting system, take control of your expenditures and spend your money on the things that are important to you."),
                React.createElement("div", { className: `${styles.linkButton} ${styles.signupFreeButton}`, onClick: handleSignupClick }, "Sign up for free")),
            React.createElement("img", { src: "/budgeting.svg", alt: "", className: styles.image }))));
};
export default Intro;
