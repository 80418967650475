/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, } from 'react';
import { Field, } from 'formik';
import { toJS } from 'mobx';
import { DateTime } from 'luxon';
import { makeUseModal } from '@mortvola/usemodal';
import Http from '@mortvola/http';
import { FormModal, FormError, setFormErrors } from '@mortvola/forms';
import CategoryRebalance from './CategoryRebalance';
import Amount from '../Amount';
import { useStores } from '../State/Store';
import { isCategoryTreeBalanceResponse, TransactionType } from '../../common/ResponseTypes';
const RebalanceDialog = ({ transaction, setShow, }) => {
    const { register } = useStores();
    const [balances, setBalances] = useState([]);
    const [unassigned, setUnassigned] = useState(0);
    const [date, setDate] = useState(transaction ? transaction.date.toISODate() : DateTime.now().toISODate());
    const fetchCategoryBalances = useCallback((fetchDate) => {
        if (fetchDate !== '') {
            const params = { date: fetchDate };
            if (transaction && transaction.id !== null) {
                params.id = transaction.id.toString();
            }
            const url = new URL('/api/v1/category-balances', window.location.href);
            url.search = (new URLSearchParams(params)).toString();
            (async () => {
                const response = await Http.get(url.toString());
                if (response.ok) {
                    const body = await response.body();
                    if (isCategoryTreeBalanceResponse(body)) {
                        setBalances(body);
                    }
                }
            })();
        }
    }, [transaction]);
    useEffect(() => {
        fetchCategoryBalances(date ?? '');
    }, [date, fetchCategoryBalances]);
    const handleDateChange = (event) => {
        setDate(event.target.value);
    };
    const handleDeltaChange = (delta) => {
        setUnassigned(unassigned - delta);
    };
    const handleValidate = (values) => {
        const errors = {};
        if (values.date === '') {
            errors.date = 'A date must be specified.';
        }
        if (values.categories.length === 0) {
            errors.categories = 'There must be at least one adjustment.';
        }
        else {
            const sum = values.categories.reduce((accumulator, currentValue) => (accumulator + Math.round(currentValue.amount * 100)), 0);
            if (sum !== 0) {
                errors.categories = 'The sum of the adjustments must be zero.';
            }
        }
        return errors;
    };
    const handleSubmit = async (values) => {
        // const { setErrors } = bag;
        if (transaction) {
            const errors = await transaction.updateCategoryTransfer(values);
            if (!errors) {
                setShow(false);
            }
        }
        else {
            const errors = await register.addCategoryTransfer(values, TransactionType.REBALANCE_TRANSACTION);
            if (!errors) {
                setShow(false);
            }
        }
    };
    const handleDelete = async (bag) => {
        const { setTouched, setErrors } = bag;
        if (transaction) {
            const errors = await transaction.delete();
            if (errors && errors.length > 0) {
                setTouched({ [errors[0].field]: true }, false);
                setFormErrors(setErrors, errors);
            }
        }
    };
    return (React.createElement(FormModal, { setShow: setShow, initialValues: {
            categories: transaction
                ? toJS(transaction.categories)
                : [],
            date: date ?? '',
        }, title: "Rebalance Categories", formId: "rebalanceForm", validate: handleValidate, onSubmit: handleSubmit, onDelete: transaction ? handleDelete : null },
        React.createElement("div", { className: "rebalance-container" },
            React.createElement("div", { className: "rebalance-header" },
                React.createElement("label", null,
                    "Date:",
                    React.createElement(Field, { name: "date" }, ({ field: { name, value, }, form: { setFieldValue, }, }) => (React.createElement("input", { className: "form-control", value: value, type: "date", onChange: (event) => {
                            handleDateChange(event);
                            setFieldValue(name, event.target.value, false);
                        } }))),
                    React.createElement(FormError, { name: "date" })),
                React.createElement("label", null,
                    "Unassigned:",
                    React.createElement(Amount, { className: "form-control", amount: unassigned }))),
            React.createElement(FormError, { name: "categories" }),
            React.createElement(Field, { name: "categories" }, ({ field: { name, value, }, form: { setFieldValue, }, }) => (React.createElement(CategoryRebalance, { trxCategories: value, balances: balances, onDeltaChange: (_amount, delta, categories) => {
                    handleDeltaChange(delta);
                    setFieldValue(name, categories, false);
                } }))))));
};
export const useRebalanceDialog = makeUseModal(RebalanceDialog, { size: 'lg' });
export default RebalanceDialog;
