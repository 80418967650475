import React from 'react';
import AmountInput from '../AmountInput';
import Amount from '../Amount';
const StatementAmount = ({ amount, onUpdate, }) => {
    const [editAmount, setEditAmount] = React.useState(false);
    const [amountValue, setAmountValue] = React.useState('');
    const handleEditAmountClick = () => {
        setEditAmount(true);
        setAmountValue(amount.toString());
    };
    const handleAmountChange = (event) => {
        setAmountValue(event.target.value);
    };
    const handleAmountKeyDown = (event) => {
        if (event.code === 'Enter') {
            setEditAmount(false);
            const value = parseFloat(amountValue);
            if (value !== amount) {
                onUpdate(parseFloat(amountValue));
            }
        }
        else if (event.code === 'Escape') {
            setEditAmount(false);
        }
    };
    return (editAmount
        ? (React.createElement(AmountInput, { value: amountValue, onChange: handleAmountChange, onKeyDown: handleAmountKeyDown }))
        : (React.createElement(Amount, { amount: amount, onClick: handleEditAmountClick })));
};
export default StatementAmount;
