import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './RemoteDataManager.module.scss';
import PleaseWait from './PleaseWait';
const RemoteDataManager = observer(({ data, children, className, }) => {
    const ref = React.useRef(null);
    // const [refreshing, setRefreshing] = React.useState<boolean>(false);
    // const [fetching, setFetching] = React.useState<boolean>(false);
    // const [loadingMore, setLoadingMore] = React.useState<boolean>(false);
    const isDataNeeded = (element) => {
        if (element !== null) {
            const { scrollTop, scrollHeight, clientHeight } = element;
            // When we are a page away from the bottom then get more data.
            return (scrollTop > scrollHeight - 2 * clientHeight);
        }
        return false;
    };
    const handleScroll = () => {
        const element = ref.current;
        if (element) {
            if (data.state() === 'IDLE' && !data.isComplete() && isDataNeeded(element)) {
                (async () => {
                    await data.getMoreData();
                })();
            }
            if (element) {
                // Handle pull to refresh
                if (element.scrollTop < -100 && data.state() === 'IDLE') {
                    (async () => {
                        await data.getData(0);
                        // if (element.scrollTop >= 0) {
                        //   setRefreshing(false);
                        // }
                    })();
                }
                // else if (refreshing && !fetching && element.scrollTop >= 0) {
                //   setRefreshing(false);
                // }
            }
        }
    };
    return (React.createElement("div", { ref: ref, className: styles.pullRefresherWrapper, onScroll: handleScroll },
        React.createElement("div", { className: `${styles.pullRefresher} ${className ?? ''}` },
            data.state() === 'LOADING'
                ? (React.createElement("div", { className: styles.refresh },
                    React.createElement(PleaseWait, null)))
                : null,
            children,
            data.state() === 'LOADING-MORE'
                ? (React.createElement("div", { className: styles.loadMore },
                    React.createElement(PleaseWait, null)))
                : null)));
});
export default RemoteDataManager;
