import React from 'react';
import { observer } from 'mobx-react-lite';
import Amount from '../Amount';
import styles from './Transactions.module.scss';
import TransactionAccount from './TransactionAccount';
import { TransactionType } from '../../common/ResponseTypes';
import AccountOwner from './AccountOwner';
import Reconcile from './Reconcile';
import Date from '../Date';
import Icon from '../Icon';
const Transaction = observer(({ transaction, className, amount, runningBalance, account, onClick, }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(transaction);
        }
    };
    const transactionAmount = () => {
        if ([
            TransactionType.FUNDING_TRANSACTION,
            TransactionType.REBALANCE_TRANSACTION,
        ].includes(transaction.type)
            || transaction.amount === amount) {
            return React.createElement("div", { className: styles.trxAmount });
        }
        return React.createElement(Amount, { className: styles.trxAmount, amount: transaction.amount });
    };
    const loanFields = () => (account?.type === 'loan'
        ? (React.createElement("div", { className: `${className ?? ''} ${styles.transaction}`, onClick: handleClick },
            React.createElement(Amount, { className: "currency", amount: transaction.amount }),
            transaction.type !== TransactionType.STARTING_BALANCE
                ? (React.createElement(React.Fragment, null,
                    React.createElement(Amount, { className: "currency", amount: transaction.amount - amount }),
                    React.createElement(Amount, { className: "currency", amount: amount })))
                : (React.createElement(React.Fragment, null,
                    React.createElement("div", null),
                    React.createElement("div", null)))))
        : null);
    return (React.createElement("div", { className: `${className ?? ''} ${styles.transaction} ${transaction.pending ? styles.pendingTrx : ''}`, onClick: handleClick },
        transaction.duplicateOfTransactionId
            ? React.createElement(Icon, { icon: "arrow-right-arrow-left", iconClass: "fa-solid" })
            : React.createElement("div", null),
        React.createElement(Date, { className: styles.date, date: transaction.date }),
        React.createElement("div", { className: styles.name }, transaction.name),
        React.createElement(Amount, { className: styles.amount, amount: amount }),
        React.createElement(Amount, { className: styles.runningBalance, amount: runningBalance }),
        React.createElement(TransactionAccount, { transaction: transaction }),
        transactionAmount(),
        loanFields(),
        React.createElement(Reconcile, { transaction: transaction }),
        React.createElement("div", { className: styles.pendingFlag }, "Pending"),
        React.createElement(AccountOwner, { owner: transaction.accountOwner })));
});
export default Transaction;
