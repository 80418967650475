import React from 'react';
import { observer } from 'mobx-react-lite';
import Amount from '../../Amount';
import EditButton from './EditButton';
const Category = observer(({ category, group, selected, onCategorySelected, }) => {
    const handleClick = () => {
        onCategorySelected(category);
    };
    let className = 'cat-list-cat';
    if (selected) {
        className += ' selected';
    }
    let barClassName = 'cat-element-bar';
    if (category.type !== 'LOAN' && category.type !== 'REGULAR') {
        barClassName += ' system';
    }
    return (React.createElement("div", { className: className, onClick: handleClick },
        React.createElement("div", { className: barClassName },
            ['REGULAR', 'LOAN', 'BILL', 'GOAL'].includes(category.type)
                ? React.createElement(EditButton, { category: category })
                : null,
            React.createElement("div", { className: "cat-list-name" }, category.name)),
        React.createElement(Amount, { className: "cat-list-amt", amount: category.balance })));
});
export default Category;
