import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Statements.module.scss';
import trxStyles from '../Transactions/Transactions.module.scss';
import Register from '../Transactions/Register';
import { useStatementDialog } from './StatementDialog';
import { useStores } from '../State/Store';
import StatementView from './StatementView';
const Statements = observer(({ account, }) => {
    const { uiState, } = useStores();
    const { selectedStatement } = uiState;
    const [StatementDialog, showStatementDialog] = useStatementDialog();
    React.useEffect(() => {
        account.getStatements();
    }, [account]);
    const handleStatementClick = (statement) => {
        uiState.selectStatement(statement);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.layout },
            React.createElement("div", { className: `${styles.statements} window window1` },
                "Statements",
                React.createElement("button", { type: "button", onClick: showStatementDialog }, "Add"),
                React.createElement("div", { className: styles.items }, account.statements.map((statement) => (React.createElement("div", { className: statement === selectedStatement ? styles.selected : '', key: statement.id, onClick: () => {
                        handleStatementClick(statement);
                    } }, statement.endDate.toISODate()))))),
            React.createElement(Register, { className: `${trxStyles.statement} ${styles.transactions}`, type: "account" }),
            React.createElement("div", { className: `${styles.statement} window window1` }, selectedStatement
                ? React.createElement(StatementView, { statement: selectedStatement })
                : null)),
        React.createElement(StatementDialog, { account: account })));
});
export default Statements;
