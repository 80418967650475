import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { useStores } from '../State/Store';
import DesktopView from '../DesktopView';
import MobileView from '../MobileView';
import styles from './OverviewView.module.scss';
import ViewTitle from '../ViewTitle';
const OverviewView = observer(() => {
    const { overview } = useStores();
    React.useEffect(() => {
        overview.load();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(DesktopView, null,
            React.createElement("div", null,
                React.createElement(Outlet, null))),
        React.createElement(MobileView, null,
            React.createElement("div", { className: styles.layout },
                React.createElement(ViewTitle, null, "Bills"),
                React.createElement(Outlet, null)))));
});
export default OverviewView;
