import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../State/Store';
import PlaidLog from './PlaidLog';
import styles from './PlaidLogs.module.scss';
const PlaidLogs = observer(() => {
    const { plaidLogs } = useStores();
    const [selectedLog, setSelectedLog] = React.useState(null);
    const handleClick = (log) => {
        setSelectedLog((prev) => (prev === log ? null : log));
    };
    return (React.createElement("div", { className: styles.layout },
        React.createElement("div", { className: styles.logs }, plaidLogs.logs.map((log) => (React.createElement(PlaidLog, { key: log.id, log: log, onClick: handleClick, selected: log === selectedLog }))))));
});
export default PlaidLogs;
