import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from './State/Store';
import PlaidLinkDialog from './PlaidLinkDialog';
const PlaidLink = observer(() => {
    const { uiState } = useStores();
    if (uiState.plaid) {
        return React.createElement(PlaidLinkDialog, { institution: uiState.plaid.institution });
    }
    return null;
});
export default PlaidLink;
