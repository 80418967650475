import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { useStores } from '../State/Store';
import DesktopView from '../DesktopView';
import AutoAssigmentsToolbar from './AutoAssignmentsToolbar';
import MobileView from '../MobileView';
import ViewTitle from '../ViewTitle';
const AutoAssigments = observer(() => {
    const { autoAssignments } = useStores();
    React.useEffect(() => {
        autoAssignments.load();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(DesktopView, null,
            React.createElement("div", null,
                React.createElement(AutoAssigmentsToolbar, null),
                React.createElement(Outlet, null))),
        React.createElement(MobileView, null,
            React.createElement("div", null,
                React.createElement(AutoAssigmentsToolbar, null),
                React.createElement(ViewTitle, null, "Auto Assignments"),
                React.createElement(Outlet, null)))));
});
export default AutoAssigments;
