import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { makeUseModal } from '@mortvola/usemodal';
import Http from '@mortvola/http';
import { SubmitButton } from '@mortvola/forms';
import styles from './InstitutionInfoDialog.module.scss';
const Header = () => (React.createElement(Modal.Header, { closeButton: true },
    React.createElement("h4", { id: "modalTitle", className: "modal-title" }, "Institution Information")));
const Footer = ({ setShow, isSubmitting }) => (React.createElement(Modal.Footer, null,
    React.createElement("div", null),
    React.createElement("div", null),
    React.createElement(Button, { variant: "secondary", onClick: () => setShow(false) }, "Cancel"),
    React.createElement(SubmitButton, { isSubmitting: isSubmitting, label: "Save", submitLabel: "Saving" })));
const InstitutionInfoDialog = ({ institution, setShow, }) => {
    const [infoInitialized, setInfoInitialized] = useState(false);
    const [info, setInfo] = useState(null);
    if (!infoInitialized) {
        setInfoInitialized(true);
        (async () => {
            const response = await Http.get(`/api/v1/institution/${institution.id}/info`);
            if (response.ok) {
                const institutionInfo = await response.body();
                setInfo(institutionInfo);
            }
        })();
    }
    const product = (value) => {
        const words = value.replace('_', ' ').split(' ');
        return words.map((w) => (w[0].toUpperCase() + w.substring(1)))
            .join(' ');
    };
    const percent = (value) => `${(value * 100).toFixed(0)}%`;
    const renderStatus = () => {
        if (info && info.status) {
            return Object.entries(info.status)
                .filter(([, value]) => value !== null)
                .map(([key, value]) => (React.createElement("div", { className: styles.statusItem, key: key },
                React.createElement("div", null, product(key)),
                React.createElement("div", null, percent(value.breakdown.success)),
                React.createElement("div", null, percent(value.breakdown.error_plaid)),
                React.createElement("div", null, percent(value.breakdown.error_institution)),
                React.createElement("div", null, DateTime.fromISO(value.last_status_change).toRelative()))));
        }
        return null;
    };
    const renderForm = () => {
        if (info) {
            return (React.createElement("div", null,
                React.createElement("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                    } },
                    React.createElement("img", { src: `data:image/png;base64, ${info.logo}`, alt: "logo", width: "48", height: "48" }),
                    React.createElement("div", { style: { marginLeft: '1rem' } },
                        React.createElement("div", { style: { fontSize: 'x-large' } }, info.name),
                        info.url
                            ? React.createElement("a", { href: info.url, rel: "noopener noreferrer", target: "_blank" }, info.url)
                            : null)),
                React.createElement("div", null,
                    React.createElement("div", { className: `${styles.title} ${styles.statusItem}` },
                        React.createElement("div", null, "Product"),
                        React.createElement("div", null, "Status"),
                        React.createElement("div", null, "Success Rate"),
                        React.createElement("div", null, "Plaid Errors"),
                        React.createElement("div", null, "Institution Errors"),
                        React.createElement("div", null, "Last Status Change")),
                    renderStatus())));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Modal.Body, null, renderForm()),
        React.createElement(Footer, { setShow: setShow, isSubmitting: false })));
};
export const useInstitutionInfoDialog = makeUseModal(InstitutionInfoDialog, { size: 'lg' });
export default InstitutionInfoDialog;
