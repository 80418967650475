import React from 'react';
const Amount = ({ id, noValue = null, amount = null, className = '', style, }) => {
    let displayedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    if (displayedAmount === null) {
        if (noValue === null) {
            displayedAmount = 0;
        }
    }
    let updatedClassName = `${className} dollar-amount`;
    let amountString;
    if (displayedAmount === null) {
        amountString = noValue;
    }
    else {
        amountString = displayedAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (amountString === '-0.00') {
            amountString = '0.00';
        }
        else if (displayedAmount < 0) {
            updatedClassName += ' negative';
        }
    }
    return (React.createElement("div", { id: id, className: updatedClassName, style: style }, amountString));
};
export default Amount;
