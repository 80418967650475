var __esDecorate = (this && this.__esDecorate) || function (ctor, descriptorIn, decorators, contextIn, initializers, extraInitializers) {
    function accept(f) { if (f !== void 0 && typeof f !== "function") throw new TypeError("Function expected"); return f; }
    var kind = contextIn.kind, key = kind === "getter" ? "get" : kind === "setter" ? "set" : "value";
    var target = !descriptorIn && ctor ? contextIn["static"] ? ctor : ctor.prototype : null;
    var descriptor = descriptorIn || (target ? Object.getOwnPropertyDescriptor(target, contextIn.name) : {});
    var _, done = false;
    for (var i = decorators.length - 1; i >= 0; i--) {
        var context = {};
        for (var p in contextIn) context[p] = p === "access" ? {} : contextIn[p];
        for (var p in contextIn.access) context.access[p] = contextIn.access[p];
        context.addInitializer = function (f) { if (done) throw new TypeError("Cannot add initializers after decoration has completed"); extraInitializers.push(accept(f || null)); };
        var result = (0, decorators[i])(kind === "accessor" ? { get: descriptor.get, set: descriptor.set } : descriptor[key], context);
        if (kind === "accessor") {
            if (result === void 0) continue;
            if (result === null || typeof result !== "object") throw new TypeError("Object expected");
            if (_ = accept(result.get)) descriptor.get = _;
            if (_ = accept(result.set)) descriptor.set = _;
            if (_ = accept(result.init)) initializers.unshift(_);
        }
        else if (_ = accept(result)) {
            if (kind === "field") initializers.unshift(_);
            else descriptor[key] = _;
        }
    }
    if (target) Object.defineProperty(target, contextIn.name, descriptor);
    done = true;
};
var __runInitializers = (this && this.__runInitializers) || function (thisArg, initializers, value) {
    var useValue = arguments.length > 2;
    for (var i = 0; i < initializers.length; i++) {
        value = useValue ? initializers[i].call(thisArg, value) : initializers[i].call(thisArg);
    }
    return useValue ? value : void 0;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
import { makeObservable, observable, runInAction } from 'mobx';
import Http from '@mortvola/http';
import { DateTime } from 'luxon';
import { isAddTransactionResponse, } from '../../common/ResponseTypes';
import Transaction from './Transaction';
import TransactionContainer from './TransactionContainer';
import Statement from './Statement';
let Account = (() => {
    var _a, _Account_statements_accessor_storage;
    let _statements_decorators;
    let _statements_initializers = [];
    let _statements_extraInitializers = [];
    return _a = class Account {
            get statements() { return __classPrivateFieldGet(this, _Account_statements_accessor_storage, "f"); }
            set statements(value) { __classPrivateFieldSet(this, _Account_statements_accessor_storage, value, "f"); }
            constructor(store, institution, props) {
                this.officialName = null;
                this.balance = 0;
                _Account_statements_accessor_storage.set(this, __runInitializers(this, _statements_initializers, []));
                this.store = __runInitializers(this, _statements_extraInitializers);
                this.transactions = new TransactionContainer(store, `/api/v1/account/${props.id}/transactions`, this.updateBalance);
                this.pendingTransactions = new TransactionContainer(store, `/api/v1/account/${props.id}/transactions?pending=1`);
                this.id = props.id;
                this.plaidId = props.plaidId;
                this.name = props.name;
                this.closed = props.closed;
                this.type = props.type;
                this.subtype = props.subtype;
                this.tracking = props.tracking;
                this.balance = props.balance;
                this.plaidBalance = props.plaidBalance;
                this.startDate = props.startDate ? DateTime.fromISO(props.startDate) : null;
                this.rate = props.rate;
                this.institution = institution;
                makeObservable(this, {
                    name: observable,
                    plaidBalance: observable,
                    rate: observable,
                    institution: observable,
                    balance: observable,
                });
                this.store = store;
            }
            update(props) {
                this.id = props.id;
                this.plaidId = props.plaidId;
                this.name = props.name;
                this.closed = props.closed;
                this.type = props.type;
                this.subtype = props.subtype;
                this.tracking = props.tracking;
                this.balance = props.balance;
                this.plaidBalance = props.plaidBalance;
                this.startDate = props.startDate ? DateTime.fromISO(props.startDate) : null;
                this.rate = props.rate;
                this.transactions.url = `/api/v1/account/${props.id}/transactions`;
                this.pendingTransactions.url = `/api/v1/account/${props.id}/transactions?pending=1`;
            }
            updateBalance(balance) {
                this.balance = balance;
            }
            async setSettings(settings) {
                const response = await Http.patch(`/api/v1/account/${this.id}`, settings);
                if (response.ok) {
                    runInAction(() => {
                        this.closed = settings.closed ?? this.closed;
                        if (this.closed) {
                            this.institution.closeAccount(this);
                        }
                    });
                }
            }
            async addTransaction(values) {
                const response = await Http.post(`/api/v1/account/${this.id}/transactions`, values);
                if (response.ok) {
                    const body = await response.body();
                    if (isAddTransactionResponse(body)) {
                        runInAction(() => {
                            this.store.categoryTree.updateBalances(body.categories);
                            const transaction = new Transaction(this.store, body.transaction);
                            this.transactions.insertTransaction(transaction);
                            this.balance = body.acctBalances[0].balance;
                        });
                        return null;
                    }
                }
                throw new Error('Error response received');
            }
            // eslint-disable-next-line class-methods-use-this
            async addStatement(startDate, endDate, startingBalance, endingBalance) {
                const response = await Http.post(`/api/v1/account/${this.id}/statements`, {
                    startDate, endDate, startingBalance, endingBalance,
                });
                if (response.ok) {
                    const props = await response.body();
                    runInAction(() => {
                        const statement = new Statement(props);
                        this.statements = [
                            ...this.statements,
                            statement,
                        ].sort((a, b) => {
                            if (a.endDate.startOf('day') > b.endDate.startOf('day')) {
                                return -1;
                            }
                            if (a.endDate.startOf('day') > b.endDate.startOf('day')) {
                                return 1;
                            }
                            return 0;
                        });
                        this.store.uiState.selectStatement(statement);
                    });
                    return null;
                }
                throw new Error('Error response received');
            }
            async getStatements() {
                const response = await Http.get(`/api/v1/account/${this.id}/statements`);
                if (response.ok) {
                    const body = await response.body();
                    runInAction(() => {
                        this.statements = body.map((props) => new Statement(props))
                            .sort((a, b) => {
                            if (a.endDate.startOf('day') > b.endDate.startOf('day')) {
                                return -1;
                            }
                            if (a.endDate.startOf('day') > b.endDate.startOf('day')) {
                                return 1;
                            }
                            return 0;
                        });
                        const statement = this.statements.find((s) => s.id === this.store.uiState.selectedStatement?.id);
                        this.store.uiState.selectStatement(statement ?? null);
                    });
                }
                throw new Error('Error response received');
            }
            updateStatement(props) {
                const statement = this.statements.find((s) => s.id === props.id);
                if (statement) {
                    runInAction(() => {
                        statement.credits = props.credits;
                        statement.debits = props.debits;
                    });
                }
            }
            delete() {
                this.institution.deleteAccount(this);
            }
            async updateOfflineAccount(name) {
                const response = await Http.patch(`/api/v1/account/${this.id}`, {
                    name,
                });
                if (response.ok) {
                    runInAction(() => {
                        this.name = name;
                    });
                }
            }
        },
        _Account_statements_accessor_storage = new WeakMap(),
        (() => {
            const _metadata = typeof Symbol === "function" && Symbol.metadata ? Object.create(null) : void 0;
            _statements_decorators = [observable];
            __esDecorate(_a, null, _statements_decorators, { kind: "accessor", name: "statements", static: false, private: false, access: { has: obj => "statements" in obj, get: obj => obj.statements, set: (obj, value) => { obj.statements = value; } }, metadata: _metadata }, _statements_initializers, _statements_extraInitializers);
            if (_metadata) Object.defineProperty(_a, Symbol.metadata, { enumerable: true, configurable: true, writable: true, value: _metadata });
        })(),
        _a;
})();
export default Account;
