import { useFormikContext, } from 'formik';
import React from 'react';
import { makeUseModal } from '@mortvola/usemodal';
import { FormField, FormModal, FormTextField, setFormErrors, } from '@mortvola/forms';
import AmountInput from '../AmountInput';
import { useStores } from '../State/Store';
import { getSubtypes, getTypes } from '../State/AccountTypes';
const APRField = () => {
    const { values } = useFormikContext();
    if (values.type === 'loan') {
        return (React.createElement(FormField, { name: "rate", label: "Annual Percentage Rate (APR):", as: AmountInput }));
    }
    return null;
};
const OfflineAccountDialog = ({ institution, account = null, setShow, }) => {
    const { accounts } = useStores();
    const handleValidate = (values) => {
        const errors = {};
        if (!values.institute) {
            errors.institute = 'Institution name is required';
        }
        if (!account) {
            if (!values.account) {
                errors.account = 'Account name is required';
            }
            if (!values.startDate) {
                errors.startDate = 'Start date is required';
            }
        }
        return errors;
    };
    const handleSubmit = async (values, bag) => {
        const { setErrors } = bag;
        let errors = null;
        if (institution) {
            if (account) {
                await account.updateOfflineAccount(values.account);
            }
            else {
                errors = await institution.addOfflineAccount(values.account, parseFloat(values.balance), values.startDate, values.type, values.subtype, values.tracking, parseFloat(values.rate));
            }
        }
        else {
            errors = await accounts.addOfflineAccount(values.institute, values.account, parseFloat(values.balance), values.startDate, values.type, values.subtype, values.tracking, parseFloat(values.rate));
        }
        if (errors) {
            setFormErrors(setErrors, errors);
        }
        else {
            setShow(false);
        }
    };
    const handleDelete = () => {
        if (account) {
            account.delete();
            setShow(false);
        }
    };
    const subtypeList = ({ field, form }) => (React.createElement("select", { name: field.name, value: field.value, className: "form-control", onChange: field.onChange, onBlur: field.onBlur }, (() => (getSubtypes(form.values.type).map((subtype) => (React.createElement("option", { key: subtype.key, value: subtype.key }, subtype.name)))))()));
    const typelist = ({ field, form }) => (React.createElement("select", { name: field.name, value: field.value, className: "form-control", onChange: (e) => {
            const subTypes = getSubtypes(e.target.value);
            if (subTypes.length > 0) {
                form.setFieldValue('subtype', subTypes[0].key, false);
            }
            field.onChange(e);
        }, onBlur: field.onBlur }, getTypes().map((t) => (React.createElement("option", { key: t.key, value: t.key }, t.name)))));
    return (React.createElement(FormModal, { initialValues: {
            institute: institution ? institution.name : '',
            account: account ? account.name : '',
            balance: account ? account.balance.toString() : '0',
            startDate: '',
            type: 'depository',
            subtype: 'checking',
            tracking: 'Transactions',
            rate: '0',
        }, setShow: setShow, validate: handleValidate, onSubmit: handleSubmit, title: account ? 'Edit Offline Account' : 'Add Offline Account', formId: "UnlinkedAccounts", onDelete: account ? handleDelete : null },
        React.createElement("div", { style: {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(45%, 1fr))',
                gridGap: '0.5rem',
            } },
            React.createElement(FormTextField, { name: "institute", label: "Institution Name:", readOnly: institution !== undefined }),
            React.createElement(FormTextField, { name: "account", label: "Account Name:" }),
            !account
                ? (React.createElement(React.Fragment, null,
                    React.createElement(FormField, { name: "balance", label: "Starting Balance:", as: AmountInput }),
                    React.createElement(FormField, { name: "startDate", label: "Start Date:", type: "date" }),
                    React.createElement(FormField, { name: "type", label: "Account Type:" }, typelist),
                    React.createElement(FormField, { name: "subtype", label: "Account Subtype:" }, subtypeList),
                    React.createElement(APRField, null),
                    React.createElement(FormField, { name: "tracking", label: "Tracking:", as: "select" },
                        React.createElement("option", { value: "Transactions" }, "Categorized Transactions"),
                        React.createElement("option", { value: "Uncategorized Transactions" }, "Uncategorized Transactions"),
                        React.createElement("option", { value: "Balances" }, "Balances"))))
                : null)));
};
export const useOfflineAccountDialog = makeUseModal(OfflineAccountDialog);
export default OfflineAccountDialog;
