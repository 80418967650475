import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tab, Tabs } from 'react-bootstrap';
import { Outlet, matchPath, useLocation, useNavigate, } from 'react-router-dom';
import AccountView from './AccountView';
import Main from '../Main';
import AccountsToolbar from './AccountsToolbar';
import styles from './Accounts.module.scss';
import DesktopView from '../DesktopView';
import MobileView from '../MobileView';
import NavigationView from '../NavigationView';
const Accounts = observer(() => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    React.useEffect(() => {
        const matched = matchPath({ path: '/accounts/:accountId', caseSensitive: false, end: true }, location.pathname);
        if (matched) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [location.pathname]);
    const handleClose = () => {
        navigate('/accounts');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DesktopView, null,
            React.createElement(Main, { toolbar: React.createElement(AccountsToolbar, { open: open }), sidebar: (React.createElement("div", { className: styles.accounts },
                    React.createElement(Tabs, { className: "mb-3", mountOnEnter: true, unmountOnExit: true },
                        React.createElement(Tab, { eventKey: "opened", title: "Opened" },
                            React.createElement(AccountView, { opened: true })),
                        React.createElement(Tab, { eventKey: "closed", title: "Closed" },
                            React.createElement(AccountView, { opened: false }))))), className: styles.theme },
                React.createElement(Outlet, null))),
        React.createElement(MobileView, null,
            React.createElement(NavigationView, { title: "Accounts", open: open, onClose: handleClose, details: (React.createElement(Outlet, null)) },
                React.createElement("div", { className: styles.accounts },
                    React.createElement(Tabs, { className: "mb-3", mountOnEnter: true, unmountOnExit: true },
                        React.createElement(Tab, { eventKey: "opened", title: "Opened" },
                            React.createElement(AccountView, { opened: true })),
                        React.createElement(Tab, { eventKey: "closed", title: "Closed" },
                            React.createElement(AccountView, { opened: false }))))))));
});
export default Accounts;
