import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { useStores } from '../State/Store';
import DesktopView from '../DesktopView';
import styles from './TransactionLogs.module.scss';
import MobileView from '../MobileView';
import ViewTitle from '../ViewTitle';
const TransactionLogs = observer(() => {
    const { transactionLogs } = useStores();
    React.useEffect(() => {
        transactionLogs.load();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(DesktopView, null,
            React.createElement("div", { className: styles.layout },
                React.createElement(Outlet, null))),
        React.createElement(MobileView, null,
            React.createElement("div", { className: styles.layout },
                React.createElement(ViewTitle, null, "Transaction Logs"),
                React.createElement(Outlet, null)))));
});
export default TransactionLogs;
